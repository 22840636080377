.header {
  height: 70px;

  background-image: url(../../assets/images/header-nav.png);
  background-repeat: no-repeat;
  &Cursor {
    width: 12px;
    height: 12px;
    background: #ff6c2d;
    border-radius: 10px;
    margin-right: 4px;
  }
}
.header a:hover,
.header a:visited,
.header a:active {
  color: #000000;
}
.header a:hover::after {
  width: 100%;
}
.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 24px;
  &View {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.split {
  margin: 0 24px;
  border-bottom: 0.5px solid #000;
}
.logo {
  display: inline-flex;
  margin-right: 89px;
  svg {
    width: 104px;
    height: 100%;
    margin: 15px 20px;
    margin-left: 0px;
    fill: none;
  }
}
.nav {
  display: flex;
  justify-content: space-between;
  height: 100%;
  &Menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &Item::after {
    content: '';
    left: 50%;
    position: absolute;
    height: 1px;
    bottom: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    width: 0%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ff6c2d),
      to(#ff6c2d)
    );
    background: linear-gradient(to right, #ff6c2d, #ff6c2d);
  }
  &Item {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    line-height: 20px;
    &Active {
      &::before {
        width: 12px;
        height: 12px;
        background: #ff6c2d;
        border-radius: 15px;
        content: '';
        position: absolute;
        left: -17px;
        top: 10px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      &::after {
        width: 100%;
        background: none;
      }
    }
    &Tip {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -6px;
      right: -28px;
      width: 35px;
      height: 14px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      background: #83d300;
      border-radius: 10px;
      padding: 2px 4px;
      span {
        display: inline-block;
        transform: scale(0.75);
      }
    }
  }
  &Item:not(:last-child) {
    margin-right: 52px;
  }
  &Link {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    &.active {
      font-weight: bold;
      color: #000000;
      border-bottom: 2px solid var(--color-highlight);
    }
  }

  &Info {
    display: flex;
    align-items: center;
    justify-content: center;
    &Btn {
      display: block;
      margin-right: 24px;
      margin-top: 6px;
      img {
        width: 50%;
        max-width: 180px;
      }
      &Mobile {
         display: none;
         flex-wrap: wrap;
         align-items: center;
         padding: 0 16px;
         img {
           max-width: 170px;
         }
      }
    }
  }
}

.menu {
  display: none;
}

@media (max-width: 1335px) {
  .navInfoBtn {
    display: none;
    &Mobile {
      display: flex;
    }
  }
}
@media (max-width: 1127px) {
  .logo {
    margin-right: 30px;
  }
}
@media (max-width: 1070px) {
  .logo {
    margin-right: 15px;
  }
  .navItem:not(:last-child) {
    margin-right: 26px;
  }
}

@media (max-width: 850px) {
  .navInfoBtn {
    &Mobile {
      justify-content: space-between;
    }
  }
  .header {
    height: 56px;
  }
  .split {
    margin: 0 16px;
  }
  .inner {
    /*padding:16px;*/
    padding: 0 16px;
    height: 56px;
  }

  .logo {
    font-size: 24px;
    svg {
      width: 104px;
    }
  }

  .nav {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    z-index: var(--z-index-header);
    background-color: #fff;
    &.open {
      z-index: 1399;
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s ease-in-out;
    }

    &Menu {
      display: none;
    }

    &Item {
      font-size: 18px;
      height: auto;
      width: 100%;
      margin: 16px 0;
    }

    &Link {
      justify-content: flex-start;
    }

    &Info {
      margin: 15px 16px;
    }
  }
  .menu {
    display: flex;
    width: 22px;
    user-select: none;
    z-index: var(--z-index-header);

    &Icon {
      position: relative;
      width: 22px;
      height: 2px;
      margin: 14px auto;
      background-color: #000;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      transform-origin: center;

      &::before,
      &::after {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        background-color: #000;
        transition: inherit;
        transform-origin: center;
      }

      &::before {
        top: -8px;
        left: 0;
      }

      &::after {
        top: 8px;
        left: 0;
      }

      &.open {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(-45deg);
        }

        &::after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
