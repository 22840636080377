.login {
  height: 100%;
}

.inner {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.title {
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
}

.metamask {
  width: 96px;
  height: 96px;

&Wrapper {
   margin: 68px 0;
   display: flex;
   align-items: center;
   justify-content: center;
 }
}

.connect {
  width: 321px;
  height: 286px;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  margin: 0 auto;
}
