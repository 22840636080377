.select {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
  &Wrapper {
    position: relative;
  }
}
select {
  border: solid 1px #000;
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
    no-repeat scroll right center transparent;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
}
select::-ms-expand {
  display: none; /* remove default arrow in IE 10 and 11 */
}

.option {
  color: var(--color-highlight);
  background-color: var(--color-background-primary);
}

.arrowDown {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  height: 16px;
  width: 16px;
  fill: currentColor;
}
