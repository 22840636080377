.layoutSlider {
  padding: 50px 190px 50px 40px;
  ul {
    width: 180px;
  }
}

.layoutSlider .menuItem:nth-child(1) {
  margin-top: 0 !important;
}

.layoutSlider .menuItem {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-top: 32px;
  cursor: pointer;

  display: flex;
  padding-left: 12px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.layoutSlider .menuItem:hover {
  font-weight: 600;
}

.layoutSlider .menuItemSelected {
  font-weight: 600;
  padding-left: 0;
}

.layoutSlider .menuItemSelected::before {
  display: inline;
  height: 12px;
  background: #ff6c2d;
  border-radius: 15px;
  content: '';
  position: relative;
  width: 12px;
  left: -4px;
  top: 6px;
  transform: translateY(-50%);
}

.layoutSlider .menuItemSelected span::after {
  width: 100%;
  background: none;
}

.base {
  height: 100%;
  display: flex;
  flex-direction: column;
}
