.walletConnectModal {
  width: 369px;
  height: 286px;
  /*&Inner {*/
  /*  margin-bottom: 16px;*/
  /*  &Info {*/
  /*     display: flex;*/
  /*     align-items: center;*/
  /*     margin-top: 32px;*/
  /*     margin-bottom: 16px;*/
  /*     &Radio {*/
  /*       display: flex;*/
  /*       margin-right: 9px;*/
  /*       span {*/
  /*           display: none;*/
  /*       }*/
  /*     }*/
  /*     &Description {*/
  /*         display: flex;*/
  /*         align-items: center;*/
  /*         font-weight: 400;*/
  /*         font-size: 14px;*/
  /*         line-height: 15px;*/
  /*         color: #000000;*/
  /*     }*/
  /*  }*/
  /*  &Wallet {*/
  /*    width:321px;*/
  /*    margin-top: 16px;*/
  /*    svg {*/
  /*      width: 24px;*/
  /*      height: 24px;*/
  /*      margin-right: 12px;*/
  /*    }*/
  /*  }*/
  /*}*/
}
