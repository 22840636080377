.container {
  position: relative;
}

.caption {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #909090;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.step {
  margin-top: 24px;

  svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;

    &Text {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &Content {
    padding: 5px 0px;
    color: #909090;
  }

  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}

.wallet {
  .address {
    margin-top: 24px;
    display: flex;
    align-items: center;

    &Link {
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      overflow-wrap: anywhere;
      cursor: pointer;

      &:hover,
      &:visited {
        color: #000000;
      }
    }

    &Icon {
      margin-left: 6px;
    }
  }

  .tokenList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 32px;
    gap: 24px;

    .tokenItem {
      width: 290px;
    }
  }
}

.inventory {
  .noReslut {
    margin-top: 12px;
  }

  &Content {
    margin-top: 12px;
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .pagination {
    margin: 40px 0;
  }
}

@media (--view-modal-sm) {
  .wallet {
    .tokenList {
      display: block;

      .tokenItem {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .inventory {
    &List {
      justify-content: center;
    }
  }
}
