.radio {
  display: inline-flex;
  align-items: center;
}

.inner {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  /*background-color: var(--color-background-secondary);*/
  border: solid 1px #000;
  cursor: pointer;
  &:hover,
  &:focus {
    border: solid 1px #ff6c2d;
  }
  &:disabled {
     border-color: var(--color-disable);
     color: var(--color-disable);
     cursor: not-allowed;
  }
  &:checked {
    border: solid 1px #ff6c2d;
    background-color: #ff6c2d;

    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 1px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
    }
  }
}
.point {
  position: relative;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  /*background-color: var(--color-background-secondary);*/
  border: solid 1px #000;
  cursor: pointer;
  &:hover,
  &:focus {
     border: solid 1px #ff6c2d;
   }
  &:checked {
    border: solid 1px #ff6c2d;
    /*background-color: #ff6c2d;*/

    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -4px;
      margin-top: -4px;
      border-radius: 15px;
      background-color: #ff6c2d;
    }
  }
}
.text {
  font-size: 12px;
  margin-left: 6px;
  margin-right: 25px;
}
