.notFound {
  height: 100%;
}

.inner {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.title {
  font-size: 64px;
  text-align: center;
}
