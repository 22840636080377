.input {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 56px;
  border-radius: 8px;
  padding: 4px 8px;
  color: var(--color-light-primary);
  border: 2px solid var(--color-background-tertiary);
  background-color: var(--color-background-tertiary);

  &:hover,
  &:focus {
    border: 2px solid var(--color-highlight);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
