.header {
  width: 100%;
  height: 140px;
  flex: none;
  padding: 24px;
  font-family: 'Chivo';
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 1px 6px #00000026;
}

.nav {
  display: flex;
  align-items: center;
  gap: 36px;

  &Logo {
    display: flex;
    color: var(--color-dark);

    &:hover,
    &:focus {
      color: var(--color-dark);
    }

    &Icon {
      width: 120px;
    }
  }

  &Item {
    position: relative;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
      color: var(--color-dark);
      border-bottom: 1px solid var(--color-primary);
    }

    &Active {
      &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translate3d(-120%, -50%, 0);
        background-color: var(--color-primary);
      }
    }
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--color-light);

  &Item {
    display: none;
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    border-radius: 20px;
    padding: 8px 16px;
    gap: 4px;
    color: var(--color-light);
    background-color: var(--color-dark);
    transition: transform 0.3s ease;

    &.invalid {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      color: var(--color-light);
      transform: scale(1.1);
    }

    &:active {
      color: var(--color-light);
      transform: scale(0.9);
    }
  }

  &Icon {
    width: 24px;
    height: 24px;
    z-index: 1;

    &.offset {
      z-index: 0;
      transform: translateX(-4px);
    }

    &Wrapper {
      display: flex;
      align-items: center;
      margin-right: -4px;
    }
  }
}

.menu {
  display: none;
}

@media (--view-xl) {
  .menu {
    display: flex;
    width: 22px;
    user-select: none;
    z-index: var(--z-index-header);

    &Icon {
      position: relative;
      width: 22px;
      height: 2px;
      margin: 14px auto;
      background-color: rgb(136, 136, 136);
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      transform-origin: center;

      &::before,
      &::after {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        background-color: rgb(136, 136, 136);
        transition: inherit;
        transform-origin: center;
      }

      &::before {
        top: -8px;
        left: 0;
      }

      &::after {
        top: 8px;
        left: 0;
      }

      &.open {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(-45deg);
        }

        &::after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  .navItem {
    display: none;
  }

  .info {
    display: none;

    &.open {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      top: 120px;
      left: 16px;
      right: 16px;
      height: calc(100vh - 120px);
      opacity: 1;
      z-index: 1200;
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        color: var(--color-dark);
      }
    }
  }
}
