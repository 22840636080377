.message {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: var(--z-index-message);
  pointer-events: none;

  &-notice {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0px 24px;
    font-size: 18px;
    border-radius: 4px;
    color: #fff;
    background-color: #ff6c2d;
    border-radius: 15px;
    pointer-events: auto;

    &.warning {
      background-color: #ff6c2d;
      color: #fff;
      border-radius: 15px;
    }

    .icon {
      display: none;
      width: 16px;
      height: 16px;
    }

    .close {
      display: inline-flex;
      margin-left: 16px;

      &Icon {
        width: 16px;
        height: 16px;
      }
    }

    &-text {
      margin-left: 8px;
    }

    &.message-notice-enter {
      opacity: 0;
    }

    &.message-notice-enter-active {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }

    &.message-notice-exit {
      max-height: 100vh;
      opacity: 1;
    }

    &.message-notice-exit-active {
      max-height: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in, max-height 0.3s;
    }
  }
}
