.mint {
  background: #c5c5f4;
  border-radius: 8px;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Plus Jakarta Sans';

  .label {
    display: flex;
    align-items: flex-end;

    .text {
      flex: 1;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }

    .value {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .bar {
    display: flex;
    margin-top: 17px;

    span {
      background: #ffffff;
      border-radius: 6px;
      height: 12px;
      flex: 1;
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }
    }

    .active {
      background: #8c8ce4;
    }
  }
}
