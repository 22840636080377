.download {
  height: 100%;
  font-family: 'Chivo';
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.picture {
  display: flex;
  flex: 1;

  .image {
    max-width: 100%;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  line-height: 1.2;
  font-weight: 800;
  font-size: var(--font-size-3xl);
}

.caption {
  margin: 8px 0;
  font-size: var(--font-size-xl);
  color: #8a8a8a;
}

.link {
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 24px;

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 192px;
    height: 64px;
    border-radius: 64px;
    box-shadow: 0 1px 6px #00000040;
    transition: transform 0.3s ease;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.9);
    }
  }
  &Icon {
    width: 28px;
    height: 28px;
  }
}

.apple {
  width: 108px;
}

.google {
  width: 96px;
}

@media (--view-modal-sm) {
  .container {
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
  }

  .picture {
    max-width: 640px;
  }

  .intro {
    max-width: 640px;
  }

  .title {
    text-align: center;
    font-size: var(--font-size-xl);
  }

  .caption {
    text-align: center;
    font-size: var(--font-size-xs);
  }

  .link {
    gap: 8px;
    justify-content: center;

    &Button {
      gap: 4px;
      width: 108px;
      height: 36px;
    }

    &Icon {
      width: 16px;
      height: 16px;
    }
  }

  .apple {
    width: 56px;
  }

  .google {
    width: 52px;
  }
}
