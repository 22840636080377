.tips {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 36px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: #eee;
  .textWrapper {
    display: flex;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
    }
    .text {
      margin-left: 16px;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }
  }
  .btn {
    min-width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 6px;
    border: 2px solid #ff6c2d;
    border-radius: 12px;
    font-size: 18px;
    line-height: 18px;
    color: #ff6c2d;
    &:disabled{
       color: #909090;
       border: 2px solid #909090;
       cursor: not-allowed;
    }
  }
}

@media (--view-modal-sm) {
  .tips {
    display: block;
    padding: 24px;
    .btn {
      margin-top: 20px;
    }
  }
}
