.account {
  position: relative;
  /*height: 100%;*/
  padding: 0 24px;
}

.event {
  font-size: 12px;
  font-weight: 600;
}

.inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.account .total .caption {
  margin-left: 0px;
  margin-right: 0px;
}

.caption {
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: 'Archivo';
  font-size: 24px;
  /*margin: 8px 0px;*/
  font-size: 18px;
  border-bottom: 0.5px solid #909090;
  font-weight: normal;

  &Inventory {
    margin-top: 24px;
    margin-bottom: 12px;
    font-family: 'Archivo';
    font-size: 24px;
    /*margin: 8px 0px;*/
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
}

.balance {
  /*position: relative;*/
  /*margin: 16px 0;*/
  /*padding: 16px 32px;*/
  /*max-width: 472px;*/
  /*width: 100%;*/
  /*border-radius: 4px;*/
  /*background-color: var(--color-background-tertiary);*/

  &Icon {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 72px;
    height: 72px;
    fill: none;
  }

  &ETH {
    font-size: 32px;
  }

  &USD {
    align-items: center;
    font-size: 12px;
    color: #ff6c2d;
    border: 1px solid #ff6c2d;
    border-radius: 12px;
    height: 13px;
    padding: 2px 5px;
  }
}

.address {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
  margin-top: 20px;

  a {
    text-decoration: underline;
  }

  a:hover,
  a:visited {
    color: #000000 !important;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: none;
  }
}

.copy {
  margin-left: 8px;
  flex: none;
}

.total {
  min-width: 180px;
  max-width: 291px;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
}

.inventory {
  max-width: 940px;

  &List {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.cursorInherit {
  cursor: inherit;
}

.right {
  width: 100%;
  margin-left: 102px;
}

.rightView {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.activity {
  width: 100%;
  min-width: 448px;
}

.activityTableRowSplit {
  width: 97.5%;
}

.openOutside svg {
  fill: none;
}

.noResult {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: rgba(238, 238, 238, 0.5);

  &Info {
    font-size: 14px;
    line-height: 16px;
    color: #909090;
  }

  div {
    text-align: center;
  }

  svg {
    width: 60px;
    height: 60px;
  }
}

.batchTransfer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &Title {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    padding-top: 48px;
    padding-bottom: 8px;
    border-bottom: 0.5px solid #909090;
  }

  &Info {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #909090;
    margin-bottom: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: none;
    margin-top: 25px;
    margin-bottom: 13px;
  }
}

.cardListModal {
  &Normal {
    width: 975px;
    height: 655px;
  }

  &Middle {
    width: 504px;
    height: 655px;
  }

  &Bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Btn {
    &Back {
      margin-right: 20px;

      &Small {
        width: 186px !important;
      }
    }
  }

  &Transfer {
    &Btn {
      height: 40px;
      line-height: 40px;
      width: 212px;
      padding: 0px;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      border: 2px solid #ff6c2d;
      color: #fff !important;
      background-color: #ff6c2d !important;
      margin-top: 10px;

      &Disable {
        height: 40px;
        line-height: 40px;
        width: 212px;
        padding: 0px;
        text-align: center;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 10px;
        color: #909090 !important;
        background: #ffffff !important;
        border: 2px solid #909090;
      }
    }

    &Error {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #ff0000;
      min-height: 18px;
      margin-top: 5px;
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &Confirm {
      margin: 20px 0px;

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #909090;
        margin-bottom: 8px;
      }

      div {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #000000;
      }

      &Large {
        display: flex;
        margin: 10px 0px;

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #909090;
          margin-right: 12px;
        }

        div {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          text-decoration-line: underline;
          color: #000000;
        }
      }
    }

    &Pagination {
      margin-top: 36px;

      &Large {
        margin-top: 4px;
        padding-top: 0px;
        padding-bottom: 16px;
      }
    }

    &InputView {
      position: relative;

      &Large {
        svg {
          top: 30px !important;
          right: 23px !important;
        }
      }

      svg {
        width: 14px !important;
        height: 14px !important;
        fill: none !important;
        position: absolute;
        right: 0px;
        top: 6px;
      }
    }

    &Input {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #909090;
      width: 100%;
      height: 30px;
      border-radius: 0px;
      padding-right: 15px !important;
      padding-left: 4px !important;
      border-bottom: 1px solid #000000;

      &Large {
        width: 448px;
        margin-right: 24px;
      }

      &:hover,
      &:active,
      &:focus {
        border: 0px;
        border-bottom: 1px solid #000000;
      }
    }

    &Btn {
      text-align: center;
      border-top: 1px solid #eeeeee;

      &Title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }
      }
    }
  }
}

.steps {
  display: block;
}

.step {
  margin-top: 24px;

  svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;

    &Text {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &Content {
    padding: 5px 0px;
    color: #909090;
  }

  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}

.modalNotice {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #c7c7c7;

  &Title {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #909090;
    margin-bottom: 8px;
  }

  &Msg {
    font-size: 12px;
    line-height: 13px;
    color: #909090;
  }
}

@media (max-width: 850px) {
  .right {
    margin-left: 0px;
  }

  .account {
    padding: 0px;

    &Info {
      display: flex;
      font-size: 12px;
      margin-right: 33px;
      position: relative;
      flex-direction: column;
      align-items: center;
    }
  }

  .caption {
    &Inventory {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .address {
    position: relative;
  }

  .inner {
    flex-direction: column;
    padding: 0 16px;
  }

  .total {
    max-width: none;
  }

  .inventory {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0px;

    &List {
      grid-gap: 16px;
      gap: 16px;
      justify-content: center;
    }
  }

  .activity {
    margin-bottom: 50px;
    max-width: none;
    min-width: 100%;
    margin-bottom: 50px;
    padding: 0px;
  }

  .transfer {
    flex-direction: column;
    padding: 16px 16px;

    &Info {
      &Block {
        margin-left: 0px;
        margin-bottom: 20px;
      }

      &Icon {
        display: none;
      }
    }

    &Inner {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .activityTableRowSplit {
    width: 95.5%;
  }
}

@media (max-width: 720px) {
  .cardListModal {
    &Normal {
      width: 95% !important;
      height: 665px;
    }

    &Middle {
      width: 95% !important;
      height: 665px;
    }

    &Bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &Content {
      margin: 16px 0px;

      &Confirm {
        flex-direction: column;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }

        div {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
        }
      }

      &InputView {
        width: 100%;

        svg {
          right: 0px !important;
        }
      }

      &Input {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        &:focus,
        &:active {
          user-select: text;
          -webkit-user-select: text;
          zoom: 1;
        }
        &Large {
          width: 100%;
        }
      }
    }
  }
}

@media (--view-xs) {
  .batchTransfer {
    button {
      width: 100%;
    }
  }
}
