.tableHeaderRow:nth-child(2) {
}
.table {
  display: table;
  width: 100%;
  min-width: 448px;
  overflow-x: scroll;
  table-layout: fixed;
  border-collapse: collapse;
  &Wrapper {
    width: 100%;
    overflow-y: auto;
    max-height: 516px;
    padding: 1px 0px;
  }
  &Header {
    display: table-header-group;

    &Row {
      display: table-row;
      height: 36px;
    }
    &Cell {
      font-size: 12px;
      color: #909090;
      display: table-cell;
      vertical-align: middle;
      background-color: #eeeeee;
      padding: 0px 10px;
      &:nth-child(2) {
        padding-left: 0px;
      }
    }
    &Cell:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      padding: 0px;
    }
    &Cell:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
    &Cell:nth-last-child(2) {
      padding-right: 0px;
      width: 72px;
    }
  }

  &Body {
    display: table-row-group;
    position: relative;
    &Row {
      position: relative;
      display: table-row;
      height: 48px;
      &Split {
        position: absolute;
        width: 428px;
        border-bottom: 1px solid #000;
        margin-left: 10px;
      }
    }

    &Cell {
      font-size: 12px;
      display: table-cell;
      vertical-align: middle;
      padding: 0px 10px;
      width: 71px;
      border-bottom: solid 1px #000;
      a {
        text-decoration: underline;
      }
      &:nth-child(2) {
        padding-left: 0px;
      }
    }
    &Cell:first-child {
      border-bottom: 0px;
    }
    &Cell:last-child {
      border-bottom: 0px;
    }
    &Cell:nth-last-child(2) {
      padding-right: 0px;
      width: 10%;
    }
  }
}
