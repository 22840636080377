.container {
  display: flex;
  flex-direction: column;
}
.summary {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.purchased {
  font-weight: 600;
  font-size: 14px;
  color: #ff6c2d;
  text-decoration: underline;
}
.trait {
  display: flex;
  /* width: 128px; */
  border: 2px solid #ff6c2d;
  border-radius: 11px;
  flex-direction: column;
  padding: 3px 7px;
  &Info {
    font-size: 18px;
  }
}
.price {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  &Title {
    font-weight: 600;
    font-size: 14px;
  }
  &Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;
  }
}
.card {
  position: relative;
  display: flex;
  top: 0;
  width: 291px;
  height: 360px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f6f8fd;
  &Sale {
    font-size: 12px;
    right: 8px;
    top: 12px;
    height: 19px;
    line-height: 18px;
    padding: 0px 5px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }
  &Image {
    margin-bottom: 30px;
  }
  &Image[src=''] {
    opacity: 0;
  }
  &Image:after {
    content: '' ' ' attr(alt);
  }

  &Price {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &Inner {
      display: block;
    }

    &ETH {
      font-size: 18px;
      height: 20px;
      line-height: 20px;
    }

    &USD {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
    }
  }
}

@media (max-width: 850px) {
  .container {
    width: 100%;
  }
  .card {
    position: relative;
    width: 100%;
    height: 440px;
  }
}

@media (max-width: 320px) {
  .container {
    width: 100%;
  }
  .card {
    position: relative;
    width: 100%;
    height: 380px;
  }
}
