.attribute {
  font-family: 'Tomorrow';

  .label {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 4px;
      background-image: url('/src/assets/icons/attribute.svg');
      background-position: center;
    }

    .text {
      flex: 1;
    }
  }

  .bar {
    background: #f8f8f8;
    border-radius: 6px;
    height: 8px;
    margin-top: 8px;
    position: relative;
    overflow: hidden;

    div {
      position: absolute;
      height: 8px;
    }
  }
}
