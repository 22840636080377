@import 'ress';
@import 'root.css';
@import 'media.css';
@import 'firebaseui.css';
@import 'tippy.js/dist/tippy.css';
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html,
body {
  height: 100%;
  /*color: var(--color-light-primary);*/
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Archivo', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  background-color: #ffffff;
  font-weight: 400;
}

#root {
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-highlight);
  }
}

@font-face {
  font-family: 'Chivo';
  font-display: swap;
  font-weight: 100 900;
  src: local('Chivo'),
    url(assets/fonts/chivo/Chivo-VariableFont.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'Marcellus';
  src: local('Marcellus'),
    url(assets/fonts/marcellus/Marcellus-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
