.noResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: rgba(238, 238, 238, 0.5);
  svg {
    width: 60px;
    height: 60px;
  }
  &Info {
    font-size: 14px;
    line-height: 16px;
    color: #909090;
  }
}

@media (--view-modal-sm) {
  .noResult {
    height: 144px;
  }
}
