.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 8px 36px;
  border-radius: 15px;
  font-size: 18px;
  background: var(--color-button-background);
  &.normal {
    border: 2px solid var(--color-button);

    &:hover,
    &:focus {
      border: 2px solid var(--color-button);
      color: var(--color-button);
    }
  }

  &.primary {
    color: var(--color-background-primary);
    background-color: var(--color-light-primary);

    &:hover,
    &:focus {
      color: var(--color-background-primary);
      background-color: var(--color-highlight);
    }
  }
  &.disabled {
    color: var(--color-disable);
    border: 2px solid var(--color-disable);
    &:hover,
    &:focus {
       color: var(--color-disable);
     }
  }
  &:disabled {
    cursor: not-allowed;
    color: var(--color-disable);
    &:hover,
    &:focus {
    }
  }
}
