.caption {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #909090;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.login {
  margin-top: 24px;

  .loginBanner {
    width: 100%;
    max-width: 600px;
    cursor: pointer;
  }
}

.user {
  display: flex;
  align-items: center;
  margin-top: 32px;

  .infoAvatar {
    order: 3;
    margin-left: 20px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      background: #000000;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    .logout {
      margin-left: 10px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .email {
    order: 1;
    &Field {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }

    &Value {
      margin-top: 8px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .pairBtn {
    order: 2;
    margin-left: 60px;
    padding: 0 12px;
  }
}

.tokenList {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .tokenItem {
    width: 290px;
  }
}

.deposit,
.withdraw {
  max-width: 500px;

  .amount {
    margin-top: 40px;
    font-size: 14px;
    line-height: 14px;
    color: #909090;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding-bottom: 14px;
    border-bottom: 1px solid #000000;

    svg {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eee;
    }

    .input {
      height: 26px;
      margin-left: 16px;
      padding: 0;
      font-size: 24px;
      line-height: 26px;
      color: #000;
      outline: none;

      &:hover,
      &:focus {
        border: none;
      }
    }
  }

  .available {
    margin-top: 12px;
    font-size: 18px;
    line-height: 20px;
  }

  .actions {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    .btn {
      width: 242px;
    }
  }
}

.steps {
  display: block;
}

.step {
  margin-top: 24px;

  svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;

    &Text {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &Content {
    padding: 5px 0px;
    color: #909090;
  }

  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}

.inventory {
  .noReslut {
    margin-top: 12px;
  }

  &Content {
     margin-top: 12px;
   }

  &List {
     display: flex;
     flex-wrap: wrap;
     gap: 24px;
   }
  .pagination {
    margin: 40px 0;
  }
}

.cursorInherit {
  cursor: inherit;
}

.showWithdraw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
  &Card {
    width: 212px !important;
    cursor: initial;
  }
  &Description {
    border-top: 1px solid #000000;
    padding: 16px 0px;
    margin-top: -5%;
    &Title {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
    &Fee {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #FF6C2D;
    }
  }
  &Footer {
   display: flex;
   width: 100%;
   margin-top: 24px;
   justify-content: space-between;
  }
  &Submit {
    width: 205px;
  }
  &Cancel {
     width: 96px !important;
     color: #000000 !important;
     background: #ffffff !important;
     border: 2px solid #000000 !important;
  }
}
@media (--view-xl) {
  .user {
    .pairBtn {
      margin-left: 20px;
    }
  }
}

@media (--view-modal-sm) {
  .user {
    margin-top: 24px;
    display: block;

    .infoAvatar {
      justify-content: flex-start;
      margin-left: 0;
    }
    .email {
      margin-top: 24px;
    }
    .pairBtn {
      margin-top: 24px;
      margin-left: 0;
    }
  }

  .tokenList {
    display: block;
    margin-top: 24px;

    .tokenItem {
      width: 100%;
      margin-top: 16px;
    }
  }
  .deposit,
  .withdraw {
    .actions .btn {
      width: 100%;
    }
  }
}
