.checkbox {
  display: inline-flex;
  align-items: center;
}

.inner {
  display: none;
  outline: none;
  appearance: none;
  /*
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #000000;
  cursor: pointer;*/

  &:hover,
  &:focus {
    border: solid 1px #ff6c2d;
  }

  &:checked {
    outline: none;
    appearance: none;
    /*  border: solid 1px #ff6c2d;
    background-color: #ff6c2d;*/

    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
    }
  }
}
.checked {
  cursor: pointer;
  background: #ff6c2d;
  padding: 2.5px 7px;
  border-radius: 15px;
  color: #ffffff;
  border: 1px solid #ff6c2d;
}
.unchecked {
  cursor: pointer;
  background: #fff;
  padding: 2.5px 7px;
  border-radius: 15px;
  color: #000000;
  border: 1px solid #000000;
}
.text {
  font-size: 12px;
  z-index: 1100;
}
