.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  border-radius: 15px;
  font-size: 18px;
  line-height: 20px;
  transition: opacity 0.4s;

  &.normal {
    border: 2px solid #909090;
    color: #909090;
    &:hover {
      opacity: 0.7;
    }
  }
  &.primary {
    color: #fff;
    background-color: #ff6c2d;
    &:hover {
      opacity: 0.7;
    }
  }
  &.secondary {
    color: #fff;
    background-color: #3864ff;
    &:hover {
      opacity: 0.7;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: #909090;
    border: solid 2px #909090;
    background-color: transparent;
  }
}
