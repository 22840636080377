.tokenInfo {
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #eee;
  border-radius: 16px;
}

.baseInfo {
  display: flex;
  align-items: center;
  padding: 14px;
  svg {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .balanceWrapper {
    flex: 1;
    margin-left: 16px;
  }
  .balance {
    font-size: 28px;
    line-height: 30px;
  }
  .price {
    display: inline-block;
    margin-top: 8px;
    padding: 3px 6px;
    border: 2px solid #ff6c2d;
    border-radius: 15px;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    color: #ff6c2d;
  }
}

.contract {
  display: flex;
  align-items: center;
  padding: 6px 14px 14px;
  font-size: 18px;
  line-height: 20px;
  .addr {
    flex: 1;
    margin-left: 32px;
    margin-right: 10px;
    text-align: right;
  }
}

.actions {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #eee;
  .btn {
    width: 126px;
  }
  .btn + .btn {
    margin-left: 12px;
  }
}

.tokenInfo.disabled {
  .baseInfo {
    .icon {
      filter: grayscale(100%);
      opacity: 0.4;
    }
    .balanceWrapper {
      color: #C7C7C7;
    }
    .price {
      border-color: #C7C7C7;
      color: #C7C7C7;
    }
  }
}

@media (--view-modal-sm) {
  .actions {
    padding: 20px 12px;
    .btn {
      flex: 1;
    }
  }
}
