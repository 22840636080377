.topBanner {
  height: 48px;
  padding: 13px 22px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  align-items: center;

  &IcoTips {
    height: 20px;
    margin-right: 10px;
  }

  &Tips {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #909090;
  }
}

.transferTips {
  padding: 24px 0 0 0;

  span {
    height: 20px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }
}

.transferContent {
  max-width: 975px;
  --cardsJustifyContent: flex-start;
  --cardsWidth: 142px;
  --cardImageWidth: '114px !important';
  --cardsGap: 4px 24px !important;
  --cardsHeight: auto;
  --cards720Height: auto;

  .card {
    justify-content: flex-start !important;
  }

  &Disable {
    display: none;
  }
}

.cardListModal {
  &Normal {
    width: 975px;
    height: 655px;
  }

  &Middle {
    width: 504px;
    height: 655px;
  }

  &Bottom {
    display: flex;
    justify-content: flex-start;
  }

  &Btn {
    &Back {
      margin-right: 20px;

      &Small {
        width: 186px !important;
      }
    }
  }

  &Transfer {
    &Btn {
      height: 40px;
      line-height: 40px;
      width: 212px;
      padding: 0px;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      border: 2px solid #ff6c2d;
      color: #fff !important;
      background-color: #ff6c2d !important;
      margin-top: 10px;
      margin-left: 28px;

      &Disable {
        margin-left: 28px;
        height: 40px;
        line-height: 40px;
        width: 212px;
        padding: 0px;
        text-align: center;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 10px;
        color: #909090 !important;
        background: #ffffff !important;
        border: 2px solid #909090;
      }
    }

    &Error {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #ff0000;
      min-height: 18px;
      margin-top: 5px;
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &Confirm {
      margin: 20px 0px;

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #909090;
        margin-bottom: 8px;
      }

      div {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #000000;
      }

      &Large {
        display: flex;
        margin: 10px 0px;

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #909090;
          margin-right: 12px;
        }

        div {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          text-decoration-line: underline;
          color: #000000;
        }
      }
    }

    &Pagination {
      margin-top: 36px;

      &Large {
        margin-top: 4px;
        padding-top: 0px;
        padding-bottom: 16px;
      }
    }

    &InputView {
      position: relative;

      &Large {
        svg {
          top: 30px !important;
          right: 23px !important;
        }
      }

      svg {
        width: 14px !important;
        height: 14px !important;
        fill: none !important;
        position: absolute;
        right: 0;
        top: 30px;
      }
    }

    &Input {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #909090;
      width: 448px;
      height: 30px;
      border-radius: 0px;
      padding-right: 15px !important;
      padding-left: 4px !important;
      border-bottom: 1px solid #000000;

      &Large {
        width: 448px;
        margin-right: 24px;
      }

      &:hover,
      &:active,
      &:focus {
        border: 0px;
        border-bottom: 1px solid #000000;
      }
      &Done {
        border-bottom: 2px solid #83d300 !important;
        &:hover,
        &:active,
        &:focus {
          border-bottom: 2px solid #83d300 !important;
        }
      }
    }

    &Btn {
      text-align: left;
      border-top: 1px solid #eeeeee;

      &Title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }
      }
    }
    &ConfirmTransfer {
      text-align: left;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;

      &Title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }
      }
      &Btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.steps {
  display: block;
}

.step {
  margin-top: 24px;

  svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;

    &Text {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &Content {
    padding: 5px 0px;
    color: #909090;
  }

  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}
@media (max-width: 1380px) {
  .cardListModal {
    &Content {
      &ConfirmTransfer {
        &BackBtn {
          margin-left: 10px;
          margin-right: 10px;
          width: 120px !important;
        }
        &ConfirmBtn {
          margin-right: 0;
          width: 210px !important;
        }
      }
    }
  }
}
@media (max-width: 1240px) {
  .cardListModal {
    &Transfer {
      &Btn {
        width: 162px;

        &Disable {
          width: 162px;
        }
      }
    }

    &Content {
      &Input {
        width: 224px;
      }
      &ConfirmTransfer {
        text-align: left;
        display: block;
        border-top: 1px solid #eeeeee;

        &Title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          margin-top: 13px;
          text-transform: uppercase;

          svg {
            width: 24px;
            height: 24px;
            margin-right: 14px;
          }
        }
        &Btn {
          display: block;
        }
        &Blox {
          width: 100% !important;
        }
        &BackBtn {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
        &ConfirmBtn {
          margin-right: 0;
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .topBanner {
    height: auto;
  }

  .cardListModal {
    &Normal {
      width: 95% !important;
      height: 665px;
    }

    &Middle {
      width: 95% !important;
      height: 665px;
    }

    &Bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &Transfer {
      &Btn {
        width: 100%;
        margin-left: 0;

        &Disable {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    &Content {
      margin: 16px 0px;

      &Confirm {
        flex-direction: column;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }

        div {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
        }
      }

      &InputView {
        width: 100%;

        svg {
          right: 0px !important;
        }
      }

      &Input {
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        line-height: 16px;

        &:focus,
        &:active {
          user-select: text;
          -webkit-user-select: text;
          zoom: 1;
        }

        &Large {
          width: 100%;
        }
      }
      &ConfirmTransfer {
        text-align: left;
        display: block;
        border-top: 1px solid #eeeeee;

        &Title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          margin-top: 13px;
          text-transform: uppercase;

          svg {
            width: 24px;
            height: 24px;
            margin-right: 14px;
          }
        }
        &Btn {
          display: block;
        }
        &Blox {
          width: 100% !important;
        }
        &BackBtn {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
        &ConfirmBtn {
          margin-right: 0;
          width: 100% !important;
        }
      }
    }
  }
}
