.marketplace {
  /*height: 100%;*/
}
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  text-align: center;
  padding: 0 16px;
}

.split {
  border-bottom: 0.5px solid #000;
  &Header {
    margin: 0px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #000;
  }
}
.inner {
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.banner {
  width: 100%;
  margin: 25px 0px;
  &Pic {
    position: relative;
    width: 100%;
    min-height: 320px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-position: 0 34%;
    overflow: hidden;
    background-size: cover;

    div {
      position: absolute;
      left: 5%;
      bottom: 10%;
      font-size: 48px;
      line-height: 52px;
      display: flex;
      align-items: center;
      color: #ffffff;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    }
  }
  &Info {
    min-height: 100px;
    display: flex;
    padding: 0px 45px;
    background: #eeeeee;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    align-items: center;
    justify-content: space-around;
    &Item {
      display: flex;
      padding: 30px 0px;
      svg {
        width: 38px;
        height: 26px;
        fill: none;
      }
      &Title {
        display: flex;
        align-items: flex-start;
        margin-left: 12px;
        margin-right: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #909090;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &Value {
        font-size: 36px;
        line-height: 32px;
        color: #000000;
        border-bottom: 1px solid #000000;
      }
    }
  }
}
.filter {
  width: 100%;
  border-radius: 4px;
  &Header {
    display: flex;
    margin-top: 7px;
    align-items: center;
    justify-content: flex-start;
    &Left {
      display: flex;
      width: 100%;
    }
  }

  &Title {
    color: #000;
    font-size: 18px;
    margin-right: 58px;
  }

  &Reset {
    color: #909090;
    font-size: 14px;
    text-decoration: underline;
    &Enable {
      color: #000000;
      text-decoration: underline;
    }
  }

  &Block {
    margin-right: 90px;
  }

  &Caption {
    margin-top: 12px;
    color: #000;
    font-weight: 600;
  }

  &Options {
    padding: 4px 0px;
    background-color: #fff;
  }

  &Checkbox {
    margin-bottom: 18px;
    margin-right: 16px;
  }
  &Properties {
     color: #000000;
     background: #EEEEEE;
     border-radius: 18px;
     padding: 2px 7px;
     font-weight: 400;
     font-size: 10px;
     margin-right: -5px;
     display: inline-block;
     -webkit-transform: scale(.83);
     transform: scale(.83);
  }
  &Radio {
    margin: 8px 0;
  }

  &Row {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
  }
}

.list {
  width: 100%;
  max-width: 1432px;
  flex: 1;
  margin: 0 auto;

  &Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.sum {
  font-family: Archivo;
  font-size: 20px;
}

.sort {
  display: flex;
}

.select:first-child {
  margin: 0 16px;
}
.select select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.cards {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  gap: 24px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.marketplace .menu {
  display: none;
}

@media (min-width: 1600px) {
  .list {
    width: 100%;
    max-width: 100%;
    flex: 1;
    margin: 0 auto;
  }
}
@media (max-width: 1100px) {
  .banner {
    &Pic {
      width: auto;
      min-height: 190px;
      div {
        font-size: 24px;
        line-height: 25px;
      }
    }
    &Info {
      flex-wrap: wrap;
      justify-content: space-between;
      &Item {
        min-width: 259px;
      }
    }
  }
}
@media (max-width: 850px) {
  .banner {
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .open {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }
  .menu {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1100;
    align-items: center;
    justify-content: flex-end;
  }
  .menu svg {
    width: 28px;
    height: 28px;
  }
  .filter {
    &Row {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    &Block {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    &Block:first-child {
      margin-bottom: 0px;
    }
  }
  .sort {
    justify-content: space-between;
  }
  .inner {
    margin: 16px;
  }
  .split {
    margin-top: 15px;
    &Header {
      margin: 0px;
      margin-bottom: 10px;
    }
  }
  .list {
    &Header {
      flex-direction: column;
    }
  }

  .select select {
    width: 100%;
  }
  .select:first-child {
    margin: 0px;
  }
  .select {
    margin: 0px;
    width: 47%;
  }

  .cards {
    display: flex;
    justify-content: center;
    margin: 16px auto;
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (max-width: 650px) {
  .banner {
    &Info {
      padding: 0px 15px;
      justify-content: flex-start;
    }
  }
}
@media (max-width: 414px) {
  .banner {
    &Pic {
      min-height: 390px;
      div {
        font-weight: 400;
        font-size: 36px;
        line-height: 39px;
      }
    }
  }
}
@media (max-width: 390px) {
  .banner {
    &Pic {
      min-height: 390px;
    }
  }
}
