.footer {
  margin-top: 80px;
  font-family: 'Chivo';
  background-color: var(--color-dark);
  color: var(--color-light);
  padding: 48px 24px;

  &Logo {
    display: flex;
    align-items: flex-start;
    margin-right: 80px;

    &Link {
      display: flex;
      color: var(--color-light);
    }

    &Icon {
      width: 104px;
    }
  }

  &Info {
    display: flex;
    flex: 1;
  }

  &Column {
    margin-right: 20%;
  }

  &Item {
    margin-top: 12px;
  }

  &Title {
    font-size: var(--font-size-md);
    font-weight: 600;
  }

  &SubTitle {
    font-size: var(--font-size-md);
    font-weight: 600;
  }

  &Link {
    color: var(--color-light);

    &:hover,
    &:focus {
      color: var(--color-light);
      text-decoration: underline;
    }
  }

  &Download {
    display: flex;
    flex-direction: column;

    &Inner {
      display: block;
    }
  }

  &Terms {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.body {
  display: flex;
}

.bottom {
  margin: 40px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  font-size: var(--font-size-sm);
  font-weight: 600;
}

.sns {
  display: flex;
  gap: 8px;

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Icon {
    width: 20px;
    height: 20px;
  }
}

.snapshot {
  width: 15px;
  height: 18px;
}

@media (--view-md) {
  .footer {
    margin-top: 40px;

    &Logo {
      margin-right: 40px;
    }

    &Column {
      margin-right: 0;
    }

    &Info {
      flex-direction: column;
      gap: 32px;
    }

    &Terms {
      margin-top: 40px;
    }
  }
}
