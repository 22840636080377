.chain {
  width: 256px;
}

.body {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &Button {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 8px;

    &:hover,
    &:focus {
      color: var(--color-dark);
      border: 1px solid var(--color-dark);
    }

    &:active {
      color: var(--color-light);
      background-color: var(--color-dark);
    }
  }

  &Icon {
    width: 24px;
    height: 24px;
  }
}
