.wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: var(--font-size-sm);
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: var(--color-dark);
  transition: transform 0.3s ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
    color: var(--color-dark);
  }

  &:active {
    transform: scale(0.9);
  }
}

.modal {
  width: 368px;

  &Info {
    display: flex;
    align-items: center;
    margin: 32px 0 16px 0;

    &Radio {
      display: flex;
      margin-right: 8px;
      span {
        display: none;
      }
    }

    &Desc {
      line-height: 1;
      font-size: var(--font-size-sm);
    }

    &Link {
      font-weight: 600;
      color: var(--color-dark);
      text-decoration: underline;
    }
  }

  &Inner {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-sm);
  }

  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &Button {
    margin: 8px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 4px 0;
    border-radius: 16px;
    font-size: var(--font-size-lg);
    border: 2px solid var(--color-dark);
    color: var(--color-dark);
    background-color: var(--color-light);

    &:hover,
    &:focus {
      color: var(--color-light);
      background-color: var(--color-dark);
    }

    &:disabled {
      cursor: not-allowed;
      border: 2px solid var(--color-disable);
      color: var(--color-disable);
      background-color: var(--color-light);
    }
  }
}
