.slider {
  position: relative;
  width: 212px;
  margin-top: 3px;
  &Steps {
    position: absolute;
    top: -22px;
    right: 0;
    font-size: 14px;
    color: #ff6c2d;
  }
  &Input {
    position: relative;
    /* -webkit-appearance: none; */
    /* appearance: none; */
    /* border: none; */
    width: 100%;
    height: 2px;
    /* background-color: red; */
    z-index: 2;
    -webkit-appearance: none; /*清除系统默认样式*/
    width: 100% !important;
    border-bottom: 1px solid #000000;
    /*background-size: 30% 100%;!*设置左右宽度比例*!*/

    &::range-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    width: var(--width);
    /*background-color: var(--color-highlight);*/
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    right: 0;
    background-color: var(--color-background-tertiary);
    pointer-events: none;
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.step {
  font-size: 12px;
}

@media (--view-md) {
  .slider {
    position: relative;
    width: 100%;
    &Input::range-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
  }
}
