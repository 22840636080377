.activity {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  &List {
    &Header {
      &Price {
        text-align: right;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        padding-right: 45%;
      }
    }
    &Item {
      text-decoration: none !important;
      display: flex;
      align-items: center;

      &:hover {
        color: #000000;
      }

      div {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        background: #f6f8fd;
        border-radius: 4px;

        img {
          width: 38px;
        }
      }
    }

    &Price {
      text-align: right;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-right: 45%;
      &ETH {
        font-size: 14px;
        line-height: 14px;
        color: #ff6c2d;
        margin-bottom: 4px;
      }

      &USD {
        font-size: 12px;
        line-height: 12px;
        color: #909090;
      }
    }

    &Date {
      color: #909090;
    }
  }
}

.select select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.event {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;

  &Inner {
    flex: 1;
    display: flex;
    justify-content: space-between;
    max-width: 1078px;
  }

  &Title {
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
  }
}

.tableList {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &Wrapper {
    min-width: 1078px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 45px;

  &Inner {
    width: 100%;
    max-width: 1078px;
  }
}

@media (max-width: 1128px) {
  .tableList {
    justify-content: flex-start;
  }
}

@media (max-width: 850px) {
  .activity {
    padding: 0 16px;
  }

  .header {
    &Inner {
      flex-direction: column;
    }
  }

  .select select {
    margin-top: 10px;
    width: 100%;
  }
}
