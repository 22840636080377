.sell {
  margin: 16px 24px;
}

.inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.nav {
  display: flex;
}

.back {
  display: flex;
  align-items: center;

  &Text {
    margin-left: 8px;
  }
}

.content {
  display: flex;
  margin-top: 30px;
}

.card {
  /*width:212px;*/
  /*height:256px;*/
}
.cardView {
  width: 212px;
  height: 256px;
}
.info {
  padding: 0 32px;
  max-width: 768px;
  margin: 0 auto;
  &HeaderMobile {
    display: none;
  }
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Desc {
    font-size: 18px;
    font-weight: 600;
  }

  &Id {
    color: var(--color-highlight);
  }

  &Caption {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }

  &Card {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }

  &Subject {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }

  &Text {
    font-size: 14px;
    color: #000;
  }
  &Price {
    position: relative;
    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 17px;
    }
  }
}

.trade {
  font-size: 18px;
  &View {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }
  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.button {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.prevBtn {
  padding-right: 10px;
  text-align: right;
  background-image: url('/src/assets/icons/arrow-left.svg');
  background-position: 10% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.price {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  &ListedInfo {
    font-size: 14px;
    margin-top: 12px;
    color: #ff6c2d;
    line-height: 15px;
  }
  &Block {
    flex: 1;
  }

  &Input {
    margin-top: 16px;
    padding: 4px 40px;
  }
  &Input input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: revert !important;
    }
  }

  &Card {
    padding: 16px;
    border: 2px solid var(--color-background-secondary);
    background: linear-gradient(
      329.58deg,
      rgba(87, 65, 52, 0.4) 0%,
      rgba(87, 65, 52, 0) 100%
    );
  }

  &Fees {
    display: flex;
    margin-top: 16px;
    color: #000;

    &Name {
      width: 200px;
      font-weight: 600;
      font-size: 14px;
    }

    &Value {
      font-size: 14px;
      color: #000;
    }
  }
}

.steps {
  display: block;
}

.step {
  margin-top: 24px;
  svg {
    fill: none;
    width: 24px;
    height: 24px;
  }
  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;

    &Text {
      margin-left: 5px;
      font-weight: 600;
      line-height: 15px;
    }
  }

  &Content {
    padding: 5px;
    color: #909090;
  }
  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}

.done {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
}
.card .card {
  background-color: red;
}
.sell input {
  width: 100%;
  position: relative;
  border-radius: 0px;
  border-bottom: 1px solid #000;
  color: #909090;
  &:hover,
  &:focus {
    border-radius: 0px;
    border: 0px;
    border-bottom: 2px solid #ff6c2d;
  }
  &:focus {
    border-bottom: 2px solid #ff6c2d;
  }
}
.sell input::-webkit-outer-spin-button,
.sell input::-webkit-inner-spin-button {
  appearance: revert !important;
}
.priceContainer {
  display: flex;
  font-size: 18px;
}
.cardPriceUSD {
  margin-left: 10px;
  color: #ff6c2d;
}
.trading {
  max-width: 404px;
  margin-top: 27px;
}

@media (--view-md) {
  .sell input::-webkit-outer-spin-button,
  .sell input::-webkit-inner-spin-button {
    appearance: revert !important;
  }
  .trading {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .content {
    flex-direction: column;
  }
  .info {
    width: 100%;
    padding: 0px;
    &Header {
      display: none;
    }
    &HeaderMobile {
      display: flex;
    }
  }
  .sell {
    margin: 16px 16px;
  }
  .inner {
    padding: 0px;
  }
  .card {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
