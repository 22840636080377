.wallet {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  border: 2px solid #000;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;

  &:hover {
    color: #fff !important;
    background-color: #000;
    border: 2px solid #000;
  }

  &:focus {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }

  &Top {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0;
    flex-direction: column;
  }

  &Menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    /*padding: 110px 0;*/
    /*border-bottom: 0.5px solid #909090;*/
  }

  &Info {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-right: 0;
    margin-top: 146px;
  }

  &Connect {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
  }

  &Flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.walletDisabled {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #909090;
  border: 2px solid #909090;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;
}

.select {
  &Option {
    display: flex;
    align-items: center;

    svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }
  }
}

.mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &Menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 56px;
    border-bottom: 0.5px solid #909090;
    margin-bottom: 56px;
    &Item:not(:last-child) {
      margin-bottom: 48px;
    }
    &Item {
      display: inline-block;
      position: relative;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      color: #000000;

      &Active {
        &::before {
          width: 12px;
          height: 12px;
          background: #ff6c2d;
          border-radius: 15px;
          content: '';
          position: absolute;
          left: -17px;
          top: 10px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        &::after {
          width: 100%;
          background: none;
        }
      }

      a:visited {
        color: #000000;
      }

      &Tip {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -6px;
        right: -28px;
        width: 35px;
        height: 14px;
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        background: #83d300;
        border-radius: 10px;
        padding: 2px 4px;

        span {
          display: inline-block;
          transform: scale(0.75);
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wallet,
  .walletDisabled {
    &Info {
      margin-top: 0px;
    }
  }
}
