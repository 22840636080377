.level {
  background: #a0deec;
  border-radius: 8px;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Tomorrow';

  .label {
    background: #215a67;
    border-radius: 22px;
    width: 64px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    overflow: hidden;

    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-left: 4px;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -4px;
        height: 100px;
        width: 8px;
        box-sizing: border-box;
        border-left: 3px solid;
        border-right: 3px solid;
        border-color: #3892a7;
      }

      &:before {
        top: 23px;
      }

      &:after {
        bottom: 21px;
      }
    }
  }

  .bar {
    margin-top: 8px;
    background: #fff;
    border-radius: 6px;
    height: 12px;
    overflow: hidden;

    div {
      background: #3892a7;
      height: 100%;
    }
  }
}
