.copyButton {
  width: 18px;
  height: 18px;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    fill: none;
  }
}
