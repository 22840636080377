@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
.header_header__2Lhqg {
  width: 100%;
  height: 140px;
  flex: none;
  padding: 24px;
  font-family: 'Chivo';
}

.header_inner__ty6dS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 1px 6px #00000026;
}

.header_nav__2B9c- {
  display: flex;
  align-items: center;
  grid-gap: 36px;
  gap: 36px;
}

.header_navLogo__1bu5P {
    display: flex;
    color: var(--color-dark);
  }

.header_navLogo__1bu5P:hover,
    .header_navLogo__1bu5P:focus {
      color: var(--color-dark);
    }

.header_navLogoIcon__1Oejq {
      width: 120px;
    }

.header_navItem__3_Aac {
    position: relative;
    border-bottom: 1px solid transparent;
  }

.header_navItem__3_Aac:hover,
    .header_navItem__3_Aac:focus {
      color: var(--color-dark);
      border-bottom: 1px solid var(--color-primary);
    }

.header_navItemActive__IyAdD::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 50%;
        left: 0;
        -webkit-transform: translate3d(-120%, -50%, 0);
                transform: translate3d(-120%, -50%, 0);
        background-color: var(--color-primary);
      }

.header_info__2mO2o {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  background-color: var(--color-light);
}

.header_infoItem__3qvK3 {
    display: none;
  }

.header_infoLink__1yNyI {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    border-radius: 20px;
    padding: 8px 16px;
    grid-gap: 4px;
    gap: 4px;
    color: var(--color-light);
    background-color: var(--color-dark);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

.header_infoLink__1yNyI.header_invalid__sRIEo {
      pointer-events: none;
    }

.header_infoLink__1yNyI:hover,
    .header_infoLink__1yNyI:focus {
      color: var(--color-light);
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

.header_infoLink__1yNyI:active {
      color: var(--color-light);
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.header_infoIcon__3WPSE {
    width: 24px;
    height: 24px;
    z-index: 1;
  }

.header_infoIcon__3WPSE.header_offset__2MPSe {
      z-index: 0;
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }

.header_infoIconWrapper__3aD2K {
      display: flex;
      align-items: center;
      margin-right: -4px;
    }

.header_menu__1cMyC {
  display: none;
}

@media screen and (max-width: 1200px) {
  .header_menu__1cMyC {
    display: flex;
    width: 22px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: var(--z-index-header);
  }

    .header_menuIcon__31l5V {
      position: relative;
      width: 22px;
      height: 2px;
      margin: 14px auto;
      background-color: rgb(136, 136, 136);
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      -webkit-transform-origin: center;
              transform-origin: center;
    }

      .header_menuIcon__31l5V::before,
      .header_menuIcon__31l5V::after {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        background-color: rgb(136, 136, 136);
        transition: inherit;
        -webkit-transform-origin: center;
                transform-origin: center;
      }

      .header_menuIcon__31l5V::before {
        top: -8px;
        left: 0;
      }

      .header_menuIcon__31l5V::after {
        top: 8px;
        left: 0;
      }

      .header_menuIcon__31l5V.header_open__2GjuG {
        background-color: transparent;
      }

        .header_menuIcon__31l5V.header_open__2GjuG::before {
          top: 0;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
        }

        .header_menuIcon__31l5V.header_open__2GjuG::after {
          top: 0;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
        }

  .header_navItem__3_Aac {
    display: none;
  }

  .header_info__2mO2o {
    display: none;
  }

    .header_info__2mO2o.header_open__2GjuG {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      top: 120px;
      left: 16px;
      right: 16px;
      height: calc(100vh - 120px);
      opacity: 1;
      z-index: 1200;
    }

    .header_infoItem__3qvK3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

      .header_infoItem__3qvK3:hover,
      .header_infoItem__3qvK3:focus {
        color: var(--color-dark);
      }
}

.button_button__2pFT2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 8px 36px;
  border-radius: 15px;
  font-size: 18px;
  background: var(--color-button-background);
}
  .button_button__2pFT2.button_normal__1TG1J {
    border: 2px solid var(--color-button);
  }
  .button_button__2pFT2.button_normal__1TG1J:hover,
    .button_button__2pFT2.button_normal__1TG1J:focus {
      border: 2px solid var(--color-button);
      color: var(--color-button);
    }
  .button_button__2pFT2.button_primary__2Z9E_ {
    color: var(--color-background-primary);
    background-color: var(--color-light-primary);
  }
  .button_button__2pFT2.button_primary__2Z9E_:hover,
    .button_button__2pFT2.button_primary__2Z9E_:focus {
      color: var(--color-background-primary);
      background-color: var(--color-highlight);
    }
  .button_button__2pFT2.button_disabled__2ZVTX {
    color: var(--color-disable);
    border: 2px solid var(--color-disable);
  }
  .button_button__2pFT2.button_disabled__2ZVTX:hover,
    .button_button__2pFT2.button_disabled__2ZVTX:focus {
       color: var(--color-disable);
     }
  .button_button__2pFT2:disabled {
    cursor: not-allowed;
    color: var(--color-disable);
  }
  .button_button__2pFT2:disabled:hover,
    .button_button__2pFT2:disabled:focus {
    }

.buttonNew_button__1ZTzA {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  border-radius: 15px;
  font-size: 18px;
  line-height: 20px;
  transition: opacity 0.4s;
}

  .buttonNew_button__1ZTzA.buttonNew_normal__3SuMw {
    border: 2px solid #909090;
    color: #909090;
  }

  .buttonNew_button__1ZTzA.buttonNew_normal__3SuMw:hover {
      opacity: 0.7;
    }

  .buttonNew_button__1ZTzA.buttonNew_primary__35hda {
    color: #fff;
    background-color: #ff6c2d;
  }

  .buttonNew_button__1ZTzA.buttonNew_primary__35hda:hover {
      opacity: 0.7;
    }

  .buttonNew_button__1ZTzA.buttonNew_secondary__3XjdM {
    color: #fff;
    background-color: #3864ff;
  }

  .buttonNew_button__1ZTzA.buttonNew_secondary__3XjdM:hover {
      opacity: 0.7;
    }

  .buttonNew_button__1ZTzA:disabled {
    cursor: not-allowed;
    color: #909090;
    border: solid 2px #909090;
    background-color: transparent;
  }

.radio_radio__3T1hU {
  display: inline-flex;
  align-items: center;
}

.radio_inner__3Sg9K {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  /*background-color: var(--color-background-secondary);*/
  border: solid 1px #000;
  cursor: pointer;
}

.radio_inner__3Sg9K:hover,
  .radio_inner__3Sg9K:focus {
    border: solid 1px #ff6c2d;
  }

.radio_inner__3Sg9K:disabled {
     border-color: var(--color-disable);
     color: var(--color-disable);
     cursor: not-allowed;
  }

.radio_inner__3Sg9K:checked {
    border: solid 1px #ff6c2d;
    background-color: #ff6c2d;
  }

.radio_inner__3Sg9K:checked::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 1px solid #fff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
.radio_point__VL7rS {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  /*background-color: var(--color-background-secondary);*/
  border: solid 1px #000;
  cursor: pointer;
}
.radio_point__VL7rS:hover,
  .radio_point__VL7rS:focus {
     border: solid 1px #ff6c2d;
   }
.radio_point__VL7rS:checked {
    border: solid 1px #ff6c2d;
  }
/*background-color: #ff6c2d;*/
.radio_point__VL7rS:checked::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -4px;
      margin-top: -4px;
      border-radius: 15px;
      background-color: #ff6c2d;
    }
.radio_text__1QOqJ {
  font-size: 12px;
  margin-left: 6px;
  margin-right: 25px;
}

:root {
  --cardsJustifyContent: center;
  --cardsWidth: 142px;
}
.card_pricePoint__YHLRM {
  position: absolute;
  right: -11px;
  top: -7px;
  color: #ff6c2d;
}

.card_priceView__3Ty3Q {
  position: relative;
}

.card_smallCard__1qr6q {
  width: 134px !important;
  height: 165px !important;

  /*
  width: 134px !important;
  height: 165px !important;
  &Info {
     width: 134px !important;
  }
  */
}

.card_smallCardInfo__3mgT_ {
    width: 142px !important;
  }

.card_smallCardInfoChecked__1lyWc {
      padding-left: 4px;
      min-height: 60px !important;
    }

.card_smallCardInfoUnChecked__24Kl4 {
      padding-left: 4px;
      min-height: 60px !important;
    }

.card_card__1PvOV {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #f6f8fd;
  border-radius: 8px;
}

.card_cardItem__RZ60b a:hover {
      color: #000000;
    }

.card_cardItemChecked__pAtK_ {
      height: 234px;
      width: 142px;
      background: #f4f4f4;
      border-radius: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }

.card_cardItemUnChecked__1IauD {
      height: 234px;
      width: 142px;
      width: var(--cardsWidth);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }

.card_cardInfo__1ZUfL {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;
  }

.card_cardInfo__1ZUfL span:only-child {
      margin: 0;
    }

.card_cardId__3O645 {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    height: 14px;
    line-height: 14px;

    /*background-color: var(--color-background-secondary);*/
  }

.card_cardId__3O645 svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

.card_cardCheck__2kNnR {
    position: absolute;
    left: 12px;
    top: 8px;
  }

.card_cardCheck__2kNnR svg {
      fill: none !important;
    }

.card_cardSale__3-Ona {
    font-size: 12px;
    right: 12px;
    top: 8px;
    height: 16px;
    line-height: 14px;
    padding: 0px 3px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }

.card_cardLink__2sYlm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

.card_cardImage__14Tgg {
  }

.card_cardLastPrice__3p9FK {
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #909090;
  }

.card_cardLastPriceUSD__2ek-2 {
      border: 0px;
    }

.card_cardPrice__21tNO {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

.card_cardPriceInner__2FB3d {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 212px;
    }

.card_cardPriceInnerSmall__2dTSy {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 212px;
        width: 134px;
      }

.card_cardPriceETH__1reVH {
      font-size: 18px;
      line-height: 24px;
      margin-right: 4.5px;
    }

.card_cardPriceUSD__hDQNW {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      padding: 7px 5px;
    }

@media (max-width: 968px) {
  .card_card__1PvOV {
    width: 160px;
  }
      .card_cardPriceUSD__hDQNW {
        margin-bottom: 4px;
      }
    .card_cardPriceETH__1reVH {
    }

    .card_cardPriceInner__2FB3d {
      width: 152px;
    }

    .card_cardInfo__1ZUfL {
      min-height: 38px;
    }
}

@media (max-width: 320px) {
  .card_card__1PvOV {
    width: 134px;
  }
}

.message_message__2VZVU {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: var(--z-index-message);
  pointer-events: none;
}

  .message_message-notice__LHnRS {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0px 24px;
    font-size: 18px;
    border-radius: 4px;
    color: #fff;
    background-color: #ff6c2d;
    border-radius: 15px;
    pointer-events: auto;
  }

  .message_message-notice__LHnRS.message_warning__22xZO {
      background-color: #ff6c2d;
      color: #fff;
      border-radius: 15px;
    }

  .message_message-notice__LHnRS .message_icon__2JxIk {
      display: none;
      width: 16px;
      height: 16px;
    }

  .message_message-notice__LHnRS .message_close__197bh {
      display: inline-flex;
      margin-left: 16px;
    }

  .message_message-notice__LHnRS .message_closeIcon__12uDM {
        width: 16px;
        height: 16px;
      }

  .message_message-notice-text__Bq05H {
      margin-left: 8px;
    }

  .message_message-notice__LHnRS.message_message-notice-enter__3ZpYI {
      opacity: 0;
    }

  .message_message-notice__LHnRS.message_message-notice-enter-active__3ssz5 {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }

  .message_message-notice__LHnRS.message_message-notice-exit__3kxfc {
      max-height: 100vh;
      opacity: 1;
    }

  .message_message-notice__LHnRS.message_message-notice-exit-active__3upkU {
      max-height: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in, max-height 0.3s;
    }

.card_overview_container__1RkYs {
  display: flex;
  flex-direction: column;
}
.card_overview_summary__n2mqf {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.card_overview_purchased__3lIiV {
  font-weight: 600;
  font-size: 14px;
  color: #ff6c2d;
  text-decoration: underline;
}
.card_overview_trait__1GR6D {
  display: flex;
  /* width: 128px; */
  border: 2px solid #ff6c2d;
  border-radius: 11px;
  flex-direction: column;
  padding: 3px 7px;
}
.card_overview_traitInfo__3urKc {
    font-size: 18px;
  }
.card_overview_price__3sKGY {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.card_overview_priceTitle__ootcu {
    font-weight: 600;
    font-size: 14px;
  }
.card_overview_priceContainer__2s698 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;
  }
.card_overview_card__30nzv {
  position: relative;
  display: flex;
  top: 0;
  width: 291px;
  height: 360px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f6f8fd;
}
.card_overview_cardSale__Fj3Wt {
    font-size: 12px;
    right: 8px;
    top: 12px;
    height: 19px;
    line-height: 18px;
    padding: 0px 5px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }
.card_overview_cardImage__Z5ONP {
    margin-bottom: 30px;
  }
.card_overview_cardImage__Z5ONP[src=''] {
    opacity: 0;
  }
.card_overview_cardImage__Z5ONP:after {
    content: '' ' ' attr(alt);
  }
.card_overview_cardPrice__3suso {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.card_overview_cardPriceInner__2eqmM {
      display: block;
    }
.card_overview_cardPriceETH__2yzbw {
      font-size: 18px;
      height: 20px;
      line-height: 20px;
    }
.card_overview_cardPriceUSD__1y0P5 {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
    }

@media (max-width: 850px) {
  .card_overview_container__1RkYs {
    width: 100%;
  }
  .card_overview_card__30nzv {
    position: relative;
    width: 100%;
    height: 440px;
  }
}

@media (max-width: 320px) {
  .card_overview_container__1RkYs {
    width: 100%;
  }
  .card_overview_card__30nzv {
    position: relative;
    width: 100%;
    height: 380px;
  }
}

:root {
  --cardsJustifyContent: center;
  --cardsMargin: 20px auto;
  --cardsGap: 4px 15px !important;
  --cardsHeight: 380px;
  --cards720Height: var(--cards720Height);
}
.card_list_list__29Cy0 {
  width: 100%;
  max-width: 1432px;
  flex: 1 1;
  margin: 0 auto;
}
.card_list_listHeader__2kzZ3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
.card_list_listHeaderSearchInput__3Gyq- {
      width: 108px;
    }
.card_list_listHeaderSearchInputLarge__1_xpt {
        width: 212px;
      }

.card_list_check__3Phxf {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.card_list_checkTitle__392QC {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

.card_list_checkSelectAll__3RX9E {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

.card_list_checkSelectAllDisabled__2vzvW {
      color: #909090;
      border: 1px solid #000000;
    }

.card_list_checkSelectAllSelected__311nx {
      border: 1px solid #ff6c2d;
      background: #ff6c2d;
      white-space: nowrap;
      color: #ffffff;
    }

.card_list_checkClearAll__2mhaa {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    line-height: 15px;
    justify-content: center;
    color: #909090;
    cursor: pointer;
  }

.card_list_checkClearAllActive__1MrpM {
      color: #000000;
    }

.card_list_sum__1Nq8W {
  display: flex;
  font-family: Archivo;
  font-size: 20px;
}

.card_list_sort__DgQZw {
  display: flex;
}

.card_list_select__1x_eh:first-child {
  margin: 0 16px;
}

.card_list_select__1x_eh select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.card_list_cards__3AkXO {
  display: flex;
  justify-content: center;
  justify-content: var(--cardsJustifyContent);
  margin: 20px auto;
  margin: var(--cardsMargin);
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.card_list_cardsHeightLargeModal__3_gDK {
      height: 380px;
      height: var(--cardsHeight);
      overflow: auto;
    }

.card_list_cardsHeightSmallModal__37iuu {
      height: 390px;
      overflow: auto;
    }

.card_list_cardsSmall__3q__V {
    margin: 0 auto !important;
    margin-top: 16px !important;
    grid-gap: 4px 15px;
    gap: 4px 15px;
    grid-gap: var(--cardsGap);
    gap: var(--cardsGap);
  }

.card_list_cardsTransferNoFound__399T3 {
    justify-content: center;
  }

.card_list_cardsNotFound__3o4Fd {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 380px;
    min-height: var(--cardsHeight);
    padding-top: 84px;
  }

.card_list_cardsNotFoundLarge__35pHg {
      min-height: 516px;
    }

.card_list_cardsNotFound__3o4Fd span {
      margin-top: 36px;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

.card_list_cardsNotFound__3o4Fd svg {
      width: 72px;
      height: 72px;
      fill: none;
    }

.card_list_pagination__1ywOm {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.card_list_marketplace__1Rm5G .card_list_menu__3fHWM {
  display: none;
}

.card_list_card__2lWGD {
  cursor: inherit;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #f6f8fd;
  border-radius: 8px;
}

.card_list_cardPointer__1a8S2 {
    cursor: pointer;
  }

.card_list_cardPagination__23BqU {
    padding-top: 10px;
    padding-bottom: 30px;
  }

.card_list_cardBreedInfo__cYw0r {
    font-size: 12px;
    color: #909090;
  }

.card_list_cardItem__2jXrz a:hover {
      color: #000000;
    }

.card_list_cardInfo__38TLg {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;
  }

.card_list_cardInfo__38TLg span:only-child {
      margin: 0;
    }

.card_list_cardId__fb18i {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
  }

.card_list_cardId__fb18i svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

.card_list_cardLink__UtgJ9 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

@media (min-width: 1600px) {
  .card_list_list__29Cy0 {
    width: 100%;
    max-width: 100%;
    flex: 1 1;
    margin: 0 auto;
  }
}

@media (max-width: 968px) {
  .card_list_card__2lWGD {
    width: 152px;
    height: 188px;
  }
    .card_list_cardInfo__38TLg {
      width: 152px;
    }
  .card_list_cards__3AkXO {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    height: 321px;
    overflow-y: scroll;
  }
    .card_list_cardsSmall__3q__V {
      height: 380px;
      height: var(--cardsHeight);
      overflow: auto;
    }
}
@media (max-width: 851px) {
  .card_list_open__2H6Uf {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }

  .card_list_menu__3fHWM {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    z-index: var(--z-index-header);
    align-items: center;
    justify-content: flex-end;
  }

  .card_list_menu__3fHWM svg {
    width: 28px;
    height: 28px;
  }
    .card_list_filterRow__EXM85 {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    .card_list_filterBlock__2awc9 {
      margin-right: 0px;
      margin-bottom: 15px;
    }

    .card_list_filterBlock__2awc9:first-child {
      margin-bottom: 0px;
    }

  .card_list_sort__DgQZw {
    justify-content: space-between;
  }

  .card_list_inner__3APh0 {
    margin: 16px;
  }

  .card_list_split__1hqCH {
    margin-top: 15px;
  }
    .card_list_listHeader__2kzZ3 {
      flex-direction: column;
      margin-top: 25px;
    }

      .card_list_listHeaderSmall__3Fhj5 {
        flex-direction: row;
        margin-top: 0px;
      }

  .card_list_pagination__1ywOm {
    padding-top: 0px;
    margin-top: 28px !important;
    padding-bottom: 0px;
  }

  .card_list_select__1x_eh select {
    width: 100%;
  }

  .card_list_select__1x_eh:first-child {
    margin: 0px;
  }

  .card_list_select__1x_eh {
    margin: 0px;
    width: 47%;
  }
}

@media (max-width: 720px) {
      .card_list_listHeaderSmall__3Fhj5 {
        flex-direction: column;
      }

      .card_list_listHeaderSearchInput__3Gyq- {
        width: 100%;
      }

        .card_list_listHeaderSearchInputLarge__1_xpt {
          width: 100%;
        }

  .card_list_pagination__1ywOm {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card_list_sort__DgQZw {
    margin-top: 15px;
    width: 100%;
  }

  .card_list_sum__1Nq8W {
    width: 100%;
    justify-content: space-between;
  }
  .card_list_check__3Phxf {
    margin-left: 0px;
  }
  .card_list_cards__3AkXO {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    height: var(--cards720Height);
    overflow-y: auto;
  }

    .card_list_cardsSmall__3q__V {
      margin-top: 1px !important;
      padding-top: 16px !important;
      height: var(--cards720Height);
      overflow-y: auto;
    }
      .card_list_cardsSmallNotFound__7agdj {
        min-height: 327px !important;
        border-bottom: 0px !important;
      }
      .card_list_cardsHeightLargeModal__3_gDK {
        min-height: var(--cards720Height);
      }

      .card_list_cardsHeightSmallModal__37iuu {
        min-height: var(--cards720Height);
        border-bottom: 1px solid #c7c7c7;
      }
    .card_list_cardsNotFound__3o4Fd {
      min-height: auto;
      justify-content: center;
      padding-top: 0px;
    }

  .card_list_cardListModalContentInput__AlSug {
    font-size: 14px;
  }
}

.checkbox_checkbox__1IMOS {
  display: inline-flex;
  align-items: center;
}

.checkbox_inner__nMVSI {
  display: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

/*
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #000000;
  cursor: pointer;*/

.checkbox_inner__nMVSI:hover,
  .checkbox_inner__nMVSI:focus {
    border: solid 1px #ff6c2d;
  }

.checkbox_inner__nMVSI:checked {
    outline: none;
    -webkit-appearance: none;
            appearance: none;
  }

/*  border: solid 1px #ff6c2d;
    background-color: #ff6c2d;*/

.checkbox_inner__nMVSI:checked::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
.checkbox_checked__1j9Fy {
  cursor: pointer;
  background: #ff6c2d;
  padding: 2.5px 7px;
  border-radius: 15px;
  color: #ffffff;
  border: 1px solid #ff6c2d;
}
.checkbox_unchecked__1EnNz {
  cursor: pointer;
  background: #fff;
  padding: 2.5px 7px;
  border-radius: 15px;
  color: #000000;
  border: 1px solid #000000;
}
.checkbox_text__2weft {
  font-size: 12px;
  z-index: 1100;
}

.input_input__jhdgh {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 56px;
  border-radius: 8px;
  padding: 4px 8px;
  color: var(--color-light-primary);
  border: 2px solid var(--color-background-tertiary);
  background-color: var(--color-background-tertiary);
}

  .input_input__jhdgh:hover,
  .input_input__jhdgh:focus {
    border: 2px solid var(--color-highlight);
  }

  .input_input__jhdgh::-webkit-outer-spin-button,
  .input_input__jhdgh::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
  }

.modal {
  width: 369px;
  border-radius: 8px;
  background-color: #fff;
}

  .modal-inner {
    padding: 24px 24px;
  }

  .modal-header {
    position: relative;
    display: flex;
    align-items: center;
  }

  .modal-title {
    width: 90%;
    font-size: 18px;
    text-align: left;
  }

  .modal-close {
    position: absolute;
    display: inline-flex;
    top: 0;
    right: 0;
  }

  .modal-close svg {
      width: 24px;
      height: 24px;
    }

  .modal-close:hover,
    .modal-close:focus {
    }

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mask-bg);
  z-index: var(--z-index-modal);
}

.mask.mask-enter {
    opacity: 0;
  }

.mask.mask-enter .modal {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.mask.mask-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

.mask.mask-enter-active .modal {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

.mask.mask-exit {
    opacity: 1;
  }

.mask.mask-exit .modal {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }

.mask.mask-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }

.mask.mask-exit-active .modal {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

@media screen and (max-width: 425px) {
  .modal {
    width: 92%;
  }
}

.modal_modal__11K8r {
  width: auto;
  border-radius: 8px;
  background-color: var(--color-light);
}

  .modal_modal__11K8r .modal_inner__1Fo_c {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-family: 'Chivo';
  }

  .modal_modal__11K8r .modal_header__15s31 {
    position: relative;
    display: flex;
    align-items: center;
  }

  .modal_modal__11K8r .modal_title__ttX-X {
    width: 90%;
    font-size: var(--font-size-lg);
    text-align: left;
  }

  .modal_modal__11K8r .modal_close__3IoCp {
    position: absolute;
    display: inline-flex;
    top: 0;
    right: 0;
  }

  .modal_modal__11K8r .modal_icon__S_Qk_ {
    width: 24px;
    height: 24px;
  }

.modal_mask__YQtdK {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mask-bg);
  z-index: var(--z-index-modal);
}

.modal_mask__YQtdK.modal_mask-enter__3vWvl {
    opacity: 0;
  }

.modal_mask__YQtdK.modal_mask-enter__3vWvl .modal_modal__11K8r {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.modal_mask__YQtdK.modal_mask-enter-active__2ko5a {
    opacity: 1;
    transition: opacity 0.3s;
  }

.modal_mask__YQtdK.modal_mask-enter-active__2ko5a .modal_modal__11K8r {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

.modal_mask__YQtdK.modal_mask-exit__3Yw98 {
    opacity: 1;
  }

.modal_mask__YQtdK.modal_mask-exit__3Yw98 .modal_modal__11K8r {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }

.modal_mask__YQtdK.modal_mask-exit-active__-vuvC {
    opacity: 0;
    transition: opacity 0.3s;
  }

.modal_mask__YQtdK.modal_mask-exit-active__-vuvC .modal_modal__11K8r {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

.pagination_pagination__1b6wU {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pagination_pc__cVugE {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pagination_mobile__1gaBl {
  display: none;
}
.pagination_nextBtn__JrVzT {
  padding-left: 10px;
  text-align: left;
  background-image: url(/static/media/arrow-right.32f5d866.svg);
  background-position: 90% 50%;
}
.pagination_nextBtnMobile__3ttSR {
    width: 50px !important;
    background-position: center;
  }
.pagination_nextBtnMobile__3ttSR:hover,
    .pagination_nextBtnMobile__3ttSR:disabled {
      background-position: center !important;
    }
.pagination_nextBtn__JrVzT:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }
.pagination_nextBtn__JrVzT:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    border: solid 2px #e5e5e5;
  }
.pagination_prevBtn__1k_Fn {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.pagination_prevBtnMobile__21tSD {
    width: 50px !important;
    background-position: center;
  }
.pagination_prevBtnMobile__21tSD:hover,
    .pagination_prevBtnMobile__21tSD:disabled {
      background-position: center !important;
    }
.pagination_prevBtn__1k_Fn:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.pagination_prevBtn__1k_Fn:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.pagination_button__2xeL_ {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.pagination_text__1wDYx {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination_page__2CPhX {
  margin: 0 24px;
  font-size: 18px;
}

.pagination_input__2tRyC {
  margin: 0 12px;
  width: 55px;
  height: 29px;
  text-align: center;
  /* border-radius: 4px; */
  border-bottom: solid 1px #000;
  color: #000;
  background-color: var(--color-background-tertiary);
}

@media screen and (max-width: 768px) {
  .pagination_pc__cVugE {
    display: none;
  }
  .pagination_mobile__1gaBl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
    .pagination_mobilePageBar__nOP7g {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  .pagination_page__2CPhX {
    text-align: center;
  }
}

.select_select__2ohZ4 {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
}
  .select_selectWrapper__1PSeM {
    position: relative;
  }
select {
  border: solid 1px #000;
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
    no-repeat scroll right center transparent;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
}
select::-ms-expand {
  display: none; /* remove default arrow in IE 10 and 11 */
}

.select_option__38Ulf {
  color: var(--color-highlight);
  background-color: var(--color-background-primary);
}

.select_arrowDown__2ZcJt {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 0;
  height: 16px;
  width: 16px;
  fill: currentColor;
}

.slider_slider__3TXi0 {
  position: relative;
  width: 212px;
  margin-top: 3px;
}
  .slider_sliderSteps__3mN36 {
    position: absolute;
    top: -22px;
    right: 0;
    font-size: 14px;
    color: #ff6c2d;
  }
  .slider_sliderInput__2kkEm {
    position: relative;
    /* -webkit-appearance: none; */
    /* appearance: none; */
    /* border: none; */
    width: 100%;
    height: 2px;
    /* background-color: red; */
    z-index: 2;
    -webkit-appearance: none; /*清除系统默认样式*/
    width: 100% !important;
    border-bottom: 1px solid #000000;
  }
  /*background-size: 30% 100%;!*设置左右宽度比例*!*/
  .slider_sliderInput__2kkEm::-moz-range-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_sliderInput__2kkEm::-ms-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_sliderInput__2kkEm::-webkit-slider-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_slider__3TXi0::before {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    width: var(--width);
    /*background-color: var(--color-highlight);*/
    pointer-events: none;
    z-index: 1;
  }
  .slider_slider__3TXi0::after {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    right: 0;
    background-color: var(--color-background-tertiary);
    pointer-events: none;
  }

.slider_steps__1XsKb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.slider_step__1g0c9 {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .slider_slider__3TXi0 {
    position: relative;
    width: 100%;
  }
    .slider_sliderInput__2kkEm::-moz-range-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
    .slider_sliderInput__2kkEm::-ms-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
    .slider_sliderInput__2kkEm::-webkit-slider-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
}

.table_tableHeaderRow__1CRVs:nth-child(2) {
}
.table_table__2bx3B {
  display: table;
  width: 100%;
  min-width: 448px;
  overflow-x: scroll;
  table-layout: fixed;
  border-collapse: collapse;
}
.table_tableWrapper__3GeVH {
    width: 100%;
    overflow-y: auto;
    max-height: 516px;
    padding: 1px 0px;
  }
.table_tableHeader__1BOvU {
    display: table-header-group;
  }
.table_tableHeaderRow__1CRVs {
      display: table-row;
      height: 36px;
    }
.table_tableHeaderCell__3X_Kj {
      font-size: 12px;
      color: #909090;
      display: table-cell;
      vertical-align: middle;
      background-color: #eeeeee;
      padding: 0px 10px;
    }
.table_tableHeaderCell__3X_Kj:nth-child(2) {
        padding-left: 0px;
      }
.table_tableHeaderCell__3X_Kj:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      padding: 0px;
    }
.table_tableHeaderCell__3X_Kj:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
.table_tableHeaderCell__3X_Kj:nth-last-child(2) {
      padding-right: 0px;
      width: 72px;
    }
.table_tableBody__1jQeE {
    display: table-row-group;
    position: relative;
  }
.table_tableBodyRow__3kRxW {
      position: relative;
      display: table-row;
      height: 48px;
    }
.table_tableBodyRowSplit__rP6ng {
        position: absolute;
        width: 428px;
        border-bottom: 1px solid #000;
        margin-left: 10px;
      }
.table_tableBodyCell__25xH2 {
      font-size: 12px;
      display: table-cell;
      vertical-align: middle;
      padding: 0px 10px;
      width: 71px;
      border-bottom: solid 1px #000;
    }
.table_tableBodyCell__25xH2 a {
        text-decoration: underline;
      }
.table_tableBodyCell__25xH2:nth-child(2) {
        padding-left: 0px;
      }
.table_tableBodyCell__25xH2:first-child {
      border-bottom: 0px;
    }
.table_tableBodyCell__25xH2:last-child {
      border-bottom: 0px;
    }
.table_tableBodyCell__25xH2:nth-last-child(2) {
      padding-right: 0px;
      width: 10%;
    }

.table_table__1xeD3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
  .table_tableWrapper__2w5Bk {
    width: 100%;
    padding: 1px 0px;
  }
  .table_tableHeader__3S9Lo {
    display: flex;
  }
  .table_tableHeaderRow__1ZsKJ {
      display: flex;
      height: 36px;
      flex: 1 1;
      padding: 0 10px;
      align-items: center;
      background-color: #eeeeee;
      border-radius: 8px;
    }
  .table_tableHeaderCell__3-kiB {
      font-size: 12px;
      font-weight: 600;
      color: #909090;
      display: flex;
      align-items: center;
      vertical-align: middle;
    }
  .table_tableHeaderCell__3-kiB:not(:last-child) {
      padding-right: 10px;
    }
  .table_tableBody__1Kc8R {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .table_tableBodyRow__3b68l {
      position: relative;
      display: flex;
      height: 64px;
      margin: 0 10px;
      border-bottom: 1px solid #eeeeee;
    }
  .table_tableBodyRowSplit__3LVRr {
        border-bottom: 1px solid #eeeeee;
        margin-left: 10px;
      }
  .table_tableBodyCell__3hAaE {
      font-size: 12px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      width: 71px;
    }
  /*border-bottom: solid 1px #000;*/
  .table_tableBodyCell__3hAaE a {
        text-decoration: underline;
      }
  .table_tableBodyCell__3hAaE a:hover {
          color: #000000;
        }
  .table_tableBodyCell__3hAaE:not(:last-child) {
      padding-right: 10px;
    }

.wallet_wallet__34pyU {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  border: 2px solid #000;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;
}

  .wallet_wallet__34pyU:hover {
    color: #fff !important;
    background-color: #000;
    border: 2px solid #000;
  }

  .wallet_wallet__34pyU:focus {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }

  .wallet_walletTop__1fOvP {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0;
    flex-direction: column;
  }

  .wallet_walletMenu__1rw8G {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    /*padding: 110px 0;*/
    /*border-bottom: 0.5px solid #909090;*/
  }

  .wallet_walletInfo__7aWHR {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-right: 0;
    margin-top: 146px;
  }

  .wallet_walletConnect__14_8A {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
  }

  .wallet_walletFlex__gLSIn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.wallet_walletDisabled__2bgu3 {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #909090;
  border: 2px solid #909090;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;
}

.wallet_selectOption__2FM9W {
    display: flex;
    align-items: center;
  }

.wallet_selectOption__2FM9W svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }

.wallet_mobile__22Cq2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.wallet_mobileMenu__2fFzC {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 56px;
    border-bottom: 0.5px solid #909090;
    margin-bottom: 56px;
  }

.wallet_mobileMenuItem__1Fvh8:not(:last-child) {
      margin-bottom: 48px;
    }

.wallet_mobileMenuItem__1Fvh8 {
      display: inline-block;
      position: relative;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

.wallet_mobileMenuItemActive__2KgU7::before {
          width: 12px;
          height: 12px;
          background: #ff6c2d;
          border-radius: 15px;
          content: '';
          position: absolute;
          left: -17px;
          top: 10px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

.wallet_mobileMenuItemActive__2KgU7::after {
          width: 100%;
          background: none;
        }

.wallet_mobileMenuItem__1Fvh8 a:visited {
        color: #000000;
      }

.wallet_mobileMenuItemTip__1K79X {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -6px;
        right: -28px;
        width: 35px;
        height: 14px;
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        background: #83d300;
        border-radius: 10px;
        padding: 2px 4px;
      }

.wallet_mobileMenuItemTip__1K79X span {
          display: inline-block;
          -webkit-transform: scale(0.75);
                  transform: scale(0.75);
        }

@media (max-width: 850px) {
    .wallet_walletInfo__7aWHR, .wallet_walletDisabledInfo__2WTH5 {
      margin-top: 0px;
    }
}

.wallet_connect_modal_walletConnectModal__1zZFK {
  width: 369px;
  height: 286px;
  /*&Inner {*/
  /*  margin-bottom: 16px;*/
  /*  &Info {*/
  /*     display: flex;*/
  /*     align-items: center;*/
  /*     margin-top: 32px;*/
  /*     margin-bottom: 16px;*/
  /*     &Radio {*/
  /*       display: flex;*/
  /*       margin-right: 9px;*/
  /*       span {*/
  /*           display: none;*/
  /*       }*/
  /*     }*/
  /*     &Description {*/
  /*         display: flex;*/
  /*         align-items: center;*/
  /*         font-weight: 400;*/
  /*         font-size: 14px;*/
  /*         line-height: 15px;*/
  /*         color: #000000;*/
  /*     }*/
  /*  }*/
  /*  &Wallet {*/
  /*    width:321px;*/
  /*    margin-top: 16px;*/
  /*    svg {*/
  /*      width: 24px;*/
  /*      height: 24px;*/
  /*      margin-right: 12px;*/
  /*    }*/
  /*  }*/
  /*}*/
}

.wallet_connect_component_walletConnectModalInner__3Pe0Y {
    margin-bottom: 16px;
  }
    .wallet_connect_component_walletConnectModalInnerInfo__3ldkA {
       display: flex;
       align-items: center;
       margin-top: 32px;
       margin-bottom: 16px;
    }
    .wallet_connect_component_walletConnectModalInnerInfoRadio__37KAd {
         display: flex;
         margin-right: 9px;
       }
    .wallet_connect_component_walletConnectModalInnerInfoRadio__37KAd span {
             display: none;
         }
    .wallet_connect_component_walletConnectModalInnerInfoDescription__2iBKQ {
           font-weight: 400;
           font-size: 14px;
           line-height: 15px;
           color: #000000;
       }
    .wallet_connect_component_walletConnectModalInnerInfoDescription__2iBKQ a {
               font-weight: 600;
               -webkit-text-decoration-line: underline;
                       text-decoration-line: underline;
               cursor: pointer;
           }
    .wallet_connect_component_walletConnectModalInnerInfoDescription__2iBKQ a:hover {
                    color: #000000;
               }
    .wallet_connect_component_walletConnectModalInnerWallet__36kmV {
      width:321px;
      margin-top: 16px;
    }
    .wallet_connect_component_walletConnectModalInnerWallet__36kmV svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

.wallet_wallet__ZgT6h {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: var(--font-size-sm);
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: var(--color-dark);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

  .wallet_wallet__ZgT6h:hover,
  .wallet_wallet__ZgT6h:focus {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: var(--color-dark);
  }

  .wallet_wallet__ZgT6h:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }

.wallet_modal__3BsrL {
  width: 368px;
}

.wallet_modalInfo__3py_9 {
    display: flex;
    align-items: center;
    margin: 32px 0 16px 0;
  }

.wallet_modalInfoRadio__2WwZQ {
      display: flex;
      margin-right: 8px;
    }

.wallet_modalInfoRadio__2WwZQ span {
        display: none;
      }

.wallet_modalInfoDesc__27dmJ {
      line-height: 1;
      font-size: var(--font-size-sm);
    }

.wallet_modalInfoLink__1HKVC {
      font-weight: 600;
      color: var(--color-dark);
      text-decoration: underline;
    }

.wallet_modalInner__1ERBQ {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-sm);
  }

.wallet_modalIcon__Pd3z5 {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

.wallet_modalButton__26d0e {
    margin: 8px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 4px 0;
    border-radius: 16px;
    font-size: var(--font-size-lg);
    border: 2px solid var(--color-dark);
    color: var(--color-dark);
    background-color: var(--color-light);
  }

.wallet_modalButton__26d0e:hover,
    .wallet_modalButton__26d0e:focus {
      color: var(--color-light);
      background-color: var(--color-dark);
    }

.wallet_modalButton__26d0e:disabled {
      cursor: not-allowed;
      border: 2px solid var(--color-disable);
      color: var(--color-disable);
      background-color: var(--color-light);
    }

.select_input_searchInput__9nttv {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
}
  .select_input_searchInputWrapper__2UWVs {
    position: relative;
    border-bottom: 1px solid #000000;
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
  }
  .select_input_searchInputWrapper__2UWVs svg {
      fill: none !important;
      width: 14px !important;
      height: 14px !important;
      position: absolute;
      left: 0px;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_input__3PEvI {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #909090;
      padding-left: 20px;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_input__3PEvI::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background: url(https://yxs-web.oss-cn-beijing.aliyuncs.com/328e4d97f9d0d68ea04e872f68e508e3.png)
        no-repeat;
      background-size: contain;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_close__2a99H {
      cursor: pointer;
    }

.tokenInfo_tokenInfo__16A4i {
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #eee;
  border-radius: 16px;
}

.tokenInfo_baseInfo__2hwvM {
  display: flex;
  align-items: center;
  padding: 14px;
}

.tokenInfo_baseInfo__2hwvM svg {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

.tokenInfo_baseInfo__2hwvM .tokenInfo_balanceWrapper__3wUiH {
    flex: 1 1;
    margin-left: 16px;
  }

.tokenInfo_baseInfo__2hwvM .tokenInfo_balance__2f8QA {
    font-size: 28px;
    line-height: 30px;
  }

.tokenInfo_baseInfo__2hwvM .tokenInfo_price__1KQ8i {
    display: inline-block;
    margin-top: 8px;
    padding: 3px 6px;
    border: 2px solid #ff6c2d;
    border-radius: 15px;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    color: #ff6c2d;
  }

.tokenInfo_contract__1SbSi {
  display: flex;
  align-items: center;
  padding: 6px 14px 14px;
  font-size: 18px;
  line-height: 20px;
}

.tokenInfo_contract__1SbSi .tokenInfo_addr__tOjSj {
    flex: 1 1;
    margin-left: 32px;
    margin-right: 10px;
    text-align: right;
  }

.tokenInfo_actions__3PR2i {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #eee;
}

.tokenInfo_actions__3PR2i .tokenInfo_btn__2zA2B {
    width: 126px;
  }

.tokenInfo_actions__3PR2i .tokenInfo_btn__2zA2B + .tokenInfo_btn__2zA2B {
    margin-left: 12px;
  }

.tokenInfo_tokenInfo__16A4i.tokenInfo_disabled__1u7WZ .tokenInfo_baseInfo__2hwvM .tokenInfo_icon__JDJY_ {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      opacity: 0.4;
    }

.tokenInfo_tokenInfo__16A4i.tokenInfo_disabled__1u7WZ .tokenInfo_baseInfo__2hwvM .tokenInfo_balanceWrapper__3wUiH {
      color: #C7C7C7;
    }

.tokenInfo_tokenInfo__16A4i.tokenInfo_disabled__1u7WZ .tokenInfo_baseInfo__2hwvM .tokenInfo_price__1KQ8i {
      border-color: #C7C7C7;
      color: #C7C7C7;
    }

@media screen and (max-width: 968px) {
  .tokenInfo_actions__3PR2i {
    padding: 20px 12px;
  }
    .tokenInfo_actions__3PR2i .tokenInfo_btn__2zA2B {
      flex: 1 1;
    }
}

.noResult_noResult__3WDbb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: rgba(238, 238, 238, 0.5);
}
  .noResult_noResult__3WDbb svg {
    width: 60px;
    height: 60px;
  }
  .noResult_noResultInfo__3gs1a {
    font-size: 14px;
    line-height: 16px;
    color: #909090;
  }

@media screen and (max-width: 968px) {
  .noResult_noResult__3WDbb {
    height: 144px;
  }
}

.copyButton_copyButton__WTAqn {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
  .copyButton_copyButton__WTAqn svg {
    width: 18px;
    height: 18px;
    fill: none;
  }

.watchAssetButton_watchAssetIcon__7Wnsa {
    cursor: pointer;
}

.account_account__1LxmQ {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  font-family: 'Chivo';
}

  .account_accountInfo__16ON- {
    display: flex;
    flex-direction: column;
  }

  .account_accountBalance__2Y4wX {
    display: flex;
    font-size: var(--font-size-sm);
    font-weight: 600;
  }

  .account_accountView__11qF2 {
    display: flex;
  }

  .account_accountLink__1v0hi {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-size: var(--font-size-xs);
    border-radius: 8px;
    padding: 8px 16px;
    background-color: var(--color-primary);
    color: var(--color-dark);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .account_accountLink__1v0hi:hover,
    .account_accountLink__1v0hi:focus {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      color: var(--color-dark);
    }

  .account_accountLink__1v0hi:active {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.account_address__B3rJa {
  font-size: var(--font-size-xs);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .account_account__1LxmQ {
    flex-direction: column;
  }
}

.base_base__1AZWc {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.base_transfer__2-nQm svg {
  width: 24px;
  height: 24px;
  fill: none;
}
.base_transfer__2-nQm {
  padding: 15px 24px;
  margin: 0 auto;
}
.base_transferMigrate__2O5p7 {
    background-color: #83d300 !important;
  }
.base_transferMigrateBtn__385dX {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      border: 2px solid #000000;
      text-align: center;
      border-radius: 15px;
      padding: 0px 10px !important;
      width: auto !important;
      margin-bottom: 0px !important;
      background-color: #83d300 !important;
      color: #000000 !important;
    }
.base_transferInner__15Abq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--color-primary);
    border-radius: 11px;
    padding: 20px 20px;
  }
.base_transferMenu__3FupF {
    min-width: 345px;
    text-align: right;
  }
.base_transferBtn__3sKtL {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: var(--color-primary) !important;
    color: var(--color-dark) !important;
  }
.base_transferInfo__2NDe1 {
    display: flex;
    align-items: center;
  }
.base_transferInfoIcon__1j7SL {
      display: flex;
      align-items: center;
    }
.base_transferInfoBlock__3xl62 {
      text-align: left;
      margin-left: 15px;
    }
.base_transferInfoBlockTitle__3hB4N {
        font-weight: 600;
        font-size: 18px;
      }
.base_transferInfoBlockMsg__2-AqD {
        font-size: 14px;
        line-height: 16px;
      }

.base_steps__3NkbC {
  display: block;
}

.base_step__3OeBR {
  margin-top: 24px;
}

.base_step__3OeBR svg {
    fill: none;
  }

.base_stepTitle__1kacP {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.base_stepTitleText__1R1c0 {
      margin-left: 5px;
      font-weight: 600;
    }

.base_stepContent__1efQ1 {
    padding: 5px;
    color: #909090;
  }

.base_stepSuccess__2aLeg {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.base_modalNotice__3ftav {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #c7c7c7;
}

.base_modalNoticeTitle__2NXyp {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #909090;
    margin-bottom: 8px;
  }

.base_modalNoticeMsg__tlSUs {
    font-size: 12px;
    line-height: 13px;
    color: #909090;
  }

.base_cardListModal__3pFpG {
    width: 968px;
    height: 630px;
  }

.base_cardListModalContent__3XbMy {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

.base_cardListModalContentPagination__2rZ40 {
        margin-top: 0px;
        padding-top: 10px;
      }

.base_cardListModalContentBtn__3wvzW {
        text-align: center;
        margin-top: 18px;
      }
.base_migrateSuccess__j7PFP div {
  padding: 15px 0px;
}

@media (max-width: 968px) {
  .base_transfer__2-nQm {
    flex-direction: column;
    padding: 16px 16px;
  }
      .base_transferMigrateBtn__385dX {
        font-size: 14px !important;
      }
    .base_transferBtn__3sKtL {
      font-size: 14px !important;
    }
    .base_transferMenu__3FupF {
      text-align: left;
    }
      .base_transferInfoBlock__3xl62 {
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .base_transferInfoIcon__1j7SL {
        display: none;
      }
    .base_transferInner__15Abq {
      flex-direction: column;
      align-items: flex-start;
    }
    .base_cardListModal__3pFpG {
      width: 80%;
      min-width: 370px;
      height: 664px;
    }
      .base_cardListModalContent__3XbMy {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
        .base_cardListModalContentPagination__2rZ40 {
          margin-top: 36px;
        }
        .base_cardListModalContentBtn__3wvzW {
          text-align: left;
          margin-top: 30px;
        }
}
@media (width: 320px) {
    .base_transferBtn__3sKtL {
      font-size: 15px !important;
      width: 250px !important;
      margin-bottom: 0px !important;
      padding: 0px !important;
      background-color: #eeeeee !important;
      color: #ff6c2d !important;
      border: 1px solid #ff6c2d;
    }
}

.footer_footer__3BEx2 {
  margin-top: 80px;
  font-family: 'Chivo';
  background-color: var(--color-dark);
  color: var(--color-light);
  padding: 48px 24px;
}

  .footer_footerLogo__2n0aB {
    display: flex;
    align-items: flex-start;
    margin-right: 80px;
  }

  .footer_footerLogoLink__-gca8 {
      display: flex;
      color: var(--color-light);
    }

  .footer_footerLogoIcon__1YObA {
      width: 104px;
    }

  .footer_footerInfo__2x8ix {
    display: flex;
    flex: 1 1;
  }

  .footer_footerColumn__3pMge {
    margin-right: 20%;
  }

  .footer_footerItem__1BUD5 {
    margin-top: 12px;
  }

  .footer_footerTitle__1WEjw {
    font-size: var(--font-size-md);
    font-weight: 600;
  }

  .footer_footerSubTitle__17Kx_ {
    font-size: var(--font-size-md);
    font-weight: 600;
  }

  .footer_footerLink__J6y0l {
    color: var(--color-light);
  }

  .footer_footerLink__J6y0l:hover,
    .footer_footerLink__J6y0l:focus {
      color: var(--color-light);
      text-decoration: underline;
    }

  .footer_footerDownload__JouJs {
    display: flex;
    flex-direction: column;
  }

  .footer_footerDownloadInner__3icRO {
      display: block;
    }

  .footer_footerTerms__1CrZB {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }

.footer_inner__3EJxT {
  max-width: 1200px;
  margin: 0 auto;
}

.footer_body__3dIMz {
  display: flex;
}

.footer_bottom__1t8yL {
  margin: 40px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_copyright__35dIc {
  font-size: var(--font-size-sm);
  font-weight: 600;
}

.footer_sns__3q4GQ {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.footer_snsLink__2E1fG {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.footer_snsIcon__3aUUq {
    width: 20px;
    height: 20px;
  }

.footer_snapshot__1Yles {
  width: 15px;
  height: 18px;
}

@media screen and (max-width: 768px) {
  .footer_footer__3BEx2 {
    margin-top: 40px;
  }

    .footer_footerLogo__2n0aB {
      margin-right: 40px;
    }

    .footer_footerColumn__3pMge {
      margin-right: 0;
    }

    .footer_footerInfo__2x8ix {
      flex-direction: column;
      grid-gap: 32px;
      gap: 32px;
    }

    .footer_footerTerms__1CrZB {
      margin-top: 40px;
    }
}

.select-chain_chain__1wtEl {
  width: 256px;
}

.select-chain_body__LWECD {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.select-chain_bodyButton__3I4C0 {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 8px;
  }

.select-chain_bodyButton__3I4C0:hover,
    .select-chain_bodyButton__3I4C0:focus {
      color: var(--color-dark);
      border: 1px solid var(--color-dark);
    }

.select-chain_bodyButton__3I4C0:active {
      color: var(--color-light);
      background-color: var(--color-dark);
    }

.select-chain_bodyIcon__3GLq9 {
    width: 24px;
    height: 24px;
  }

.header_header__2s1Aq {
  height: 70px;

  background-image: url(/static/media/header-nav.1ccb4c83.png);
  background-repeat: no-repeat;
}
  .header_headerCursor__3Ymw2 {
    width: 12px;
    height: 12px;
    background: #ff6c2d;
    border-radius: 10px;
    margin-right: 4px;
  }
.header_header__2s1Aq a:hover,
.header_header__2s1Aq a:visited,
.header_header__2s1Aq a:active {
  color: #000000;
}
.header_header__2s1Aq a:hover::after {
  width: 100%;
}
.header_inner__1fJNH {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 24px;
}
.header_innerView__2t_JW {
    display: flex;
    align-items: center;
    justify-content: center;
  }
.header_split__3cm35 {
  margin: 0 24px;
  border-bottom: 0.5px solid #000;
}
.header_logo__x4wxZ {
  display: inline-flex;
  margin-right: 89px;
}
.header_logo__x4wxZ svg {
    width: 104px;
    height: 100%;
    margin: 15px 20px;
    margin-left: 0px;
    fill: none;
  }
.header_nav__IqH6V {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.header_navMenu__z7_KU {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.header_navItem__24nDX::after {
    content: '';
    left: 50%;
    position: absolute;
    height: 1px;
    bottom: 0;
    transition: 0.2s;
    width: 0%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: linear-gradient(to right, #ff6c2d, #ff6c2d);
  }
.header_navItem__24nDX {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    line-height: 20px;
  }
.header_navItemActive__1Ak-x::before {
        width: 12px;
        height: 12px;
        background: #ff6c2d;
        border-radius: 15px;
        content: '';
        position: absolute;
        left: -17px;
        top: 10px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
.header_navItemActive__1Ak-x::after {
        width: 100%;
        background: none;
      }
.header_navItemTip__1tum3 {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -6px;
      right: -28px;
      width: 35px;
      height: 14px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      background: #83d300;
      border-radius: 10px;
      padding: 2px 4px;
    }
.header_navItemTip__1tum3 span {
        display: inline-block;
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
      }
.header_navItem__24nDX:not(:last-child) {
    margin-right: 52px;
  }
.header_navLink__CriGU {
    display: flex;
    font-size: 18px;
    font-weight: 600;
  }
.header_navLink__CriGU.header_active__ek461 {
      font-weight: bold;
      color: #000000;
      border-bottom: 2px solid var(--color-highlight);
    }
.header_navInfo__334A0 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
.header_navInfoBtn__N3F_b {
      display: block;
      margin-right: 24px;
      margin-top: 6px;
    }
.header_navInfoBtn__N3F_b img {
        width: 50%;
        max-width: 180px;
      }
.header_navInfoBtnMobile__13vnp {
         display: none;
         flex-wrap: wrap;
         align-items: center;
         padding: 0 16px;
      }
.header_navInfoBtnMobile__13vnp img {
           max-width: 170px;
         }

.header_menu__DxgDf {
  display: none;
}

@media (max-width: 1335px) {
  .header_navInfoBtn__N3F_b {
    display: none;
  }
    .header_navInfoBtnMobile__13vnp {
      display: flex;
    }
}
@media (max-width: 1127px) {
  .header_logo__x4wxZ {
    margin-right: 30px;
  }
}
@media (max-width: 1070px) {
  .header_logo__x4wxZ {
    margin-right: 15px;
  }
  .header_navItem__24nDX:not(:last-child) {
    margin-right: 26px;
  }
}

@media (max-width: 850px) {
    .header_navInfoBtnMobile__13vnp {
      justify-content: space-between;
    }
  .header_header__2s1Aq {
    height: 56px;
  }
  .header_split__3cm35 {
    margin: 0 16px;
  }
  .header_inner__1fJNH {
    /*padding:16px;*/
    padding: 0 16px;
    height: 56px;
  }

  .header_logo__x4wxZ {
    font-size: 24px;
  }
    .header_logo__x4wxZ svg {
      width: 104px;
    }

  .header_nav__IqH6V {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    z-index: var(--z-index-header);
    background-color: #fff;
  }
    .header_nav__IqH6V.header_open___icDe {
      z-index: 1399;
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s ease-in-out;
    }

    .header_navMenu__z7_KU {
      display: none;
    }

    .header_navItem__24nDX {
      font-size: 18px;
      height: auto;
      width: 100%;
      margin: 16px 0;
    }

    .header_navLink__CriGU {
      justify-content: flex-start;
    }

    .header_navInfo__334A0 {
      margin: 15px 16px;
    }
  .header_menu__DxgDf {
    display: flex;
    width: 22px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: var(--z-index-header);
  }

    .header_menuIcon__15tso {
      position: relative;
      width: 22px;
      height: 2px;
      margin: 14px auto;
      background-color: #000;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      -webkit-transform-origin: center;
              transform-origin: center;
    }

      .header_menuIcon__15tso::before,
      .header_menuIcon__15tso::after {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        background-color: #000;
        transition: inherit;
        -webkit-transform-origin: center;
                transform-origin: center;
      }

      .header_menuIcon__15tso::before {
        top: -8px;
        left: 0;
      }

      .header_menuIcon__15tso::after {
        top: 8px;
        left: 0;
      }

      .header_menuIcon__15tso.header_open___icDe {
        background-color: transparent;
      }

        .header_menuIcon__15tso.header_open___icDe::before {
          top: 0;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
        }

        .header_menuIcon__15tso.header_open___icDe::after {
          top: 0;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
        }
}

.account_accountInfo__3j_Hy a:hover {
  color: #ff6c2d !important;
}
.account_selectOption__2grGo {
    display: flex;
    align-items: center;
  }
.account_selectOption__2grGo svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }
.account_pc__1B9K2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account_pcETH__2vZYK {
    display: flex;
    align-items: center;
    height: 70px;
    margin-right: 20px;
  }
.account_pcETH__2vZYK ul {
      display: flex !important;
      flex-direction: column;
      margin-left: 100px;
      list-style: none;
      width: 150px;
      height: 24px;
      line-height: 23px;
      border-bottom: 1px solid #000000;
      overflow: hidden;
      cursor: pointer;
      background-color: red;
      background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
        no-repeat scroll right center transparent;
    }
.account_pcETH__2vZYK ul li {
        display: flex;
        align-items: center;
      }
/*在选择框的最右侧中间显示小箭头图片*/
.account_pcETH__2vZYK ul li svg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
.account_pcETH__2vZYK svg {
      width: 102px;
      height: 26px;
    }
.account_mobile__1RDy8 {
  display: none;
}
.account_address__3hwfs svg {
  position: relative;
  width: 12px;
  height: 12px;
  fill: none;
}
.account_account__2DH1h {
  display: flex;
  align-items: center;
  height: 100%;
}
.account_accountBalance__1-X5E {
    float: left;
    position: relative;
    height: 14px;
    line-height: 14px;
  }
.account_accountAddress__1WRqP {
    font-size: 12px;
    cursor: pointer;
  }
.account_accountInfo__3j_Hy {
    display: block;
    font-size: 12px;
    margin-right: 33px;
    position: relative;
  }
.account_accountInfoAddress__3aSlZ {
      height: 12px;
      line-height: 12px;
      float: left;
      clear: both;
    }
.account_accountBalance__1-X5E svg {
    position: absolute;
    top: 0;
    right: -8px;
    width: 6px;
    height: 6px;
  }
.account_accountText__1q7Tp {
    height: 40px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    border: 2px solid #000;
    border-radius: 15px;
    justify-content: center;
    padding: 0px 22px;
  }
.account_accountText__1q7Tp:hover {
      color: #fff !important;
      background-color: #000;
      border: 2px solid #000;
    }
.account_accountText__1q7Tp svg {
      width: 32px;
      height: 32px;
      fill: none;
    }
.account_accountActive__B77iT:visited {
      color: #000;
    }

.account_balance__2wkKB {
  font-size: 14px;
  font-weight: 600;
}
.account_address__3hwfs {
  position: absolute;
  right: -12px;
  top: 12px;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 1070px) {
  .account_pcETH__2vZYK {
    display: none;
  }
}
@media (max-width: 850px) {
  .account_pc__1B9K2 {
    display: none;
  }
  .account_address__3hwfs {
    position: relative;
    right: 0;
    top: 0;
  }
  .account_accountText__1q7Tp {
    font-size: 12px;
    margin-top: 28px;
    width: 166px;
  }
  .account_account__2DH1h {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0px;
    flex-direction: column;
  }
    .account_accountInfo__3j_Hy {
      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      margin-right: 0px;
      margin-top: 20px;
    }
    .account_accountLink__31dct {
      margin-bottom: 50px;
    }
    .account_accountView__29PpT {
      display: flex;
    }
  .account_mobile__1RDy8 {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
    .account_mobileMenu__iWEZB {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 56px;
      border-bottom: 0.5px solid #909090;
      margin-bottom: 36px;
    }
      .account_mobileMenuItem__3W2fG:not(:last-child) {
        margin-bottom: 48px;
      }
      .account_mobileMenuItem__3W2fG {
        position: relative;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #000000;
      }
          .account_mobileMenuItemActive__19Wt9::before {
            width: 12px;
            height: 12px;
            background: #ff6c2d;
            border-radius: 15px;
            content: '';
            position: absolute;
            left: -17px;
            top: 10px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          .account_mobileMenuItemActive__19Wt9::after {
            width: 100%;
            background: none;
          }
        .account_mobileMenuItemTip__3Eoam {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -6px;
          right: -28px;
          width: 35px;
          height: 14px;
          color: #ffffff;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          background: #83d300;
          border-radius: 10px;
          padding: 2px 4px;
        }
          .account_mobileMenuItemTip__3Eoam span {
            display: inline-block;
            -webkit-transform: scale(0.75);
                    transform: scale(0.75);
          }
}
@media (max-width: 960px) {
    .account_pcETH__2vZYK {
      display: none;
    }
}
@media (max-width: 320px) {
  .account_pc__1B9K2 {
    display: none;
  }
  .account_accountText__1q7Tp {
    font-size: 12px;
    margin-top: 28px;
  }
  .account_mobile__1RDy8 {
    display: flex;
    flex-direction: column;
  }
}

.account_account__2EV2D {
  position: relative;
  /*height: 100%;*/
  padding: 0 24px;
}

.account_event__3g5U8 {
  font-size: 12px;
  font-weight: 600;
}

.account_inner__1hRHc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.account_account__2EV2D .account_total__3nsBC .account_caption__3ir6B {
  margin-left: 0px;
  margin-right: 0px;
}

.account_caption__3ir6B {
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: 'Archivo';
  font-size: 24px;
  /*margin: 8px 0px;*/
  font-size: 18px;
  border-bottom: 0.5px solid #909090;
  font-weight: normal;
}

.account_captionInventory__288jg {
    margin-top: 24px;
    margin-bottom: 12px;
    font-family: 'Archivo';
    font-size: 24px;
    /*margin: 8px 0px;*/
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }

.account_balance__dR6ps {
  /*position: relative;*/
  /*margin: 16px 0;*/
  /*padding: 16px 32px;*/
  /*max-width: 472px;*/
}

/*background-color: var(--color-background-tertiary);*/

.account_balanceIcon__-pTZa {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 72px;
    height: 72px;
    fill: none;
  }

/*border-radius: 4px;*/

.account_balanceETH__dUTRp {
    font-size: 32px;
  }

/*width: 100%;*/

.account_balanceUSD__1yG0S {
    align-items: center;
    font-size: 12px;
    color: #ff6c2d;
    border: 1px solid #ff6c2d;
    border-radius: 12px;
    height: 13px;
    padding: 2px 5px;
  }

.account_address__iPPga {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
  margin-top: 20px;
}

.account_address__iPPga a {
    text-decoration: underline;
  }

.account_address__iPPga a:hover,
  .account_address__iPPga a:visited {
    color: #000000 !important;
  }

.account_address__iPPga svg {
    width: 24px;
    height: 24px;
    fill: none;
  }

.account_copy__3VM-S {
  margin-left: 8px;
  flex: none;
}

.account_total__3nsBC {
  min-width: 180px;
  max-width: 291px;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
}

.account_inventory__2E3eN {
  max-width: 940px;
}

.account_inventoryList__3rFZS {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    grid-gap: 24px;
    gap: 24px;
  }

.account_pagination__1aIDF {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.account_cursorInherit__1ZcOE {
  cursor: inherit;
}

.account_right__2Hoho {
  width: 100%;
  margin-left: 102px;
}

.account_rightView__1x9ZH {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.account_activity__2c0xn {
  width: 100%;
  min-width: 448px;
}

.account_activityTableRowSplit__1lc50 {
  width: 97.5%;
}

.account_openOutside__1VP_O svg {
  fill: none;
}

.account_noResult__1Rf6n {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: rgba(238, 238, 238, 0.5);
}

.account_noResultInfo__3H_-e {
    font-size: 14px;
    line-height: 16px;
    color: #909090;
  }

.account_noResult__1Rf6n div {
    text-align: center;
  }

.account_noResult__1Rf6n svg {
    width: 60px;
    height: 60px;
  }

.account_batchTransfer__2csHF {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.account_batchTransferTitle__3V2of {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    padding-top: 48px;
    padding-bottom: 8px;
    border-bottom: 0.5px solid #909090;
  }

.account_batchTransferInfo__3wN0E {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #909090;
    margin-bottom: 24px;
  }

.account_batchTransfer__2csHF svg {
    width: 24px;
    height: 24px;
    fill: none;
    margin-top: 25px;
    margin-bottom: 13px;
  }

.account_cardListModalNormal__815np {
    width: 975px;
    height: 655px;
  }

.account_cardListModalMiddle__3UOES {
    width: 504px;
    height: 655px;
  }

.account_cardListModalBottom__1xiak {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.account_cardListModalBtnBack__17Dx8 {
      margin-right: 20px;
    }

.account_cardListModalBtnBackSmall__2FzIs {
        width: 186px !important;
      }

.account_cardListModalTransferBtn__2Ht64 {
      height: 40px;
      line-height: 40px;
      width: 212px;
      padding: 0px;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      border: 2px solid #ff6c2d;
      color: #fff !important;
      background-color: #ff6c2d !important;
      margin-top: 10px;
    }

.account_cardListModalTransferBtnDisable__2tD95 {
        height: 40px;
        line-height: 40px;
        width: 212px;
        padding: 0px;
        text-align: center;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 10px;
        color: #909090 !important;
        background: #ffffff !important;
        border: 2px solid #909090;
      }

.account_cardListModalTransferError__2aAcD {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #ff0000;
      min-height: 18px;
      margin-top: 5px;
    }

.account_cardListModalContent__vXZA1 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

.account_cardListModalContentConfirm__3mghC {
      margin: 20px 0px;
    }

.account_cardListModalContentConfirm__3mghC span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #909090;
        margin-bottom: 8px;
      }

.account_cardListModalContentConfirm__3mghC div {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #000000;
      }

.account_cardListModalContentConfirmLarge__1UAqt {
        display: flex;
        margin: 10px 0px;
      }

.account_cardListModalContentConfirmLarge__1UAqt span {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #909090;
          margin-right: 12px;
        }

.account_cardListModalContentConfirmLarge__1UAqt div {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #000000;
        }

.account_cardListModalContentPagination__LGTxg {
      margin-top: 36px;
    }

.account_cardListModalContentPaginationLarge__3eQB9 {
        margin-top: 4px;
        padding-top: 0px;
        padding-bottom: 16px;
      }

.account_cardListModalContentInputView__1VCSK {
      position: relative;
    }

.account_cardListModalContentInputViewLarge__Jl69B svg {
          top: 30px !important;
          right: 23px !important;
        }

.account_cardListModalContentInputView__1VCSK svg {
        width: 14px !important;
        height: 14px !important;
        fill: none !important;
        position: absolute;
        right: 0px;
        top: 6px;
      }

.account_cardListModalContentInput__3cZpv {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #909090;
      width: 100%;
      height: 30px;
      border-radius: 0px;
      padding-right: 15px !important;
      padding-left: 4px !important;
      border-bottom: 1px solid #000000;
    }

.account_cardListModalContentInputLarge__23YjO {
        width: 448px;
        margin-right: 24px;
      }

.account_cardListModalContentInput__3cZpv:hover,
      .account_cardListModalContentInput__3cZpv:active,
      .account_cardListModalContentInput__3cZpv:focus {
        border: 0px;
        border-bottom: 1px solid #000000;
      }

.account_cardListModalContentBtn__2jHdg {
      text-align: center;
      border-top: 1px solid #eeeeee;
    }

.account_cardListModalContentBtnTitle__33CoC {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;
      }

.account_cardListModalContentBtnTitle__33CoC svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }

.account_steps__3wlnT {
  display: block;
}

.account_step__1n-dd {
  margin-top: 24px;
}

.account_step__1n-dd svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

.account_stepTitle__1hM24 {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.account_stepTitleText__V0yQ0 {
      margin-left: 5px;
      font-weight: 600;
    }

.account_stepContent__2_3T_ {
    padding: 5px 0px;
    color: #909090;
  }

.account_stepSuccess__3kc3w {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.account_modalNotice__3r-M7 {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #c7c7c7;
}

.account_modalNoticeTitle__30I1y {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #909090;
    margin-bottom: 8px;
  }

.account_modalNoticeMsg__1GMIC {
    font-size: 12px;
    line-height: 13px;
    color: #909090;
  }

@media (max-width: 850px) {
  .account_right__2Hoho {
    margin-left: 0px;
  }

  .account_account__2EV2D {
    padding: 0px;
  }

    .account_accountInfo__35k6Y {
      display: flex;
      font-size: 12px;
      margin-right: 33px;
      position: relative;
      flex-direction: column;
      align-items: center;
    }
    .account_captionInventory__288jg {
      margin-left: 0px;
      margin-right: 0px;
    }

  .account_address__iPPga {
    position: relative;
  }

  .account_inner__1hRHc {
    flex-direction: column;
    padding: 0 16px;
  }

  .account_total__3nsBC {
    max-width: none;
  }

  .account_inventory__2E3eN {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0px;
  }

    .account_inventoryList__3rFZS {
      grid-gap: 16px;
      grid-gap: 16px;
      gap: 16px;
      justify-content: center;
    }

  .account_activity__2c0xn {
    margin-bottom: 50px;
    max-width: none;
    min-width: 100%;
    margin-bottom: 50px;
    padding: 0px;
  }

  .account_transfer__3UBFB {
    flex-direction: column;
    padding: 16px 16px;
  }
      .account_transferInfoBlock__UGT6A {
        margin-left: 0px;
        margin-bottom: 20px;
      }

      .account_transferInfoIcon__14HHa {
        display: none;
      }

    .account_transferInner__3pScI {
      flex-direction: column;
      align-items: flex-start;
    }

  .account_activityTableRowSplit__1lc50 {
    width: 95.5%;
  }
}

@media (max-width: 720px) {
    .account_cardListModalNormal__815np {
      width: 95% !important;
      height: 665px;
    }

    .account_cardListModalMiddle__3UOES {
      width: 95% !important;
      height: 665px;
    }

    .account_cardListModalBottom__1xiak {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .account_cardListModalContent__vXZA1 {
      margin: 16px 0px;
    }

      .account_cardListModalContentConfirm__3mghC {
        flex-direction: column;
      }

        .account_cardListModalContentConfirm__3mghC span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }

        .account_cardListModalContentConfirm__3mghC div {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
        }

      .account_cardListModalContentInputView__1VCSK {
        width: 100%;
      }

        .account_cardListModalContentInputView__1VCSK svg {
          right: 0px !important;
        }

      .account_cardListModalContentInput__3cZpv {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
      }
        .account_cardListModalContentInput__3cZpv:focus,
        .account_cardListModalContentInput__3cZpv:active {
          -ms-user-select: text;
              user-select: text;
          -webkit-user-select: text;
          zoom: 1;
        }
        .account_cardListModalContentInputLarge__23YjO {
          width: 100%;
        }
}

@media screen and (max-width: 425px) {
    .account_batchTransfer__2csHF button {
      width: 100%;
    }
}

.asset_asset__TqfzX {
  /*height: 100%;*/
  margin: 16px 24px;
}
.asset_event__gqCwf {
  font-size: 12px;
  font-weight: 600;
}
.asset_inner__3TT9o {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.asset_attributes__1OghE {
  margin: 12px 0 48px;
}

.asset_attributes__1OghE .asset_item__299KS {
    margin-bottom: 12px;
  }

.asset_nav__4BOEh {
  display: flex;
}

.asset_back__3Oe4K {
  display: flex;
  align-items: center;
}

.asset_back__3Oe4K:hover,
  .asset_back__3Oe4K:focus {
    color: var(--color-highlight);
  }

.asset_backText__L-eTx {
    margin-left: 8px;
  }

.asset_nextBtn__3P_rw {
  padding-left: 10px;
  justify-content: left;
  background-image: url(/static/media/arrow-right.32f5d866.svg);
  background-position: 90% 50%;
}

.asset_nextBtn__3P_rw:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }

.asset_nextBtn__3P_rw:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    border: solid 2px #e5e5e5;
  }
.asset_prevBtn__kGt1W {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.asset_prevBtn__kGt1W:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.asset_prevBtn__kGt1W:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.asset_button__WznMy {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}

.asset_content__1xZ5W {
  display: flex;
  margin-top: 30px;
}

.asset_card__wJU_K {
  /*margin-top: 144px;*/
}
.asset_rightView__1wogq {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
  }
.asset_trading__2ki1i {
}
.asset_info__v5GSZ {
  max-width: 468px;
  min-width: 468px;
  padding: 0 10px;
  flex: 1 1;
  margin: 0 auto;
}
.asset_infoOwner__2Bx1l {
    display: flex;
    align-items: center;
  }
.asset_infoOwnerText__A6ImU {
      font-size: 18px;
    }
.asset_infoOwner__2Bx1l svg {
      fill: none;
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
.asset_infoOwnerAddress__eHJ4p {
      /*width:50%;*/
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;
    }
.asset_infoOwnerAddress__eHJ4p:hover {
        color: #ff6c2d;
      }
.asset_infoHeaderMobile__1_26R {
    display: none;
  }
.asset_infoHeader__sbugC {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.asset_infoDesc__29PcG {
    font-size: 18px;
    font-weight: 600;
  }
.asset_infoId__1ezcy {
    color: var(--color-highlight);
  }
.asset_infoCaption__2z31J {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
.asset_infoCard__3mMrw {
    margin-bottom: 48px;
  }
.asset_infoCardTop__3eUts {
      display: flex;
    }
.asset_infoCardTopRow__1jodn {
        margin-right: 38px;
      }
.asset_infoCardStates__3D9yD {
    margin-bottom: 48px;
    display: flex;
  }
.asset_infoCardStatesText__2uHAQ {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      width: 33%;
    }
.asset_infoCardStatesTextValue__Yfl39 {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
.asset_infoCardBodyParts__3t06- {
    margin-bottom: 22px;
    display: flex;
  }
.asset_infoCardBodyPartsText__2uHiZ {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 50%;
    }
.asset_infoCardBodyPartsTextValue__2dAda {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
.asset_infoCardBodyPartsTextValue__2dAda span {
        margin-left: 5px;
      }
.asset_infoSubject__EgUuN {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.asset_infoText__3V9F8 {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
.asset_infoCardStatesText__2uHAQ svg,
.asset_infoCardBodyPartsText__2uHiZ svg {
  fill: none;
  width: 28px;
  height: 28px;
}

.asset_stats__1AIbW .asset_infoCardTopRow__1jodn {
    width: 33.33%;
    margin-right: 0;
  }

.asset_otherStats__2cG-T {
  display: flex;
  margin-top: 20px;
}

.asset_otherStats__2cG-T > div {
    width: 50%;
  }

.asset_otherStats__2cG-T > div:first-child {
      padding-right: 10px;
    }

.asset_otherStats__2cG-T > div:last-child {
      padding-left: 10px;
    }

.asset_infoText__3V9F8.asset_none__3YMsa {
    color: #909090;
  }

.asset_infoText__3V9F8 .asset_icon__UgvAb {
    margin-right: 11px;
    position: relative;
  }

.asset_infoText__3V9F8 .asset_speed__2Q1Kc {
    font-family: 'Tomorrow';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
    background: #A0DEEC;
    border-radius: 18px;
    padding: 2px 3px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0.7);
            transform: translateX(-50%) scale(0.7);
    white-space: nowrap;
  }

.asset_infoText__3V9F8 svg {
    fill: none;
    width: 28px;
    height: 28px;
  }

.asset_infoOwnerAddress__eHJ4p svg {
  fill: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.asset_buy__1gjJl {
  font-size: 14px;
}
.asset_buy__1gjJl:hover {
  }
.asset_buy__1gjJl:focus {
  }
.asset_cancelListingLarge__32p4Q {
    width: 212px !important;
  }
.asset_cancelListing__SDPrb {
    height: 40px;
    width: 96px;
    padding: 0px;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #000;
    color: #000 !important;
    background-color: #fff !important;
  }
.asset_cancelListing__SDPrb:hover {
      border: 2px solid #000;
      color: #fff !important;
      background-color: #000 !important;
    }
.asset_checkout__3zYS0 {
  width: 205px;
}
.asset_trade__3aJEp {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
}
.asset_trade__3aJEp:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
.asset_trade__3aJEp:focus {
  }
.asset_tradeView__3EQ_C {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }

.asset_activity__Y45cR {
  display: block;
}
.asset_history__3zHFO {
  width: 448px;
}
.asset_buyCard__2hlj8 img {
  margin-bottom: 10px;
}
.asset_buy__1gjJl {
  display: block;
}
.asset_buyContent__2gzA3 {
    margin: 24px 0px;
    border-radius: 4px;
    border-top: none;
  }
.asset_buyItem__o1oT0 {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
  }
.asset_buyItem__o1oT0:first-child {
    border-bottom: 1px solid #000000;
  }
.asset_buyName__3LQUH {
    display: flex;
    flex: 1 1;
    align-items: center;
    margin-bottom: 8px;
  }
.asset_buyNameTotal__1MTW_ {
      font-weight: 600;
      font-size: 14px;
      color: #909090;
    }
.asset_buyCard__2hlj8 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: #eeeeee;
  }
.asset_buyCardInfo__1th8R {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;
      align-items: center;
    }
.asset_buyCardInfoLeft__2j-oK {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
.asset_buyRace__1aio_ {
    margin: 0 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
  }
.asset_buyRace__1aio_ svg {
      width: 14px;
      height: 14px;
    }
.asset_buyId__2w4Ms {
    align-items: center;
    display: flex;
    margin: 0 8px;
  }
.asset_buyPrice__1Gydc {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
.asset_buyPriceFlex__20svC {
      display: flex;
      align-items: center;
    }
.asset_buyPriceETH__2UKeg {
      text-align: right;
      font-size: 14px;
      color: #000000;
    }
.asset_buyPriceETHHighlight__TIQLF {
        text-align: right;
        font-size: 18px;
        font-weight: 600;
        color: #ff6c2d;
      }
.asset_buyPriceUSD__2Fuku {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
    }
.asset_buyPriceUSDNormal__36D59 {
        font-size: 14px;
        color: #ff6c2d;
        border: 0px;
      }
.asset_buyFooter__2bjpl {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
.asset_buyButton__2x6Zo {
    margin: 0 16px;
    font-size: 14px;
  }

.asset_connectWalet__3Ook5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.asset_connectWaletText__25KqO {
    margin: 24px 0;
    text-align: center;
  }

.asset_cancel__12FzA {
  display: block;
}

.asset_cancelContent__2buNl {
    margin: 24px 0;
    border-radius: 4px;
  }

.asset_cancelFooter__3a7MV {
    display: flex;
    justify-content: space-between;
  }

.asset_cancelButton__2HkNx {
    font-size: 14px;
    margin: 0 16px;
    padding: 8px 16px;
  }

.asset_openOutside__1dKaL {
  display: flex;
}
.asset_openOutside__1dKaL svg {
  fill: none;
}
.asset_externalLink__3Aw7z:hover {
    color: #ff6c2d;
  }
/*@media (max-width: 915px) {*/
/*  .info {*/
/*    padding: 0 1%;*/
/*  }*/
/*  .tableHeaderCell,*/
/*  .tableBodyCell {*/
/*    padding: 0px 0px !important;*/
/*  }*/
/*}*/

/*@media (max-width: 1400px) {*/
/*  .info {*/
/*    min-width: 100%;*/
/*    &Header {*/
/*      flex-direction: column;*/
/*    }*/

/*    &Desc {*/
/*      width: 100%;*/
/*    }*/
/*  }*/
/*}*/

@media (max-width: 1254px) {
  .asset_trading__2ki1i {
    margin: 0 auto;
  }
}
@media (max-width: 850px) {
  .asset_content__1xZ5W {
    flex-direction: column;
  }
  .asset_history__3zHFO {
    width: 100%;
  }
    .asset_infoHeader__sbugC {
      display: none;
    }
    .asset_infoHeaderMobile__1_26R {
      display: flex;
    }
      .asset_infoCardTop__3eUts {
        flex-wrap: wrap;
      }
        .asset_infoCardTopRow__1jodn {
          width: 50%;
        }
  .asset_card__wJU_K {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .asset_info__v5GSZ {
    padding: 0;
    margin: 24px 0 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  .asset_asset__TqfzX {
    margin: 16px 16px;
  }
  .asset_trading__2ki1i {
    width: 100%;
    overflow: scroll;
  }
    .asset_infoOwner__2Bx1l {
      align-items: flex-start;
    }
      .asset_infoOwnerAddress__eHJ4p {
        word-break: break-all;
        font-size: 12px;
      }
}

.asset_clear__2rinM {
  margin-bottom: 0px;
}

.attribute_attribute__1MI7s {
  font-family: 'Tomorrow';
}

  .attribute_attribute__1MI7s .attribute_label__3N7Zr {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .attribute_attribute__1MI7s .attribute_label__3N7Zr .attribute_icon__vANzg {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 4px;
      background-image: url(/static/media/attribute.111b2d62.svg);
      background-position: center;
    }

  .attribute_attribute__1MI7s .attribute_label__3N7Zr .attribute_text__16T-X {
      flex: 1 1;
    }

  .attribute_attribute__1MI7s .attribute_bar__3AzOb {
    background: #f8f8f8;
    border-radius: 6px;
    height: 8px;
    margin-top: 8px;
    position: relative;
    overflow: hidden;
  }

  .attribute_attribute__1MI7s .attribute_bar__3AzOb div {
      position: absolute;
      height: 8px;
    }

.mint_mint__Px50A {
  background: #c5c5f4;
  border-radius: 8px;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Plus Jakarta Sans';
}

  .mint_mint__Px50A .mint_label__3MK8k {
    display: flex;
    align-items: flex-end;
  }

  .mint_mint__Px50A .mint_label__3MK8k .mint_text__2_vNI {
      flex: 1 1;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }

  .mint_mint__Px50A .mint_label__3MK8k .mint_value__JUYqN {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }

  .mint_mint__Px50A .mint_bar__uiaLe {
    display: flex;
    margin-top: 17px;
  }

  .mint_mint__Px50A .mint_bar__uiaLe span {
      background: #ffffff;
      border-radius: 6px;
      height: 12px;
      flex: 1 1;
      margin-right: 2px;
    }

  .mint_mint__Px50A .mint_bar__uiaLe span:last-child {
        margin-right: 0;
      }

  .mint_mint__Px50A .mint_bar__uiaLe .mint_active__1YqiK {
      background: #8c8ce4;
    }

.level_level__2l0Zl {
  background: #a0deec;
  border-radius: 8px;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Tomorrow';
}

  .level_level__2l0Zl .level_label__qmpU9 {
    background: #215a67;
    border-radius: 22px;
    width: 64px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    overflow: hidden;
  }

  .level_level__2l0Zl .level_label__qmpU9 span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-left: 4px;
      position: relative;
    }

  .level_level__2l0Zl .level_label__qmpU9 span:before,
      .level_level__2l0Zl .level_label__qmpU9 span:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -4px;
        height: 100px;
        width: 8px;
        box-sizing: border-box;
        border-left: 3px solid;
        border-right: 3px solid;
        border-color: #3892a7;
      }

  .level_level__2l0Zl .level_label__qmpU9 span:before {
        top: 23px;
      }

  .level_level__2l0Zl .level_label__qmpU9 span:after {
        bottom: 21px;
      }

  .level_level__2l0Zl .level_bar__1AW9o {
    margin-top: 8px;
    background: #fff;
    border-radius: 6px;
    height: 12px;
    overflow: hidden;
  }

  .level_level__2l0Zl .level_bar__1AW9o div {
      background: #3892a7;
      height: 100%;
    }

.activity_activity__1xzGx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
}

  .activity_activityListHeaderPrice__1inAQ {
        text-align: right;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        padding-right: 45%;
      }

  .activity_activityListItem__2SrG- {
      text-decoration: none !important;
      display: flex;
      align-items: center;
    }

  .activity_activityListItem__2SrG-:hover {
        color: #000000;
      }

  .activity_activityListItem__2SrG- div {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        background: #f6f8fd;
        border-radius: 4px;
      }

  .activity_activityListItem__2SrG- div img {
          width: 38px;
        }

  .activity_activityListPrice__2ps-J {
      text-align: right;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding-right: 45%;
    }

  .activity_activityListPriceETH__2Ocuf {
        font-size: 14px;
        line-height: 14px;
        color: #ff6c2d;
        margin-bottom: 4px;
      }

  .activity_activityListPriceUSD__3SMLz {
        font-size: 12px;
        line-height: 12px;
        color: #909090;
      }

  .activity_activityListDate__24RAm {
      color: #909090;
    }

.activity_select__2u1xO select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.activity_event__3bv5U {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.activity_header___k5i5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
}

.activity_headerInner__F1Z8z {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    max-width: 1078px;
  }

.activity_headerTitle__pOxUP {
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
  }

.activity_tableList__1CTHJ {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity_tableListWrapper__3geY7 {
    min-width: 1078px;
  }

.activity_pagination__jgEhy {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}

.activity_paginationInner__273Tj {
    width: 100%;
    max-width: 1078px;
  }

@media (max-width: 1128px) {
  .activity_tableList__1CTHJ {
    justify-content: flex-start;
  }
}

@media (max-width: 850px) {
  .activity_activity__1xzGx {
    padding: 0 16px;
  }
    .activity_headerInner__F1Z8z {
      flex-direction: column;
    }

  .activity_select__2u1xO select {
    margin-top: 10px;
    width: 100%;
  }
}

.sell_sell__2tQmT {
  margin: 16px 24px;
}

.sell_inner__2myQb {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.sell_nav__811GR {
  display: flex;
}

.sell_back__1Pq7y {
  display: flex;
  align-items: center;
}

.sell_backText__2Zu9f {
    margin-left: 8px;
  }

.sell_content__2kBDD {
  display: flex;
  margin-top: 30px;
}

.sell_card__1_7O2 {
  /*width:212px;*/
  /*height:256px;*/
}
.sell_cardView__1mjij {
  width: 212px;
  height: 256px;
}
.sell_info__2aoic {
  padding: 0 32px;
  max-width: 768px;
  margin: 0 auto;
}
.sell_infoHeaderMobile__HOkpZ {
    display: none;
  }
.sell_infoHeader__GguWn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.sell_infoDesc__1t2yY {
    font-size: 18px;
    font-weight: 600;
  }
.sell_infoId__FrmPZ {
    color: var(--color-highlight);
  }
.sell_infoCaption__2sPrc {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
.sell_infoCard__1ApJa {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.sell_infoSubject__wGiCw {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.sell_infoText__16b7E {
    font-size: 14px;
    color: #000;
  }
.sell_infoPrice__1l2_o {
    position: relative;
  }
.sell_infoPrice__1l2_o svg {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 17px;
    }

.sell_trade__3BvZe {
  font-size: 18px;
}

.sell_tradeView__H2len {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }

.sell_tradeIcon__1DaKs {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

.sell_button__3dGfV {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.sell_prevBtn__32hVB {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.sell_prevBtn__32hVB:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.sell_prevBtn__32hVB:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.sell_price__2xqHa {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
}
.sell_priceListedInfo__2SvIS {
    font-size: 14px;
    margin-top: 12px;
    color: #ff6c2d;
    line-height: 15px;
  }
.sell_priceBlock__2YeQm {
    flex: 1 1;
  }
.sell_priceInput__1bojY {
    margin-top: 16px;
    padding: 4px 40px;
  }
.sell_priceInput__1bojY input::-webkit-outer-spin-button,
    .sell_priceInput__1bojY input::-webkit-inner-spin-button {
      -webkit-appearance: revert !important;
              appearance: revert !important;
    }
.sell_priceCard__1ckL9 {
    padding: 16px;
    border: 2px solid var(--color-background-secondary);
    background: linear-gradient(
      329.58deg,
      rgba(87, 65, 52, 0.4) 0%,
      rgba(87, 65, 52, 0) 100%
    );
  }
.sell_priceFees__2U6Bk {
    display: flex;
    margin-top: 16px;
    color: #000;
  }
.sell_priceFeesName__2GL3l {
      width: 200px;
      font-weight: 600;
      font-size: 14px;
    }
.sell_priceFeesValue__29zGl {
      font-size: 14px;
      color: #000;
    }

.sell_steps__2O0JH {
  display: block;
}

.sell_step__3X5IC {
  margin-top: 24px;
}

.sell_step__3X5IC svg {
    fill: none;
    width: 24px;
    height: 24px;
  }

.sell_stepTitle__1sLlp {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.sell_stepTitleText__JmRND {
      margin-left: 5px;
      font-weight: 600;
      line-height: 15px;
    }

.sell_stepContent__1umhX {
    padding: 5px;
    color: #909090;
  }

.sell_stepSuccess__2MFIQ {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.sell_done__3zodn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
}
.sell_card__1_7O2 .sell_card__1_7O2 {
  background-color: red;
}
.sell_sell__2tQmT input {
  width: 100%;
  position: relative;
  border-radius: 0px;
  border-bottom: 1px solid #000;
  color: #909090;
}
.sell_sell__2tQmT input:hover,
  .sell_sell__2tQmT input:focus {
    border-radius: 0px;
    border: 0px;
    border-bottom: 2px solid #ff6c2d;
  }
.sell_sell__2tQmT input:focus {
    border-bottom: 2px solid #ff6c2d;
  }
.sell_sell__2tQmT input::-webkit-outer-spin-button,
.sell_sell__2tQmT input::-webkit-inner-spin-button {
  -webkit-appearance: revert !important;
          appearance: revert !important;
}
.sell_priceContainer__31lof {
  display: flex;
  font-size: 18px;
}
.sell_cardPriceUSD__2kRYj {
  margin-left: 10px;
  color: #ff6c2d;
}
.sell_trading__18hq- {
  max-width: 404px;
  margin-top: 27px;
}

@media screen and (max-width: 768px) {
  .sell_sell__2tQmT input::-webkit-outer-spin-button,
  .sell_sell__2tQmT input::-webkit-inner-spin-button {
    -webkit-appearance: revert !important;
            appearance: revert !important;
  }
  .sell_trading__18hq- {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .sell_content__2kBDD {
    flex-direction: column;
  }
  .sell_info__2aoic {
    width: 100%;
    padding: 0px;
  }
    .sell_infoHeader__GguWn {
      display: none;
    }
    .sell_infoHeaderMobile__HOkpZ {
      display: flex;
    }
  .sell_sell__2tQmT {
    margin: 16px 16px;
  }
  .sell_inner__2myQb {
    padding: 0px;
  }
  .sell_card__1_7O2 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.login_login__Hqxje {
  height: 100%;
}

.login_inner__2fk4m {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.login_title__27rqf {
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
}

.login_metamask__1imZG {
  width: 96px;
  height: 96px;
}

.login_metamaskWrapper__2PdQh {
   margin: 68px 0;
   display: flex;
   align-items: center;
   justify-content: center;
 }

.login_connect__1D-a6 {
  width: 321px;
  height: 286px;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  margin: 0 auto;
}

.marketplace_marketplace__2NQmc {
  /*height: 100%;*/
}
.marketplace_maintenance__1d3ht {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  text-align: center;
  padding: 0 16px;
}

.marketplace_split__2MtsY {
  border-bottom: 0.5px solid #000;
}

.marketplace_splitHeader__1aZJO {
    margin: 0px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #000;
  }
.marketplace_inner__16JMp {
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.marketplace_banner__1AlFa {
  width: 100%;
  margin: 25px 0px;
}
.marketplace_bannerPic__gOVC0 {
    position: relative;
    width: 100%;
    min-height: 320px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-position: 0 34%;
    overflow: hidden;
    background-size: cover;
  }
.marketplace_bannerPic__gOVC0 div {
      position: absolute;
      left: 5%;
      bottom: 10%;
      font-size: 48px;
      line-height: 52px;
      display: flex;
      align-items: center;
      color: #ffffff;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    }
.marketplace_bannerInfo__3iJBv {
    min-height: 100px;
    display: flex;
    padding: 0px 45px;
    background: #eeeeee;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    align-items: center;
    justify-content: space-around;
  }
.marketplace_bannerInfoItem__3PRM- {
      display: flex;
      padding: 30px 0px;
    }
.marketplace_bannerInfoItem__3PRM- svg {
        width: 38px;
        height: 26px;
        fill: none;
      }
.marketplace_bannerInfoItemTitle__3_pOw {
        display: flex;
        align-items: flex-start;
        margin-left: 12px;
        margin-right: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #909090;
      }
.marketplace_bannerInfoItemTitle__3_pOw svg {
          width: 16px;
          height: 16px;
        }
.marketplace_bannerInfoItemValue__1DNzW {
        font-size: 36px;
        line-height: 32px;
        color: #000000;
        border-bottom: 1px solid #000000;
      }
.marketplace_filter__2-6lG {
  width: 100%;
  border-radius: 4px;
}
.marketplace_filterHeader___hScZ {
    display: flex;
    margin-top: 7px;
    align-items: center;
    justify-content: flex-start;
  }
.marketplace_filterHeaderLeft__3YFKr {
      display: flex;
      width: 100%;
    }
.marketplace_filterTitle__21nRf {
    color: #000;
    font-size: 18px;
    margin-right: 58px;
  }
.marketplace_filterReset__1yzOj {
    color: #909090;
    font-size: 14px;
    text-decoration: underline;
  }
.marketplace_filterResetEnable__1jraw {
      color: #000000;
      text-decoration: underline;
    }
.marketplace_filterBlock__2lLCw {
    margin-right: 90px;
  }
.marketplace_filterCaption__2-SCz {
    margin-top: 12px;
    color: #000;
    font-weight: 600;
  }
.marketplace_filterOptions__mN73b {
    padding: 4px 0px;
    background-color: #fff;
  }
.marketplace_filterCheckbox__2sorB {
    margin-bottom: 18px;
    margin-right: 16px;
  }
.marketplace_filterProperties__3S3ye {
     color: #000000;
     background: #EEEEEE;
     border-radius: 18px;
     padding: 2px 7px;
     font-weight: 400;
     font-size: 10px;
     margin-right: -5px;
     display: inline-block;
     -webkit-transform: scale(.83);
     transform: scale(.83);
  }
.marketplace_filterRadio__vxKOB {
    margin: 8px 0;
  }
.marketplace_filterRow__3VXDu {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
  }

.marketplace_list__1gPdb {
  width: 100%;
  max-width: 1432px;
  flex: 1 1;
  margin: 0 auto;
}

.marketplace_listHeader__slK4V {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

.marketplace_sum__Rf01N {
  font-family: Archivo;
  font-size: 20px;
}

.marketplace_sort__2yOZR {
  display: flex;
}

.marketplace_select__2xXYL:first-child {
  margin: 0 16px;
}
.marketplace_select__2xXYL select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.marketplace_cards__3UqHF {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.marketplace_pagination__1V4cs {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.marketplace_marketplace__2NQmc .marketplace_menu__1woaB {
  display: none;
}

@media (min-width: 1600px) {
  .marketplace_list__1gPdb {
    width: 100%;
    max-width: 100%;
    flex: 1 1;
    margin: 0 auto;
  }
}
@media (max-width: 1100px) {
    .marketplace_bannerPic__gOVC0 {
      width: auto;
      min-height: 190px;
    }
      .marketplace_bannerPic__gOVC0 div {
        font-size: 24px;
        line-height: 25px;
      }
    .marketplace_bannerInfo__3iJBv {
      flex-wrap: wrap;
      justify-content: space-between;
    }
      .marketplace_bannerInfoItem__3PRM- {
        min-width: 259px;
      }
}
@media (max-width: 850px) {
  .marketplace_banner__1AlFa {
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .marketplace_open__1UMRV {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }
  .marketplace_menu__1woaB {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    z-index: 1100;
    align-items: center;
    justify-content: flex-end;
  }
  .marketplace_menu__1woaB svg {
    width: 28px;
    height: 28px;
  }
    .marketplace_filterRow__3VXDu {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    .marketplace_filterBlock__2lLCw {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .marketplace_filterBlock__2lLCw:first-child {
      margin-bottom: 0px;
    }
  .marketplace_sort__2yOZR {
    justify-content: space-between;
  }
  .marketplace_inner__16JMp {
    margin: 16px;
  }
  .marketplace_split__2MtsY {
    margin-top: 15px;
  }
    .marketplace_splitHeader__1aZJO {
      margin: 0px;
      margin-bottom: 10px;
    }
    .marketplace_listHeader__slK4V {
      flex-direction: column;
    }

  .marketplace_select__2xXYL select {
    width: 100%;
  }
  .marketplace_select__2xXYL:first-child {
    margin: 0px;
  }
  .marketplace_select__2xXYL {
    margin: 0px;
    width: 47%;
  }

  .marketplace_cards__3UqHF {
    display: flex;
    justify-content: center;
    margin: 16px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
  }
}

@media (max-width: 650px) {
    .marketplace_bannerInfo__3iJBv {
      padding: 0px 15px;
      justify-content: flex-start;
    }
}
@media (max-width: 414px) {
    .marketplace_bannerPic__gOVC0 {
      min-height: 390px;
    }
      .marketplace_bannerPic__gOVC0 div {
        font-weight: 400;
        font-size: 36px;
        line-height: 39px;
      }
}
@media (max-width: 390px) {
    .marketplace_bannerPic__gOVC0 {
      min-height: 390px;
    }
}

.not-found_notFound__chXXH {
  height: 100%;
}

.not-found_inner__2wcSj {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.not-found_title__krlRK {
  font-size: 64px;
  text-align: center;
}

.download_download__2zbkW {
  height: 100%;
  font-family: 'Chivo';
}

.download_inner__1734n {
  max-width: 1200px;
  margin: 0 auto;
}

.download_container__6nBLu {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}

.download_picture__2fSFL {
  display: flex;
  flex: 1 1;
}

.download_picture__2fSFL .download_image__2UpgA {
    max-width: 100%;
  }

.download_intro__CejOX {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.download_title__1bzC0 {
  line-height: 1.2;
  font-weight: 800;
  font-size: var(--font-size-3xl);
}

.download_caption__1VysA {
  margin: 8px 0;
  font-size: var(--font-size-xl);
  color: #8a8a8a;
}

.download_link__3DnpB {
  margin: 16px 0;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.download_linkButton__12goS {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    width: 192px;
    height: 64px;
    border-radius: 64px;
    box-shadow: 0 1px 6px #00000040;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

.download_linkButton__12goS:hover,
    .download_linkButton__12goS:focus {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

.download_linkButton__12goS:active {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.download_linkIcon__3QrfW {
    width: 28px;
    height: 28px;
  }

.download_apple__3kkmD {
  width: 108px;
}

.download_google__M8-QN {
  width: 96px;
}

@media screen and (max-width: 968px) {
  .download_container__6nBLu {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 0 16px;
  }

  .download_picture__2fSFL {
    max-width: 640px;
  }

  .download_intro__CejOX {
    max-width: 640px;
  }

  .download_title__1bzC0 {
    text-align: center;
    font-size: var(--font-size-xl);
  }

  .download_caption__1VysA {
    text-align: center;
    font-size: var(--font-size-xs);
  }

  .download_link__3DnpB {
    grid-gap: 8px;
    gap: 8px;
    justify-content: center;
  }

    .download_linkButton__12goS {
      grid-gap: 4px;
      gap: 4px;
      width: 108px;
      height: 36px;
    }

    .download_linkIcon__3QrfW {
      width: 16px;
      height: 16px;
    }

  .download_apple__3kkmD {
    width: 56px;
  }

  .download_google__M8-QN {
    width: 52px;
  }
}

.slider_layoutSlider__10bks {
  padding: 50px 190px 50px 40px;
}
  .slider_layoutSlider__10bks ul {
    width: 180px;
  }

.slider_layoutSlider__10bks .slider_menuItem__3HAOG:nth-child(1) {
  margin-top: 0 !important;
}

.slider_layoutSlider__10bks .slider_menuItem__3HAOG {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-top: 32px;
  cursor: pointer;

  display: flex;
  padding-left: 12px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.slider_layoutSlider__10bks .slider_menuItem__3HAOG:hover {
  font-weight: 600;
}

.slider_layoutSlider__10bks .slider_menuItemSelected__2xwjX {
  font-weight: 600;
  padding-left: 0;
}

.slider_layoutSlider__10bks .slider_menuItemSelected__2xwjX::before {
  display: inline;
  height: 12px;
  background: #ff6c2d;
  border-radius: 15px;
  content: '';
  position: relative;
  width: 12px;
  left: -4px;
  top: 6px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.slider_layoutSlider__10bks .slider_menuItemSelected__2xwjX span::after {
  width: 100%;
  background: none;
}

.slider_base__30R7W {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.accountTips_tips__1nmtL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 36px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: #eee;
}
  .accountTips_tips__1nmtL .accountTips_textWrapper__mNvsZ {
    display: flex;
    align-items: center;
  }
  .accountTips_tips__1nmtL .accountTips_textWrapper__mNvsZ .accountTips_icon__3gKp1 {
      width: 24px;
      height: 24px;
    }
  .accountTips_tips__1nmtL .accountTips_textWrapper__mNvsZ .accountTips_text__jrKTC {
      margin-left: 16px;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }
  .accountTips_tips__1nmtL .accountTips_btn__2nKv6 {
    min-width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 6px;
    border: 2px solid #ff6c2d;
    border-radius: 12px;
    font-size: 18px;
    line-height: 18px;
    color: #ff6c2d;
  }
  .accountTips_tips__1nmtL .accountTips_btn__2nKv6:disabled{
       color: #909090;
       border: 2px solid #909090;
       cursor: not-allowed;
    }

@media screen and (max-width: 968px) {
  .accountTips_tips__1nmtL {
    display: block;
    padding: 24px;
  }
    .accountTips_tips__1nmtL .accountTips_btn__2nKv6 {
      margin-top: 20px;
    }
}

.account_layout_basicLayout__mLsZd {
  --color-highlight: #000000;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
}
  .account_layout_basicLayout__mLsZd .account_layout_hasSider__7Y1qF {
    align-content: space-between;
  }
  .account_layout_basicLayout__mLsZd .account_layout_accountContainer__FEzOW {
    padding-top: 36px;
    padding-right: 24px;
  }
  .account_layout_basicLayoutDivider__Wg7Tx {
    border: 0.5px solid #000000;
  }
.account_layout_topNav__3_uQ3 {
  float: right;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 28px;
  padding: 0 10px;
  position: relative;
  right: 0;
  top: 50px;
}
.account_layout_topNavIcon__28WDE {
    cursor: pointer;
    width: 28px;
  }

.account_layout_rightContainer__1Rwk5 {
  flex: 1 1;
}
@media (max-width: 1440px) {
  .account_layout_rightContainer__1Rwk5 {
    width: auto;
  }
}
@media (max-width: 900px) {
  .account_layout_basicLayout__mLsZd {
    display: block;
  }
  .account_layout_rightContainer__1Rwk5 {
    width: auto;
    padding: 0 10px;
  }
    .account_layout_basicLayout__mLsZd .account_layout_accountContainer__FEzOW {
      padding-right: 0;
    }
}

.dashboard_container__1_7-g {
  position: relative;
}

.dashboard_caption__2uqeJ {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #909090;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.dashboard_step__1Lsf9 {
  margin-top: 24px;
}

.dashboard_step__1Lsf9 svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

.dashboard_stepTitle__2LzdK {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.dashboard_stepTitleText__1Oddn {
      margin-left: 5px;
      font-weight: 600;
    }

.dashboard_stepContent__3RLNi {
    padding: 5px 0px;
    color: #909090;
  }

.dashboard_stepSuccess__3In0V {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.dashboard_wallet__3b33Y .dashboard_address__LB4X_ {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

.dashboard_wallet__3b33Y .dashboard_addressLink__Sv-IX {
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      overflow-wrap: anywhere;
      cursor: pointer;
    }

.dashboard_wallet__3b33Y .dashboard_addressLink__Sv-IX:hover,
      .dashboard_wallet__3b33Y .dashboard_addressLink__Sv-IX:visited {
        color: #000000;
      }

.dashboard_wallet__3b33Y .dashboard_addressIcon__1-xfE {
      margin-left: 6px;
    }

.dashboard_wallet__3b33Y .dashboard_tokenList__1ebij {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 32px;
    grid-gap: 24px;
    gap: 24px;
  }

.dashboard_wallet__3b33Y .dashboard_tokenList__1ebij .dashboard_tokenItem__9Wu_2 {
      width: 290px;
    }

.dashboard_inventory__3gBxc .dashboard_noReslut__ETUz2 {
    margin-top: 12px;
  }

.dashboard_inventoryContent__3YwKC {
    margin-top: 12px;
  }

.dashboard_inventoryList__1QPK1 {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
  }

.dashboard_inventory__3gBxc .dashboard_pagination__1OJk- {
    margin: 40px 0;
  }

@media screen and (max-width: 968px) {
    .dashboard_wallet__3b33Y .dashboard_tokenList__1ebij {
      display: block;
    }

      .dashboard_wallet__3b33Y .dashboard_tokenList__1ebij .dashboard_tokenItem__9Wu_2 {
        width: 100%;
        margin-top: 20px;
      }
    .dashboard_inventoryList__1QPK1 {
      justify-content: center;
    }
}

.transfer_topBanner__gDMUQ {
  height: 48px;
  padding: 13px 22px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

  .transfer_topBannerIcoTips__2jGz4 {
    height: 20px;
    margin-right: 10px;
  }

  .transfer_topBannerTips__3gq3r {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #909090;
  }

.transfer_transferTips__Y5knM {
  padding: 24px 0 0 0;
}

.transfer_transferTips__Y5knM span {
    height: 20px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }

.transfer_transferContent__324vZ {
  max-width: 975px;
  --cardsJustifyContent: flex-start;
  --cardsWidth: 142px;
  --cardImageWidth: '114px !important';
  --cardsGap: 4px 24px !important;
  --cardsHeight: auto;
  --cards720Height: auto;
}

.transfer_transferContent__324vZ .transfer_card__1dBb4 {
    justify-content: flex-start !important;
  }

.transfer_transferContentDisable__2sPgg {
    display: none;
  }

.transfer_cardListModalNormal__20TaH {
    width: 975px;
    height: 655px;
  }

.transfer_cardListModalMiddle__1iZ9F {
    width: 504px;
    height: 655px;
  }

.transfer_cardListModalBottom__3a-8q {
    display: flex;
    justify-content: flex-start;
  }

.transfer_cardListModalBtnBack__1iXHV {
      margin-right: 20px;
    }

.transfer_cardListModalBtnBackSmall__3o7T2 {
        width: 186px !important;
      }

.transfer_cardListModalTransferBtn__2MiZA {
      height: 40px;
      line-height: 40px;
      width: 212px;
      padding: 0px;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      border: 2px solid #ff6c2d;
      color: #fff !important;
      background-color: #ff6c2d !important;
      margin-top: 10px;
      margin-left: 28px;
    }

.transfer_cardListModalTransferBtnDisable__q8ibx {
        margin-left: 28px;
        height: 40px;
        line-height: 40px;
        width: 212px;
        padding: 0px;
        text-align: center;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 10px;
        color: #909090 !important;
        background: #ffffff !important;
        border: 2px solid #909090;
      }

.transfer_cardListModalTransferError__1Nq0d {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #ff0000;
      min-height: 18px;
      margin-top: 5px;
    }

.transfer_cardListModalContent__2t1bj {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

.transfer_cardListModalContentConfirm__3pdWr {
      margin: 20px 0px;
    }

.transfer_cardListModalContentConfirm__3pdWr span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #909090;
        margin-bottom: 8px;
      }

.transfer_cardListModalContentConfirm__3pdWr div {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #000000;
      }

.transfer_cardListModalContentConfirmLarge__pflEY {
        display: flex;
        margin: 10px 0px;
      }

.transfer_cardListModalContentConfirmLarge__pflEY span {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #909090;
          margin-right: 12px;
        }

.transfer_cardListModalContentConfirmLarge__pflEY div {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #000000;
        }

.transfer_cardListModalContentPagination__2o_vm {
      margin-top: 36px;
    }

.transfer_cardListModalContentPaginationLarge__1nSvk {
        margin-top: 4px;
        padding-top: 0px;
        padding-bottom: 16px;
      }

.transfer_cardListModalContentInputView__14o4e {
      position: relative;
    }

.transfer_cardListModalContentInputViewLarge__MeCIH svg {
          top: 30px !important;
          right: 23px !important;
        }

.transfer_cardListModalContentInputView__14o4e svg {
        width: 14px !important;
        height: 14px !important;
        fill: none !important;
        position: absolute;
        right: 0;
        top: 30px;
      }

.transfer_cardListModalContentInput__1_2ES {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #909090;
      width: 448px;
      height: 30px;
      border-radius: 0px;
      padding-right: 15px !important;
      padding-left: 4px !important;
      border-bottom: 1px solid #000000;
    }

.transfer_cardListModalContentInputLarge__2eL34 {
        width: 448px;
        margin-right: 24px;
      }

.transfer_cardListModalContentInput__1_2ES:hover,
      .transfer_cardListModalContentInput__1_2ES:active,
      .transfer_cardListModalContentInput__1_2ES:focus {
        border: 0px;
        border-bottom: 1px solid #000000;
      }

.transfer_cardListModalContentInputDone__17hF8 {
        border-bottom: 2px solid #83d300 !important;
      }

.transfer_cardListModalContentInputDone__17hF8:hover,
        .transfer_cardListModalContentInputDone__17hF8:active,
        .transfer_cardListModalContentInputDone__17hF8:focus {
          border-bottom: 2px solid #83d300 !important;
        }

.transfer_cardListModalContentBtn__1ebwU {
      text-align: left;
      border-top: 1px solid #eeeeee;
    }

.transfer_cardListModalContentBtnTitle__npeqn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;
      }

.transfer_cardListModalContentBtnTitle__npeqn svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }

.transfer_cardListModalContentConfirmTransfer__Th-f- {
      text-align: left;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
    }

.transfer_cardListModalContentConfirmTransferTitle__2XsWS {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;
      }

.transfer_cardListModalContentConfirmTransferTitle__2XsWS svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }

.transfer_cardListModalContentConfirmTransferBtn__2KXnG {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

.transfer_steps__3q_Ts {
  display: block;
}

.transfer_step__sQ4mJ {
  margin-top: 24px;
}

.transfer_step__sQ4mJ svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

.transfer_stepTitle__12xVK {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.transfer_stepTitleText__2Ca1h {
      margin-left: 5px;
      font-weight: 600;
    }

.transfer_stepContent__2CQXe {
    padding: 5px 0px;
    color: #909090;
  }

.transfer_stepSuccess__3ig-b {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
@media (max-width: 1380px) {
        .transfer_cardListModalContentConfirmTransferBackBtn__1kfGW {
          margin-left: 10px;
          margin-right: 10px;
          width: 120px !important;
        }
        .transfer_cardListModalContentConfirmTransferConfirmBtn__1W7FN {
          margin-right: 0;
          width: 210px !important;
        }
}
@media (max-width: 1240px) {
      .transfer_cardListModalTransferBtn__2MiZA {
        width: 162px;
      }

        .transfer_cardListModalTransferBtnDisable__q8ibx {
          width: 162px;
        }
      .transfer_cardListModalContentInput__1_2ES {
        width: 224px;
      }
      .transfer_cardListModalContentConfirmTransfer__Th-f- {
        text-align: left;
        display: block;
        border-top: 1px solid #eeeeee;
      }

        .transfer_cardListModalContentConfirmTransferTitle__2XsWS {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          margin-top: 13px;
          text-transform: uppercase;
        }

          .transfer_cardListModalContentConfirmTransferTitle__2XsWS svg {
            width: 24px;
            height: 24px;
            margin-right: 14px;
          }
        .transfer_cardListModalContentConfirmTransferBtn__2KXnG {
          display: block;
        }
        .transfer_cardListModalContentConfirmTransferBlox__3UzW_ {
          width: 100% !important;
        }
        .transfer_cardListModalContentConfirmTransferBackBtn__1kfGW {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
        .transfer_cardListModalContentConfirmTransferConfirmBtn__1W7FN {
          margin-right: 0;
          width: 100% !important;
        }
}

@media (max-width: 900px) {
  .transfer_topBanner__gDMUQ {
    height: auto;
  }
    .transfer_cardListModalNormal__20TaH {
      width: 95% !important;
      height: 665px;
    }

    .transfer_cardListModalMiddle__1iZ9F {
      width: 95% !important;
      height: 665px;
    }

    .transfer_cardListModalBottom__3a-8q {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
      .transfer_cardListModalTransferBtn__2MiZA {
        width: 100%;
        margin-left: 0;
      }

        .transfer_cardListModalTransferBtnDisable__q8ibx {
          width: 100%;
          margin-left: 0;
        }

    .transfer_cardListModalContent__2t1bj {
      margin: 16px 0px;
    }

      .transfer_cardListModalContentConfirm__3pdWr {
        flex-direction: column;
      }

        .transfer_cardListModalContentConfirm__3pdWr span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }

        .transfer_cardListModalContentConfirm__3pdWr div {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
        }

      .transfer_cardListModalContentInputView__14o4e {
        width: 100%;
      }

        .transfer_cardListModalContentInputView__14o4e svg {
          right: 0px !important;
        }

      .transfer_cardListModalContentInput__1_2ES {
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        line-height: 16px;
      }

        .transfer_cardListModalContentInput__1_2ES:focus,
        .transfer_cardListModalContentInput__1_2ES:active {
          -ms-user-select: text;
              user-select: text;
          -webkit-user-select: text;
          zoom: 1;
        }

        .transfer_cardListModalContentInputLarge__2eL34 {
          width: 100%;
        }
      .transfer_cardListModalContentConfirmTransfer__Th-f- {
        text-align: left;
        display: block;
        border-top: 1px solid #eeeeee;
      }

        .transfer_cardListModalContentConfirmTransferTitle__2XsWS {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          margin-top: 13px;
          text-transform: uppercase;
        }

          .transfer_cardListModalContentConfirmTransferTitle__2XsWS svg {
            width: 24px;
            height: 24px;
            margin-right: 14px;
          }
        .transfer_cardListModalContentConfirmTransferBtn__2KXnG {
          display: block;
        }
        .transfer_cardListModalContentConfirmTransferBlox__3UzW_ {
          width: 100% !important;
        }
        .transfer_cardListModalContentConfirmTransferBackBtn__1kfGW {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
        .transfer_cardListModalContentConfirmTransferConfirmBtn__1W7FN {
          margin-right: 0;
          width: 100% !important;
        }
}

.account_caption__1_dvc {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #909090;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.account_login__27m5B {
  margin-top: 24px;
}

.account_login__27m5B .account_loginBanner__23Izf {
    width: 100%;
    max-width: 600px;
    cursor: pointer;
  }

.account_user__1L4aY {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.account_user__1L4aY .account_infoAvatar__3ZQe7 {
    order: 3;
    margin-left: 20px;
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

.account_user__1L4aY .account_infoAvatar__3ZQe7 .account_avatar__3fzcA {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      background: #000000;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

.account_user__1L4aY .account_infoAvatar__3ZQe7 .account_logout__3vE2B {
      margin-left: 10px;
      font-size: 18px;
      line-height: 20px;
    }

.account_user__1L4aY .account_email__3tCrv {
    order: 1;
  }

.account_user__1L4aY .account_emailField__3NYDB {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }

.account_user__1L4aY .account_emailValue__30Rwq {
      margin-top: 8px;
      font-size: 14px;
      line-height: 16px;
    }

.account_user__1L4aY .account_pairBtn__jkXjm {
    order: 2;
    margin-left: 60px;
    padding: 0 12px;
  }

.account_tokenList__2VubP {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.account_tokenList__2VubP .account_tokenItem__1SttA {
    width: 290px;
  }

.account_deposit__1Dpm4,
.account_withdraw__-6Qyk {
  max-width: 500px;
}

.account_deposit__1Dpm4 .account_amount__fiB8s, .account_withdraw__-6Qyk .account_amount__fiB8s {
    margin-top: 40px;
    font-size: 14px;
    line-height: 14px;
    color: #909090;
  }

.account_deposit__1Dpm4 .account_inputWrapper__t_Doe, .account_withdraw__-6Qyk .account_inputWrapper__t_Doe {
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding-bottom: 14px;
    border-bottom: 1px solid #000000;
  }

.account_deposit__1Dpm4 .account_inputWrapper__t_Doe svg, .account_withdraw__-6Qyk .account_inputWrapper__t_Doe svg {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eee;
    }

.account_deposit__1Dpm4 .account_inputWrapper__t_Doe .account_input__PEOQS, .account_withdraw__-6Qyk .account_inputWrapper__t_Doe .account_input__PEOQS {
      height: 26px;
      margin-left: 16px;
      padding: 0;
      font-size: 24px;
      line-height: 26px;
      color: #000;
      outline: none;
    }

.account_deposit__1Dpm4 .account_inputWrapper__t_Doe .account_input__PEOQS:hover,
      .account_deposit__1Dpm4 .account_inputWrapper__t_Doe .account_input__PEOQS:focus,
      .account_withdraw__-6Qyk .account_inputWrapper__t_Doe .account_input__PEOQS:hover,
      .account_withdraw__-6Qyk .account_inputWrapper__t_Doe .account_input__PEOQS:focus {
        border: none;
      }

.account_deposit__1Dpm4 .account_available__2-RaN, .account_withdraw__-6Qyk .account_available__2-RaN {
    margin-top: 12px;
    font-size: 18px;
    line-height: 20px;
  }

.account_deposit__1Dpm4 .account_actions__1J5H-, .account_withdraw__-6Qyk .account_actions__1J5H- {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

.account_deposit__1Dpm4 .account_actions__1J5H- .account_btn__2eo07, .account_withdraw__-6Qyk .account_actions__1J5H- .account_btn__2eo07 {
      width: 242px;
    }

.account_steps__5JN8B {
  display: block;
}

.account_step__1lPax {
  margin-top: 24px;
}

.account_step__1lPax svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

.account_stepTitle__3V4PE {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.account_stepTitleText__PswzC {
      margin-left: 5px;
      font-weight: 600;
    }

.account_stepContent__1_CKS {
    padding: 5px 0px;
    color: #909090;
  }

.account_stepSuccess__39SWQ {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.account_inventory__29jlN .account_noReslut__3Dr-L {
    margin-top: 12px;
  }

.account_inventoryContent__3zW8M {
     margin-top: 12px;
   }

.account_inventoryList__38-vU {
     display: flex;
     flex-wrap: wrap;
     grid-gap: 24px;
     gap: 24px;
   }

.account_inventory__29jlN .account_pagination__2iGZ_ {
    margin: 40px 0;
  }

.account_cursorInherit__2_Qcv {
  cursor: inherit;
}

.account_showWithdraw__a6-Fz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
}

.account_showWithdrawCard__1S9hJ {
    width: 212px !important;
    cursor: auto;
    cursor: initial;
  }

.account_showWithdrawDescription__npnaz {
    border-top: 1px solid #000000;
    padding: 16px 0px;
    margin-top: -5%;
  }

.account_showWithdrawDescriptionTitle__1_4pJ {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

.account_showWithdrawDescriptionFee__34LTH {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #FF6C2D;
    }

.account_showWithdrawFooter__3Fwr2 {
   display: flex;
   width: 100%;
   margin-top: 24px;
   justify-content: space-between;
  }

.account_showWithdrawSubmit__2FGiW {
    width: 205px;
  }

.account_showWithdrawCancel__2uwDP {
     width: 96px !important;
     color: #000000 !important;
     background: #ffffff !important;
     border: 2px solid #000000 !important;
  }
@media screen and (max-width: 1200px) {
    .account_user__1L4aY .account_pairBtn__jkXjm {
      margin-left: 20px;
    }
}

@media screen and (max-width: 968px) {
  .account_user__1L4aY {
    margin-top: 24px;
    display: block;
  }

    .account_user__1L4aY .account_infoAvatar__3ZQe7 {
      justify-content: flex-start;
      margin-left: 0;
    }
    .account_user__1L4aY .account_email__3tCrv {
      margin-top: 24px;
    }
    .account_user__1L4aY .account_pairBtn__jkXjm {
      margin-top: 24px;
      margin-left: 0;
    }

  .account_tokenList__2VubP {
    display: block;
    margin-top: 24px;
  }

    .account_tokenList__2VubP .account_tokenItem__1SttA {
      width: 100%;
      margin-top: 16px;
    }
    .account_deposit__1Dpm4 .account_actions__1J5H- .account_btn__2eo07, .account_withdraw__-6Qyk .account_actions__1J5H- .account_btn__2eo07 {
      width: 100%;
    }
}

:root {
  --color-highlight: #c39175;
  --color-light-primary: #ecdec8;
  --color-light-secondary: #70512e;
  /* --color-background-tertiary: #4d392f;*/
  --color-background-secondary: #574134;
  --color-background-primary: #2f231d;
  --color-light: #fff;
  --color-dark: #000;
  --color-primary: #ff6c2d;
  --color-secondary: #3864ff;
  --color-disable: #909090;
  --color-link: #c2ad3a;
  --color-warning: #d65a55;
  --color-mask-bg: rgba(0, 0, 0, 0.6);
  --z-index-modal: 1400;
  --z-index-message: 1500;
  --z-index-header: 1300;
  --color-button: #000000;
  --color-button-background: #ffffff;
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-sm: 0.875rem; /* 14px */
  --font-size-md: 1rem; /* 16px */
  --font-size-lg: 1.125rem; /* 18px */
  --font-size-xl: 1.25rem; /* 20px */
  --font-size-2xl: 1.5rem; /* 24px */
  --font-size-3xl: 2.5rem; /* 40px */
}
.button {
  display: inline-flex;
  align-items: center;
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.prevBtn {
  padding-right: 10px;
  justify-content: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.prevBtn:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.prevBtn:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.backBtn {
  text-transform: uppercase;
  width: 212px;
  height: 40px;
  background: #3864ff !important;
  border-radius: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff !important;
  cursor: pointer;
}
.buy,
.trade {
  height: 40px;
  line-height: 40px;
  width: 212px;
  padding: 0px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
}
.buy:hover, .trade:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
.tradeDisabled {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #909090;
  color: #909090 !important;
  background-color: #fff !important;
}
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
  }

.button-normal {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0px 10px !important;
  background-color: #ffffff !important;
  color: #ff6c2d !important;
  border: 1px solid #ff6c2d;
  cursor: pointer;
}
.fullWidth {
  width: 100% !important;
}


/* walletconnect modal*/
.walletconnect-modal__base {
  top: 0% !important;
  min-width: 400px !important;
  -webkit-transform: translateY(0%) !important;
          transform: translateY(0%) !important;
}

.walletconnect-qrcode__base {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 576px) {
  .walletconnect-modal__base {
    min-width: auto !important;
  }
}



.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.mdl-button::-moz-focus-inner {
  border: 0;
}

.mdl-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.mdl-button:focus:not(:active) {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdl-button:active {
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button.mdl-button--colored {
  color: #3f51b5;
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0, 0, 0, 0.12);
}

input.mdl-button[type='submit'] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--raised:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--raised.mdl-button--colored {
  background: #3f51b5;
  color: #fff;
}

.mdl-button--raised.mdl-button--colored:hover {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored:active {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}

.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab.mdl-button--colored {
  background: #ff4081;
  color: #fff;
}

.mdl-button--fab.mdl-button--colored:hover {
  background-color: #ff4081;
}

.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: #ff4081;
}

.mdl-button--fab.mdl-button--colored:active {
  background-color: #ff4081;
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}

.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0;
  left: 0;
}

.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple,
.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: #3f51b5;
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: #fff;
}

.mdl-button--primary.mdl-button--primary.mdl-button--fab,
.mdl-button--primary.mdl-button--primary.mdl-button--raised {
  color: #fff;
  background-color: #3f51b5;
}

.mdl-button--accent.mdl-button--accent {
  color: #ff4081;
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: #fff;
}

.mdl-button--accent.mdl-button--accent.mdl-button--fab,
.mdl-button--accent.mdl-button--accent.mdl-button--raised {
  color: #fff;
  background-color: #ff4081;
}

.mdl-button.mdl-button--disabled.mdl-button--disabled,
.mdl-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  background-color: transparent;
}

.mdl-button--fab.mdl-button--disabled.mdl-button--disabled,
.mdl-button--fab[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-button--raised.mdl-button--disabled.mdl-button--disabled,
.mdl-button--raised[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
}

.mdl-button--colored.mdl-button--disabled.mdl-button--disabled,
.mdl-button--colored[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-button .material-icons {
  vertical-align: middle;
}

.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: #ff4081;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.mdl-card__title {
  align-items: center;
  color: #000;
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  -webkit-perspective-origin: 165px 56px;
          perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
          transform-origin: 165px 56px;
  box-sizing: border-box;
}

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  -webkit-transform-origin: 149px 48px;
          transform-origin: 149px 48px;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}

.mdl-card__supporting-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%;
}

.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 8px;
  box-sizing: border-box;
}

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card--expand {
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
    0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px;
}

.mdl-dialog__title {
  padding: 24px 24px 0;
  margin: 0;
  font-size: 2.5rem;
}

.mdl-dialog__actions {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.mdl-dialog__actions > * {
  margin-right: 8px;
  height: 36px;
}

.mdl-dialog__actions > :first-child {
  margin-right: 0;
}

.mdl-dialog__actions--full-width {
  padding: 0 0 8px 0;
}

.mdl-dialog__actions--full-width > * {
  height: 48px;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right;
}

.mdl-dialog__content {
  padding: 20px 24px 24px 24px;
  color: rgba(0, 0, 0, 0.54);
}

.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%;
}

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-progress > .progressbar {
  background-color: #3f51b5;
  z-index: 1;
  left: 0;
}

.mdl-progress > .bufferbar {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    linear-gradient(to right, #3f51b5, #3f51b5);
  z-index: 0;
  left: 0;
}

.mdl-progress > .auxbar {
  right: 0;
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)
    > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)
    > .auxbar {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      linear-gradient(to right, #3f51b5, #3f51b5);
    -webkit-mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=);
            mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=);
  }
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    linear-gradient(to right, #3f51b5, #3f51b5);
}

.mdl-progress.mdl-progress--indeterminate > .bar1,
.mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: #3f51b5;
  -webkit-animation-name: indeterminate1;
          animation-name: indeterminate1;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.mdl-progress.mdl-progress--indeterminate > .bar3,
.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  background-color: #3f51b5;
  -webkit-animation-name: indeterminate2;
          animation-name: indeterminate2;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes indeterminate1 {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@keyframes indeterminate1 {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@-webkit-keyframes indeterminate2 {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 0%;
  }
  75% {
    left: 0;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

@keyframes indeterminate2 {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 0%;
  }
  75% {
    left: 0;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
    0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: 'Loading...';
}

.mdl-spinner.is-upgraded.is-active {
  -webkit-animation: mdl-spinner__container-rotate 1.568s linear infinite;
          animation: mdl-spinner__container-rotate 1.568s linear infinite;
}

@-webkit-keyframes mdl-spinner__container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes mdl-spinner__container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mdl-spinner__layer-1 {
  border-color: #42a5f5;
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
          animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
}

.mdl-spinner__layer-2 {
  border-color: #f44336;
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
          animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
}

.mdl-spinner__layer-3 {
  border-color: #fdd835;
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
          animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
}

.mdl-spinner__layer-4 {
  border-color: #4caf50;
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
          animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both;
}

@-webkit-keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@-webkit-keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
  }
}

@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
  }
}

@-webkit-keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
}

@-webkit-keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
}

@-webkit-keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}

.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%;
}

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left;
}

.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right;
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%;
}

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
          animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
          transform: rotate(129deg);
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  -webkit-animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
          animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
          transform: rotate(-129deg);
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  -webkit-animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
          animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
}

@-webkit-keyframes mdl-spinner__left-spin {
  from {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
}

@keyframes mdl-spinner__left-spin {
  from {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
}

@-webkit-keyframes mdl-spinner__right-spin {
  from {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
}

@keyframes mdl-spinner__right-spin {
  from {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
}

.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
}

.mdl-textfield__input[type='number'] {
  -moz-appearance: textfield;
}

.mdl-textfield__input[type='number']::-webkit-inner-spin-button,
.mdl-textfield__input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: 0;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #d50000;
  box-shadow: none;
}

.mdl-textfield.is-disabled .mdl-textfield__input,
fieldset[disabled] .mdl-textfield .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.mdl-textfield.has-placeholder .mdl-textfield__label,
.mdl-textfield.is-dirty .mdl-textfield__label {
  visibility: hidden;
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none;
}

.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label,
fieldset[disabled] .mdl-textfield .mdl-textfield__label {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #3f51b5;
  font-size: 12px;
  top: 4px;
  visibility: visible;
}

.mdl-textfield--floating-label.has-placeholder
  .mdl-textfield__expandable-holder
  .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty
  .mdl-textfield__expandable-holder
  .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused
  .mdl-textfield__expandable-holder
  .mdl-textfield__label {
  top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #d50000;
  font-size: 12px;
}

.mdl-textfield__label:after {
  background-color: #3f51b5;
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #d50000;
}

.mdl-textfield__error {
  color: #d50000;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px;
}

.mdl-textfield.is-dirty .mdl-textfield__expandable-holder,
.mdl-textfield.is-focused .mdl-textfield__expandable-holder {
  max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: #fff;
  color: #000;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.firebaseui-container {
  background-color: #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font: 16px Roboto, arial, sans-serif;
  margin: 0 auto;
  max-width: 360px;
  overflow: visible;
  position: relative;
  text-align: left;
  width: 100%;
}

.firebaseui-container.mdl-card {
  overflow: visible;
}

.firebaseui-card-header {
  padding: 24px 24px 0 24px;
}

.firebaseui-card-content {
  padding: 15px 0px;
}

.firebaseui-card-footer {
  padding: 15px 0px;
}

.firebaseui-card-actions {
  box-sizing: border-box;
  display: table;
  font-size: 14px;
  padding: 8px 24px 24px 24px;
  text-align: left;
  width: 100%;
}

.firebaseui-form-links {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.firebaseui-form-actions {
  display: table-cell;
  text-align: right;
  white-space: nowrap;
  width: 100%;
}

.firebaseui-subtitle,
.firebaseui-title {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
}

.firebaseui-title {
  padding-bottom: 16px;
}

.firebaseui-subtitle {
  margin: 16px 0;
}

.firebaseui-text {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.firebaseui-id-page-password-recovery-email-sent p.firebaseui-text {
  margin: 16px 0;
}

.firebaseui-text-emphasis {
  font-weight: 700;
}

.firebaseui-error {
  color: #dd2c00;
  direction: ltr;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  text-align: left;
}

.firebaseui-text-input-error {
  margin: -16px 0 16px;
}

.firebaseui-error-wrapper {
  min-height: 16px;
}

.firebaseui-list-item {
  direction: ltr;
  margin: 0;
  padding: 0;
  text-align: left;
}

.firebaseui-hidden {
  display: none;
}

.firebaseui-relative-wrapper {
  position: relative;
}

.firebaseui-label {
  color: rgba(0, 0, 0, 0.54);
  direction: ltr;
  font-size: 16px;
  text-align: left;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #757575;
}

.firebaseui-input,
.firebaseui-input-invalid {
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  width: 100%;
}

input.firebaseui-input,
input.firebaseui-input-invalid {
  direction: ltr;
  text-align: left;
}

.firebaseui-input-invalid {
  border-color: #dd2c00;
}

.firebaseui-textfield {
  width: 100%;
}

.firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: rgba(0, 0, 0, 0.12);
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #3f51b5;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-input {
  border-color: #dd2c00;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-label::after {
  background-color: #dd2c00;
}

.firebaseui-button {
  display: inline-block;
  height: 36px;
  margin-left: 8px;
  min-width: 88px;
}

.firebaseui-link {
  color: #4285f4;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
}

.firebaseui-link:hover {
  text-decoration: underline;
}

.firebaseui-indent {
  margin-left: 1em;
}

.firebaseui-tos {
  color: #757575;
  direction: ltr;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
  margin-top: 0;
  text-align: left;
}

.firebaseui-provider-sign-in-footer > .firebaseui-tos {
  text-align: center;
}

.firebaseui-tos-list {
  list-style: none;
  text-align: right;
}

.firebaseui-inline-list-item {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.firebaseui-page-provider-sign-in,
.firebaseui-page-select-tenant {
  background: inherit;
}

.firebaseui-idp-list,
.firebaseui-tenant-list {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
  direction: ltr;
  font-weight: 500;
  height: auto;
  line-height: normal;
  min-height: 40px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
}

.firebaseui-idp-list > .firebaseui-list-item,
.firebaseui-tenant-list > .firebaseui-list-item {
  margin-bottom: 15px;
  text-align: center;
}

.firebaseui-idp-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.firebaseui-idp-icon {
  border: none;
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  width: 18px;
}

.firebaseui-idp-favicon {
  border: none;
  display: inline-block;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
  width: 14px;
}

.firebaseui-idp-text {
  color: #fff;
  display: table-cell;
  font-size: 14px;
  padding-left: 16px;
  text-transform: none;
  vertical-align: middle;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: table-cell;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}

@media (max-width: 268px) {
  .firebaseui-idp-text.firebaseui-idp-text-long {
    display: none;
  }

  .firebaseui-idp-text.firebaseui-idp-text-short {
    display: table-cell;
  }
}

@media (max-width: 320px) {
  .firebaseui-recaptcha-container > div > div {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #757575;
}

[data-provider-id='yahoo.com']
  > .firebaseui-idp-icon-wrapper
  > .firebaseui-idp-icon {
  height: 22px;
  width: 22px;
}

.firebaseui-info-bar {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  left: 10%;
  padding: 8px 16px;
  position: absolute;
  right: 10%;
  text-align: center;
  top: 0;
}

.firebaseui-info-bar-message {
  font-size: 12px;
  margin: 0;
}

.firebaseui-dialog {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font: 16px Roboto, arial, sans-serif;
  height: auto;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding: 24px;
  text-align: left;
}

.firebaseui-dialog-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.firebaseui-dialog-icon {
  float: left;
  height: 40px;
  margin-right: 24px;
  width: 40px;
}

.firebaseui-progress-dialog-message {
  display: table-cell;
  font-size: 16px;
  font-weight: 400;
  min-height: 40px;
  vertical-align: middle;
}

.firebaseui-progress-dialog-loading-icon {
  height: 28px;
  margin: 6px 30px 6px 6px;
  width: 28px;
}

.firebaseui-icon-done {
  background-image: url(https://www.gstatic.com/images/icons/material/system/2x/done_googgreen_36dp.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 36px 36px;
}

.firebaseui-phone-number {
  display: flex;
}

.firebaseui-country-selector {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 18px auto;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  height: initial;
  line-height: normal;
  margin: 20px 24px 20px 0;
  padding: 4px 20px 4px 0;
  width: 90px;
}

.firebaseui-country-selector-flag {
  display: inline-block;
  margin-right: 1ex;
}

.firebaseui-flag {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png);
  background-size: 100% auto;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
  height: 14px;
  width: 24px;
}

.firebaseui-list-box-dialog {
  max-height: 90%;
  overflow: auto;
  padding: 8px 0 0 0;
}

.firebaseui-list-box-actions {
  padding-bottom: 8px;
}

.firebaseui-list-box-icon-wrapper {
  display: table-cell;
  padding-right: 24px;
  vertical-align: top;
}

.firebaseui-list-box-label-wrapper {
  display: table-cell;
  vertical-align: top;
}

.firebaseui-list-box-dialog-button {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  height: initial;
  line-height: normal;
  min-height: 48px;
  padding: 14px 24px;
  text-align: left;
  text-transform: none;
  width: 100%;
}

.firebaseui-phone-number-error {
  margin-left: 114px;
}

.mdl-progress.firebaseui-busy-indicator {
  height: 2px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 100%;
}

.mdl-spinner.firebaseui-busy-indicator {
  direction: ltr;
  direction: initial;
  height: 56px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 30%;
  width: 56px;
}

.firebaseui-callback-indicator-container .firebaseui-busy-indicator {
  top: 0;
}

.firebaseui-callback-indicator-container {
  height: 120px;
}

.firebaseui-new-password-component {
  display: inline-block;
  position: relative;
  width: 100%;
}

.firebaseui-input-floating-button {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  position: absolute;
  right: 0;
  top: 20px;
  width: 24px;
}

.firebaseui-input-toggle-on {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_black_24dp.png);
}

.firebaseui-input-toggle-off {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_off_black_24dp.png);
}

.firebaseui-input-toggle-focus {
  opacity: 0.87;
}

.firebaseui-input-toggle-blur {
  opacity: 0.38;
}

.firebaseui-recaptcha-wrapper {
  display: table;
  margin: 0 auto;
  padding-bottom: 8px;
}

.firebaseui-recaptcha-container {
  display: table-cell;
}

.firebaseui-recaptcha-error-wrapper {
  caption-side: bottom;
  display: table-caption;
}

.firebaseui-change-phone-number-link {
  display: block;
}

.firebaseui-resend-container {
  direction: ltr;
  margin: 20px 0;
  text-align: center;
}

.firebaseui-id-resend-countdown {
  color: rgba(0, 0, 0, 0.38);
}

.firebaseui-id-page-phone-sign-in-start .firebaseui-form-actions div {
  float: left;
}

@media (max-width: 480px) {
  .firebaseui-container {
    box-shadow: none;
    max-width: none;
    width: 100%;
  }

  .firebaseui-card-header {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding: 16px 24px 0 24px;
  }

  .firebaseui-title {
    padding-bottom: 16px;
  }

  .firebaseui-card-actions {
    padding-right: 24px;
  }

  .firebaseui-busy-indicator {
    top: 0;
  }
}

.mdl-textfield__label {
  font-weight: 400;
  margin-bottom: 0;
}

.firebaseui-id-page-blank {
  background: inherit;
  height: 64px;
}

.firebaseui-id-page-spinner {
  background: inherit;
  height: 64px;
}

.firebaseui-email-sent {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/success_status.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  height: 64px;
  margin-top: 16px;
  text-align: center;
}

.firebaseui-text-justify {
  text-align: justify;
}

.firebaseui-flag-KY {
  background-position: 0 0;
}

.firebaseui-flag-AC {
  background-position: 0 -14px;
}

.firebaseui-flag-AE {
  background-position: 0 -28px;
}

.firebaseui-flag-AF {
  background-position: 0 -42px;
}

.firebaseui-flag-AG {
  background-position: 0 -56px;
}

.firebaseui-flag-AI {
  background-position: 0 -70px;
}

.firebaseui-flag-AL {
  background-position: 0 -84px;
}

.firebaseui-flag-AM {
  background-position: 0 -98px;
}

.firebaseui-flag-AO {
  background-position: 0 -112px;
}

.firebaseui-flag-AQ {
  background-position: 0 -126px;
}

.firebaseui-flag-AR {
  background-position: 0 -140px;
}

.firebaseui-flag-AS {
  background-position: 0 -154px;
}

.firebaseui-flag-AT {
  background-position: 0 -168px;
}

.firebaseui-flag-AU {
  background-position: 0 -182px;
}

.firebaseui-flag-AW {
  background-position: 0 -196px;
}

.firebaseui-flag-AX {
  background-position: 0 -210px;
}

.firebaseui-flag-AZ {
  background-position: 0 -224px;
}

.firebaseui-flag-BA {
  background-position: 0 -238px;
}

.firebaseui-flag-BB {
  background-position: 0 -252px;
}

.firebaseui-flag-BD {
  background-position: 0 -266px;
}

.firebaseui-flag-BE {
  background-position: 0 -280px;
}

.firebaseui-flag-BF {
  background-position: 0 -294px;
}

.firebaseui-flag-BG {
  background-position: 0 -308px;
}

.firebaseui-flag-BH {
  background-position: 0 -322px;
}

.firebaseui-flag-BI {
  background-position: 0 -336px;
}

.firebaseui-flag-BJ {
  background-position: 0 -350px;
}

.firebaseui-flag-BL {
  background-position: 0 -364px;
}

.firebaseui-flag-BM {
  background-position: 0 -378px;
}

.firebaseui-flag-BN {
  background-position: 0 -392px;
}

.firebaseui-flag-BO {
  background-position: 0 -406px;
}

.firebaseui-flag-BQ {
  background-position: 0 -420px;
}

.firebaseui-flag-BR {
  background-position: 0 -434px;
}

.firebaseui-flag-BS {
  background-position: 0 -448px;
}

.firebaseui-flag-BT {
  background-position: 0 -462px;
}

.firebaseui-flag-BV {
  background-position: 0 -476px;
}

.firebaseui-flag-BW {
  background-position: 0 -490px;
}

.firebaseui-flag-BY {
  background-position: 0 -504px;
}

.firebaseui-flag-BZ {
  background-position: 0 -518px;
}

.firebaseui-flag-CA {
  background-position: 0 -532px;
}

.firebaseui-flag-CC {
  background-position: 0 -546px;
}

.firebaseui-flag-CD {
  background-position: 0 -560px;
}

.firebaseui-flag-CF {
  background-position: 0 -574px;
}

.firebaseui-flag-CG {
  background-position: 0 -588px;
}

.firebaseui-flag-CH {
  background-position: 0 -602px;
}

.firebaseui-flag-CI {
  background-position: 0 -616px;
}

.firebaseui-flag-CK {
  background-position: 0 -630px;
}

.firebaseui-flag-CL {
  background-position: 0 -644px;
}

.firebaseui-flag-CM {
  background-position: 0 -658px;
}

.firebaseui-flag-CN {
  background-position: 0 -672px;
}

.firebaseui-flag-CO {
  background-position: 0 -686px;
}

.firebaseui-flag-CP {
  background-position: 0 -700px;
}

.firebaseui-flag-CR {
  background-position: 0 -714px;
}

.firebaseui-flag-CU {
  background-position: 0 -728px;
}

.firebaseui-flag-CV {
  background-position: 0 -742px;
}

.firebaseui-flag-CW {
  background-position: 0 -756px;
}

.firebaseui-flag-CX {
  background-position: 0 -770px;
}

.firebaseui-flag-CY {
  background-position: 0 -784px;
}

.firebaseui-flag-CZ {
  background-position: 0 -798px;
}

.firebaseui-flag-DE {
  background-position: 0 -812px;
}

.firebaseui-flag-DG {
  background-position: 0 -826px;
}

.firebaseui-flag-DJ {
  background-position: 0 -840px;
}

.firebaseui-flag-DK {
  background-position: 0 -854px;
}

.firebaseui-flag-DM {
  background-position: 0 -868px;
}

.firebaseui-flag-DO {
  background-position: 0 -882px;
}

.firebaseui-flag-DZ {
  background-position: 0 -896px;
}

.firebaseui-flag-EA {
  background-position: 0 -910px;
}

.firebaseui-flag-EC {
  background-position: 0 -924px;
}

.firebaseui-flag-EE {
  background-position: 0 -938px;
}

.firebaseui-flag-EG {
  background-position: 0 -952px;
}

.firebaseui-flag-EH {
  background-position: 0 -966px;
}

.firebaseui-flag-ER {
  background-position: 0 -980px;
}

.firebaseui-flag-ES {
  background-position: 0 -994px;
}

.firebaseui-flag-ET {
  background-position: 0 -1008px;
}

.firebaseui-flag-EU {
  background-position: 0 -1022px;
}

.firebaseui-flag-FI {
  background-position: 0 -1036px;
}

.firebaseui-flag-FJ {
  background-position: 0 -1050px;
}

.firebaseui-flag-FK {
  background-position: 0 -1064px;
}

.firebaseui-flag-FM {
  background-position: 0 -1078px;
}

.firebaseui-flag-FO {
  background-position: 0 -1092px;
}

.firebaseui-flag-FR {
  background-position: 0 -1106px;
}

.firebaseui-flag-GA {
  background-position: 0 -1120px;
}

.firebaseui-flag-GB {
  background-position: 0 -1134px;
}

.firebaseui-flag-GD {
  background-position: 0 -1148px;
}

.firebaseui-flag-GE {
  background-position: 0 -1162px;
}

.firebaseui-flag-GF {
  background-position: 0 -1176px;
}

.firebaseui-flag-GG {
  background-position: 0 -1190px;
}

.firebaseui-flag-GH {
  background-position: 0 -1204px;
}

.firebaseui-flag-GI {
  background-position: 0 -1218px;
}

.firebaseui-flag-GL {
  background-position: 0 -1232px;
}

.firebaseui-flag-GM {
  background-position: 0 -1246px;
}

.firebaseui-flag-GN {
  background-position: 0 -1260px;
}

.firebaseui-flag-GP {
  background-position: 0 -1274px;
}

.firebaseui-flag-GQ {
  background-position: 0 -1288px;
}

.firebaseui-flag-GR {
  background-position: 0 -1302px;
}

.firebaseui-flag-GS {
  background-position: 0 -1316px;
}

.firebaseui-flag-GT {
  background-position: 0 -1330px;
}

.firebaseui-flag-GU {
  background-position: 0 -1344px;
}

.firebaseui-flag-GW {
  background-position: 0 -1358px;
}

.firebaseui-flag-GY {
  background-position: 0 -1372px;
}

.firebaseui-flag-HK {
  background-position: 0 -1386px;
}

.firebaseui-flag-HM {
  background-position: 0 -1400px;
}

.firebaseui-flag-HN {
  background-position: 0 -1414px;
}

.firebaseui-flag-HR {
  background-position: 0 -1428px;
}

.firebaseui-flag-HT {
  background-position: 0 -1442px;
}

.firebaseui-flag-HU {
  background-position: 0 -1456px;
}

.firebaseui-flag-IC {
  background-position: 0 -1470px;
}

.firebaseui-flag-ID {
  background-position: 0 -1484px;
}

.firebaseui-flag-IE {
  background-position: 0 -1498px;
}

.firebaseui-flag-IL {
  background-position: 0 -1512px;
}

.firebaseui-flag-IM {
  background-position: 0 -1526px;
}

.firebaseui-flag-IN {
  background-position: 0 -1540px;
}

.firebaseui-flag-IO {
  background-position: 0 -1554px;
}

.firebaseui-flag-IQ {
  background-position: 0 -1568px;
}

.firebaseui-flag-IR {
  background-position: 0 -1582px;
}

.firebaseui-flag-IS {
  background-position: 0 -1596px;
}

.firebaseui-flag-IT {
  background-position: 0 -1610px;
}

.firebaseui-flag-JE {
  background-position: 0 -1624px;
}

.firebaseui-flag-JM {
  background-position: 0 -1638px;
}

.firebaseui-flag-JO {
  background-position: 0 -1652px;
}

.firebaseui-flag-JP {
  background-position: 0 -1666px;
}

.firebaseui-flag-KE {
  background-position: 0 -1680px;
}

.firebaseui-flag-KG {
  background-position: 0 -1694px;
}

.firebaseui-flag-KH {
  background-position: 0 -1708px;
}

.firebaseui-flag-KI {
  background-position: 0 -1722px;
}

.firebaseui-flag-KM {
  background-position: 0 -1736px;
}

.firebaseui-flag-KN {
  background-position: 0 -1750px;
}

.firebaseui-flag-KP {
  background-position: 0 -1764px;
}

.firebaseui-flag-KR {
  background-position: 0 -1778px;
}

.firebaseui-flag-KW {
  background-position: 0 -1792px;
}

.firebaseui-flag-AD {
  background-position: 0 -1806px;
}

.firebaseui-flag-KZ {
  background-position: 0 -1820px;
}

.firebaseui-flag-LA {
  background-position: 0 -1834px;
}

.firebaseui-flag-LB {
  background-position: 0 -1848px;
}

.firebaseui-flag-LC {
  background-position: 0 -1862px;
}

.firebaseui-flag-LI {
  background-position: 0 -1876px;
}

.firebaseui-flag-LK {
  background-position: 0 -1890px;
}

.firebaseui-flag-LR {
  background-position: 0 -1904px;
}

.firebaseui-flag-LS {
  background-position: 0 -1918px;
}

.firebaseui-flag-LT {
  background-position: 0 -1932px;
}

.firebaseui-flag-LU {
  background-position: 0 -1946px;
}

.firebaseui-flag-LV {
  background-position: 0 -1960px;
}

.firebaseui-flag-LY {
  background-position: 0 -1974px;
}

.firebaseui-flag-MA {
  background-position: 0 -1988px;
}

.firebaseui-flag-MC {
  background-position: 0 -2002px;
}

.firebaseui-flag-MD {
  background-position: 0 -2016px;
}

.firebaseui-flag-ME {
  background-position: 0 -2030px;
}

.firebaseui-flag-MF {
  background-position: 0 -2044px;
}

.firebaseui-flag-MG {
  background-position: 0 -2058px;
}

.firebaseui-flag-MH {
  background-position: 0 -2072px;
}

.firebaseui-flag-MK {
  background-position: 0 -2086px;
}

.firebaseui-flag-ML {
  background-position: 0 -2100px;
}

.firebaseui-flag-MM {
  background-position: 0 -2114px;
}

.firebaseui-flag-MN {
  background-position: 0 -2128px;
}

.firebaseui-flag-MO {
  background-position: 0 -2142px;
}

.firebaseui-flag-MP {
  background-position: 0 -2156px;
}

.firebaseui-flag-MQ {
  background-position: 0 -2170px;
}

.firebaseui-flag-MR {
  background-position: 0 -2184px;
}

.firebaseui-flag-MS {
  background-position: 0 -2198px;
}

.firebaseui-flag-MT {
  background-position: 0 -2212px;
}

.firebaseui-flag-MU {
  background-position: 0 -2226px;
}

.firebaseui-flag-MV {
  background-position: 0 -2240px;
}

.firebaseui-flag-MW {
  background-position: 0 -2254px;
}

.firebaseui-flag-MX {
  background-position: 0 -2268px;
}

.firebaseui-flag-MY {
  background-position: 0 -2282px;
}

.firebaseui-flag-MZ {
  background-position: 0 -2296px;
}

.firebaseui-flag-NA {
  background-position: 0 -2310px;
}

.firebaseui-flag-NC {
  background-position: 0 -2324px;
}

.firebaseui-flag-NE {
  background-position: 0 -2338px;
}

.firebaseui-flag-NF {
  background-position: 0 -2352px;
}

.firebaseui-flag-NG {
  background-position: 0 -2366px;
}

.firebaseui-flag-NI {
  background-position: 0 -2380px;
}

.firebaseui-flag-NL {
  background-position: 0 -2394px;
}

.firebaseui-flag-NO {
  background-position: 0 -2408px;
}

.firebaseui-flag-NP {
  background-position: 0 -2422px;
}

.firebaseui-flag-NR {
  background-position: 0 -2436px;
}

.firebaseui-flag-NU {
  background-position: 0 -2450px;
}

.firebaseui-flag-NZ {
  background-position: 0 -2464px;
}

.firebaseui-flag-OM {
  background-position: 0 -2478px;
}

.firebaseui-flag-PA {
  background-position: 0 -2492px;
}

.firebaseui-flag-PE {
  background-position: 0 -2506px;
}

.firebaseui-flag-PF {
  background-position: 0 -2520px;
}

.firebaseui-flag-PG {
  background-position: 0 -2534px;
}

.firebaseui-flag-PH {
  background-position: 0 -2548px;
}

.firebaseui-flag-PK {
  background-position: 0 -2562px;
}

.firebaseui-flag-PL {
  background-position: 0 -2576px;
}

.firebaseui-flag-PM {
  background-position: 0 -2590px;
}

.firebaseui-flag-PN {
  background-position: 0 -2604px;
}

.firebaseui-flag-PR {
  background-position: 0 -2618px;
}

.firebaseui-flag-PS {
  background-position: 0 -2632px;
}

.firebaseui-flag-PT {
  background-position: 0 -2646px;
}

.firebaseui-flag-PW {
  background-position: 0 -2660px;
}

.firebaseui-flag-PY {
  background-position: 0 -2674px;
}

.firebaseui-flag-QA {
  background-position: 0 -2688px;
}

.firebaseui-flag-RE {
  background-position: 0 -2702px;
}

.firebaseui-flag-RO {
  background-position: 0 -2716px;
}

.firebaseui-flag-RS {
  background-position: 0 -2730px;
}

.firebaseui-flag-RU {
  background-position: 0 -2744px;
}

.firebaseui-flag-RW {
  background-position: 0 -2758px;
}

.firebaseui-flag-SA {
  background-position: 0 -2772px;
}

.firebaseui-flag-SB {
  background-position: 0 -2786px;
}

.firebaseui-flag-SC {
  background-position: 0 -2800px;
}

.firebaseui-flag-SD {
  background-position: 0 -2814px;
}

.firebaseui-flag-SE {
  background-position: 0 -2828px;
}

.firebaseui-flag-SG {
  background-position: 0 -2842px;
}

.firebaseui-flag-SH {
  background-position: 0 -2856px;
}

.firebaseui-flag-SI {
  background-position: 0 -2870px;
}

.firebaseui-flag-SJ {
  background-position: 0 -2884px;
}

.firebaseui-flag-SK {
  background-position: 0 -2898px;
}

.firebaseui-flag-SL {
  background-position: 0 -2912px;
}

.firebaseui-flag-SM {
  background-position: 0 -2926px;
}

.firebaseui-flag-SN {
  background-position: 0 -2940px;
}

.firebaseui-flag-SO {
  background-position: 0 -2954px;
}

.firebaseui-flag-SR {
  background-position: 0 -2968px;
}

.firebaseui-flag-SS {
  background-position: 0 -2982px;
}

.firebaseui-flag-ST {
  background-position: 0 -2996px;
}

.firebaseui-flag-SV {
  background-position: 0 -3010px;
}

.firebaseui-flag-SX {
  background-position: 0 -3024px;
}

.firebaseui-flag-SY {
  background-position: 0 -3038px;
}

.firebaseui-flag-SZ {
  background-position: 0 -3052px;
}

.firebaseui-flag-TA {
  background-position: 0 -3066px;
}

.firebaseui-flag-TC {
  background-position: 0 -3080px;
}

.firebaseui-flag-TD {
  background-position: 0 -3094px;
}

.firebaseui-flag-TF {
  background-position: 0 -3108px;
}

.firebaseui-flag-TG {
  background-position: 0 -3122px;
}

.firebaseui-flag-TH {
  background-position: 0 -3136px;
}

.firebaseui-flag-TJ {
  background-position: 0 -3150px;
}

.firebaseui-flag-TK {
  background-position: 0 -3164px;
}

.firebaseui-flag-TL {
  background-position: 0 -3178px;
}

.firebaseui-flag-TM {
  background-position: 0 -3192px;
}

.firebaseui-flag-TN {
  background-position: 0 -3206px;
}

.firebaseui-flag-TO {
  background-position: 0 -3220px;
}

.firebaseui-flag-TR {
  background-position: 0 -3234px;
}

.firebaseui-flag-TT {
  background-position: 0 -3248px;
}

.firebaseui-flag-TV {
  background-position: 0 -3262px;
}

.firebaseui-flag-TW {
  background-position: 0 -3276px;
}

.firebaseui-flag-TZ {
  background-position: 0 -3290px;
}

.firebaseui-flag-UA {
  background-position: 0 -3304px;
}

.firebaseui-flag-UG {
  background-position: 0 -3318px;
}

.firebaseui-flag-UM {
  background-position: 0 -3332px;
}

.firebaseui-flag-UN {
  background-position: 0 -3346px;
}

.firebaseui-flag-US {
  background-position: 0 -3360px;
}

.firebaseui-flag-UY {
  background-position: 0 -3374px;
}

.firebaseui-flag-UZ {
  background-position: 0 -3388px;
}

.firebaseui-flag-VA {
  background-position: 0 -3402px;
}

.firebaseui-flag-VC {
  background-position: 0 -3416px;
}

.firebaseui-flag-VE {
  background-position: 0 -3430px;
}

.firebaseui-flag-VG {
  background-position: 0 -3444px;
}

.firebaseui-flag-VI {
  background-position: 0 -3458px;
}

.firebaseui-flag-VN {
  background-position: 0 -3472px;
}

.firebaseui-flag-VU {
  background-position: 0 -3486px;
}

.firebaseui-flag-WF {
  background-position: 0 -3500px;
}

.firebaseui-flag-WS {
  background-position: 0 -3514px;
}

.firebaseui-flag-XK {
  background-position: 0 -3528px;
}

.firebaseui-flag-YE {
  background-position: 0 -3542px;
}

.firebaseui-flag-YT {
  background-position: 0 -3556px;
}

.firebaseui-flag-ZA {
  background-position: 0 -3570px;
}

.firebaseui-flag-ZM {
  background-position: 0 -3584px;
}

.firebaseui-flag-ZW {
  background-position: 0 -3598px;
}

html,
body {
  height: 100%;
  /*color: var(--color-light-primary);*/
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Archivo', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  background-color: #ffffff;
  font-weight: 400;
}

#root {
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
  a:focus {
    color: var(--color-highlight);
  }

@font-face {
  font-family: 'Chivo';
  font-display: swap;
  font-weight: 100 900;
  src: local('Chivo'),
    url(/static/media/Chivo-VariableFont.9d619c1c.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'Marcellus';
  src: local('Marcellus'),
    url(/static/media/Marcellus-Regular.c29ca9ab.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

