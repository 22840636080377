.basicLayout {
  --color-highlight: #000000;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  .hasSider {
    align-content: space-between;
  }
  .accountContainer {
    padding-top: 36px;
    padding-right: 24px;
  }
  &Divider {
    border: 0.5px solid #000000;
  }
}
.topNav {
  float: right;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 28px;
  padding: 0 10px;
  position: relative;
  right: 0;
  top: 50px;
  &Icon {
    cursor: pointer;
    width: 28px;
  }
}

.rightContainer {
  flex: 1;
}
@media (max-width: 1440px) {
  .rightContainer {
    width: auto;
  }
}
@media (max-width: 900px) {
  .basicLayout {
    display: block;
  }
  .rightContainer {
    width: auto;
    padding: 0 10px;
  }
  .basicLayout {
    .accountContainer {
      padding-right: 0;
    }
  }
}
